import service from '../../services/subscriptions.service';

export default {
  namespaced: true,
  state: { billing: [], loading: false, error: null },

  actions: {
    create({ commit }, payload) {
      commit('loading');

      if (payload.plan_code.includes('SUCURSALES')) {
        return service.createSucursales(payload).catch((err) => commit('requestFailure', err));
      }

      commit('requestSuccess');

      return service.create(payload).catch((err) => commit('requestFailure', err));
    },
    updatePlan({ commit }, orderPlan) {
      // if (planCode.includes('SUCURSALES')) {
      //   console.log('Sucursales plan Changed');
      //   return service
      //     .changeSucursales({ plan_code: planCode })
      //     .catch((err) => commit('requestFailure', err));
      // }
      commit('requestSuccess');
      console.log('Menu plan changed');
      return service.changePlan(orderPlan).catch((err) => {
        commit('requestFailure', err);
        throw err;
      });
    },
    cancel({ commit }, subscription) {
      if (subscription.code.includes('SUCURSALES')) {
        console.log('Sucursales cancelled');
        return service.cancelSucursales().catch((err) => commit('requestFailure', err));
      }
      commit('requestSuccess');
      console.log('Menu cancelled');
      return service.cancel().catch((err) => commit('requestFailure', err));
    },
    resume({ commit }, subscription) {
      if (subscription.code.includes('SUCURSALES')) {
        console.log('Sucursales resumed');
        return service.resumeSucursales().catch((err) => commit('requestFailure', err));
      }
      commit('requestSuccess');
      console.log('Menu resumed');
      return service.resume().catch((err) => commit('requestFailure', err));
    },
    billing({ commit }) {
      commit('loading');

      return service.billing().then(({ data }) => {
        console.log('Billing', data);
        commit('billingSuccess', data)});
    },
  },

  mutations: {
    loading(state) {
      state.loading = true;
    },
    requestSuccess(state) {
      state.loading = false;
    },
    requestFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
    billingSuccess(state, billing) {
      console.log(billing);
      state.loading = false;
      state.billing = billing;
    },
  },
};
