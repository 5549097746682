<template>
  <div class="tab-wrapper position-relative w-100 bg-light">
    <BoardItems
      v-if="items.length"
      class="board-content"
      :modal="'#EditorModal'"
      :target="target"
      :items="items"
      v-bind="boardSettings"
      @getLink="getLink"
      @toggle="enableItem"
      @action="emitAction" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :title="title"
      :target="$wordUp(target)"
      :modal="'#EditorModal'"
      :description="`Aún no tienes ninguna ${target} registrada.`">
      <i class="icon-store"></i>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>

    <ShareLinkModal v-bind="itemLink" @click="saveLink" />
    <Notifications :single="'true'" :hide-after="5000"></Notifications>
    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="remove(itemToDelete)"></button>
    <button ref="reset" class="d-none" @click="resetInputs"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import Notifications from '@voerro/vue-notifications';
import BoardItems from '@/components/boards/BoardItems.vue';
import ShareLinkModal from '@/components/ShareLinkModal.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';

export default {
  name: 'BoardSucursales',
  components: {
    Spinner,
    BoardItems,
    Notifications,
    ShareLinkModal,
    BoardPlaceholder,
  },
  mounted() {
    if (this.planCode === 'FRANCHISES') {
      if (this.scopes.includes('Templates')) this.getAllTemplates();

      this.getAll();
    } else {
      this.$emit('goTo', 'disabled');
    }
  },
  data() {
    return {
      title: 'Sucursales',
      target: 'sucursal',
      itemLink: null,
      itemToUpdate: null,
      itemToDelete: null,
      boardSettings: {
        cols: [
          {
            header: 'Nombre',
            width: '150px',
            prop: 'name',
          },
          // {
          //   header: 'Descripción',
          //   width: 'auto',
          //   prop: 'description',
          // },
          {
            header: 'Plantilla',
            width: '200px',
            prop: 'template_name',
          },
          {
            header: 'Vista Previa',
            width: '100px',
            prop: 'route_key',
            type: 'link',
          },
          {
            header: 'Compartir',
            width: '100px',
            prop: 'id',
            type: 'button',
          },
          {
            header: '',
            width: '50px',
            prop: 'enabled',
            type: 'toggle',
          },
        ],
        actions: ['edit', 'delete'],
      },
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.info,
      sucursales: (state) => state.sucursales,
      templates: (state) => state.templates.items,
    }),
    scopes() {
      return this.profile.plan.scopes.map((s) => s.name);
    },
    limit() {
      const scope = this.profile.plan.scopes.find((s) => s.name === 'Sucursales');
      return scope.limit;
    },

    planCode() {
      return this.profile.plan.code;
    },
    items() {
      return this.sucursales.items.map((item) => {
        const template = this.templates.find((t) => t.id === item.template.id) || {};
        return {
          ...item,
          template_name: template.name || 'N/A',
          template_id: item.template.id || '',
        };
      });
    },
    loading() {
      return this.sucursales.loading;
    },
    error() {
      return this.sucursales.error;
    },
  },
  watch: {
    error(v) {
      if (v.error.code === 'ENABLED_LIMIT_REACHED') {
        this.notifyLimit();
      }
    },
    templates(v) {
      this.setInputOptions({
        name: 'template_id',
        options: [{ name: 'Sin plantilla', id: null }, ...v],
      });
    },
  },
  methods: {
    ...mapActions('sucursales', [
      'getAll',
      'create',
      'update',
      'enable',
      'remove',
      'resetInputs',
      'updateRoute',
      'setItemValues',
      'setInputOptions',
      // 'setTemplateInput',
      'removeTemplateInput',
    ]),
    ...mapActions('templates', { getAllTemplates: 'getAll' }),
    saveLink(id, route) {
      const data = { route_key: route };
      this.updateRoute({ data, id });
    },
    getLink(item) {
      this.itemLink = item;
    },
    notifyLimit() {
      const notify = {
        text: `
        <p><b>Limite Alcanzado</b></p>
        <p>El numero de sucursales que puedes tener activas es ${this.limit}</p>
        <p>Debes desactivar una sucursal antes de activar una diferente.</p>
        <p>Si necesitas activar más sucursales, agrega más sucursales a tu plan.</p>
        `,
        theme: 'error',
      };

      window.notify(notify);
    },
    emitAction(action, target, id) {
      this.$emit('action', action, target, id);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues(id);
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    enableItem(id, prop) {
      const item = this.items.find((i) => i.id === id);

      item[prop] = !item[prop];

      this.enable({ id, boolean: item[prop] }).catch(() => {
        item[prop] = false;
      });
    },
    createItem() {
      this.create().then((err) => {
        if (!err) {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
    updateItem() {
      this.update({ id: this.itemToUpdate, templates: this.templates }).then((err) => {
        if (!err) {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
