var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-panel wh-100",attrs:{"id":"admin-panel"}},[_c('div',{staticClass:"admin-panel-container d-flex wh-100"},[_c('Sidebar',{attrs:{"section":_vm.section},on:{"click":_vm.switchSection}}),(_vm.profile.loading)?_c('div',{staticClass:"position-relative wh-100"},[_c('Spinner',{staticClass:"text-primary",attrs:{"size":'lg',"center":true}})],1):(['categories', 'products', 'variants', 'tags'].includes(_vm.section))?_c('BoardMenu',{ref:"menu",attrs:{"tab":_vm.section,"existTemplates":!!_vm.templates.items.length},on:{"click":_vm.switchSection,"action":_vm.boardAction}}):(_vm.section === 'promotions')?_c('BoardPromotions',{ref:"promotions",attrs:{"existTemplates":!!_vm.templates.items.length},on:{"action":_vm.boardAction}}):(_vm.section === 'advertising')?_c('BoardAdvertising',{ref:"advertising",attrs:{"existTemplates":!!_vm.templates.items.length},on:{"goTo":_vm.switchSection,"action":_vm.boardAction}}):(['sucursales', 'templates'].includes(_vm.section))?_c('BoardMultisucursal',{ref:"multisucursal",attrs:{"tab":_vm.section},on:{"click":_vm.switchSection,"action":_vm.boardAction}}):(_vm.section === 'tutorials')?_c('BoardTutorials',{ref:"tutorials",on:{"goTo":_vm.switchSection,"action":_vm.boardAction}}):(['general', 'account', 'customize', 'marketing'].includes(_vm.section))?_c('BoardSettings',{ref:"settings",attrs:{"tab":_vm.section},on:{"click":_vm.switchSection}}):(_vm.section === 'billing')?_c('BoardBilling'):(_vm.section === 'disabled')?_c('BoardDisabled',{on:{"goTo":function($event){return _vm.switchSection('billing')}}}):_vm._e(),(
        !_vm.profile.loading &&
        _vm.profile.info.subscriptions &&
        _vm.payAlert &&
        _vm.$moment(_vm.profile.info.subscriptions[0].expires_at).diff(_vm.$moment(), 'days') <= 0
      )?_c('div',{staticClass:"pay-alert p-4 fs-3"},[_vm._v(" El servicio a sido suspendido por falta de pago. Por favor realiza el pago de "+_vm._s(_vm.$priceFormat(_vm.profile.info.subscriptions[0].amount))+" para reanudar el servicio. "),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){_vm.payAlert = false}}})]):_vm._e()],1),_c('EditorModal',{ref:"editorModal",attrs:{"inputs":_vm.inputs,"toggleInputs":_vm.toggleInputs,"productId":_vm.productToUpdate,"variantGroupId":_vm.variantGroupToUpdate,"mode":_vm.editorMode,"errorMsg":_vm.errorMsg,"loading":_vm.crudLoading,"validated":_vm.validated,"target":_vm.$wordUp(_vm.target),"imageInputs":_vm.imageInputs,"iconInputs":_vm.iconInputs,"variants":_vm.variantGroups,"prodTags":_vm.prodTags,"newVariantGroup":_vm.newVariantGroup,"productVariants":_vm.section === 'products'},on:{"save":_vm.saveItem,"valid":_vm.checkInput,"input":_vm.handleInput,"addNewVariantGroup":_vm.setVariantEditor,"updateVariantGroup":_vm.setUpdateVariantEditor,"validImage":_vm.checkImageInput,"imageInput":_vm.handleImageInput,"toggle":_vm.handleToggleInput,"reset":_vm.resetEditor}}),_c('VariantsEditorModal',_vm._b({on:{"action":_vm.boardAction,"reset":function($event){_vm.variantEditorSettings.groupId = null},"newVariantGroup":(v) => (_vm.newVariantGroup = v)}},'VariantsEditorModal',_vm.variantEditorSettings,false)),_c('MasterEnableModal',_vm._b({},'MasterEnableModal',_vm.templateEnable,false)),_c('ConfirmModal',{attrs:{"id":'ConfirmDelete',"btnText":'Eliminar ' + _vm.$wordUp(_vm.target),"description":'Confirma para eliminar ' + _vm.target},on:{"click":_vm.removeItem}}),_c('ConfirmModal',{attrs:{"id":'ConfirmLogout',"btnText":'Cerrar Sesión',"description":'Confirma para cerrar sesión'},on:{"click":_vm.logoutAccount}}),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('noscript',[_c('iframe',{staticStyle:{"display":"none","visibility":"hidden"},attrs:{"src":"https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X","height":"0","width":"0"}})])
}]

export { render, staticRenderFns }