<template>
  <div
    id="VariantsEditorModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
    aria-labelledby="EditorModalLabel">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
            {{ `${mode} Grupo de Variantes` }}
          </h5>
          <button
            v-if="chained"
            type="button"
            ref="closeEditor"
            class="btn-close"
            data-bs-toggle="modal"
            data-bs-target="#EditorModal"
            aria-label="Close"
            @click="reset"></button>
          <button
            v-else
            type="button"
            ref="closeEditor"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset"></button>
        </div>
        <div v-if="errorMsg" class="modal-body flex-center">
          <p>{{ errorMsg }}</p>
        </div>
        <div v-else class="modal-body pb-0">
          <Spinner v-if="loading" class="text-primary" :size="'lg'" :center="true" />
          <form v-show="!loading" @submit.prevent="">
            <Input
              v-for="(input, i) in inputs"
              :key="i"
              v-bind="input"
              :validated="validated"
              @valid="checkInput"
              @input="handleInput" />

            <label class="form-label w-100" for="">
              Variantes
              <div
                class="row gx-3 align-items-center my-3"
                v-for="(variant, i) in variantList"
                :key="i">
                <div class="col-7">
                  <Input
                    :class="{ 'border border-danger': validated && !variant.name }"
                    type="text"
                    placeholder="Nombre de variante"
                    v-model="variant.name"
                    @change="() => (variant.modified = true)"
                    aria-label="Variant name" />
                </div>
                <div class="col-4 position-relative">
                  <Input
                    class="input-price ps-5"
                    :name="'price'"
                    type="number"
                    placeholder="Precio"
                    v-model="variant.price"
                    @input="() => (variant.modified = true)"
                    aria-label="Price" />
                  <!-- <span class="position-absolute dollar">$</span> -->
                </div>
                <button
                  type="button"
                  class="btn-close col-1"
                  aria-label="Close"
                  @click="removeVariant(variant.id, i)"></button>
              </div>
              <Button class="w-100" :styled="'light'" @click="addVariantInput">
                + Agregar Variante
              </Button>
            </label>
          </form>
        </div>
        <div v-show="!errorMsg" class="modal-footer justify-content-between pt-5">
          <Button
            :styled="'danger'"
            data-bs-toggle="modal"
            data-bs-target="#EditorModal"
            @click="reset"
            >Cancelar</Button
          >
          <Button @click="save">{{ mode }}</Button>
        </div>
      </div>
    </div>
    <Button class="d-none" ref="editorModal" data-bs-toggle="modal" data-bs-target="#EditorModal">
      open editor modal
    </Button>

    <button
      ref="masterModal"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#MasterEnableModal"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Input from '@/components/common/Input.vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';

import service from '../services/variants.service';

export default {
  name: 'VariantsEditorModal',
  components: {
    Spinner,
    Button,
    Input,
  },
  mounted() {
    if (!this.items.length) this.getAll();
  },
  data() {
    return {
      // inputs: [
      //   {
      //     name: 'name',
      //     label: 'Nombre del grupo',
      //     value: '',
      //     required: true,
      //     placeholder: 'Aderezos',
      //   },
      // ],
      variantList: [{ name: null, price: null }],
      // validated: false,
    };
  },
  computed: {
    ...mapState({
      variants: (state) => state.variants,
      templates: (state) => state.templates,
    }),
    items() {
      return this.variants.items;
    },
    loading() {
      return this.variants.loading;
    },
    validated: {
      get() {
        return this.variants.validated;
      },
      set(input) {
        return this.$store.dispatch(`${'variants'}/setValidated`, input);
      },
    },
    inputs: {
      get() {
        return this.variants.inputs;
      },
      set(input) {
        return this.$store.dispatch(`${'variants'}/setInputValue`, input);
      },
    },
  },
  props: {
    id: String,
    mode: String,
    groupId: Number,
    chained: Boolean,
    errorMsg: String,
    productId: Number,
  },
  watch: {
    groupId(v) {
      const group = this.items.find((g) => g.id === v);

      if (group) {
        this.inputs[0].value = group.name;

        this.variantList = [...group.variants];
      }
    },
  },
  methods: {
    ...mapActions('products', ['addVariantGroup']),
    ...mapActions('products', { getAllProducts: 'getAll' }),
    ...mapActions('variants', ['getAll', 'create', 'update', 'remove', 'resetInputs']),
    save() {
      const errorVar = this.variantList.find((v) => !v.name);

      if (errorVar) {
        this.validated = true;
        return;
      }

      try {
        if (this.mode === 'Agregar') {
          this.create().then((id) => {
            const variantIds = [];
            Promise.all(
              this.variantList.map(({ name, price }) => {
                if (name) {
                  return service.variants
                    .create({ name, price }, id)
                    .then(({ data }) => variantIds.push(data.info.id));
                }
                return {};
              }),
            ).then(() => {
              this.getAll();
              if (this.templates.items.length) {
                this.$emit('action', 'create', 'variante', variantIds);
                this.$refs.masterModal.click();
              }
            });

            if (this.productId) {
              this.addVariantGroup({ productId: this.productId, group_id: id });
            } else {
              this.$emit('newVariantGroup', { id, name: this.inputs[0].value });
            }

            this.getAllProducts();

            if (this.chained) {
              this.$refs.editorModal.$el.click();
            } else {
              this.$refs.closeEditor.click();
            }
            this.reset();
          });
        } else {
          this.update({ id: this.groupId }).then(() => {
            Promise.all(
              this.variantList.map(({ id, name, price, modified }) => {
                if (id && modified) {
                  if (this.templates.items.length) {
                    this.$emit('action', 'update', 'variante', this.groupId);
                    this.$refs.masterModal.click();
                  }

                  return service.variants.update(id, { name, price: price || null });
                }

                if (!id) {
                  return service.variants.create({ name, price }, this.groupId);
                }

                return [];
              }),
            ).then(() => {
              this.getAll();
              this.getAllProducts();
            });

            if (this.chained) {
              this.$refs.editorModal.$el.click();
            } else {
              this.$refs.closeEditor.click();
            }
            this.reset();
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    reset() {
      this.$emit('reset');
      this.resetInputs();
      this.validated = false;
      this.variantList = [{ name: null, price: null }];
    },
    checkInput(input) {
      this.inputs = input;
    },
    handleInput(input) {
      this.inputs = input;
    },
    addGroupToProduct(groupId) {
      this.addVariantGroup({ productId: this.productId, group_id: groupId });
    },
    addVariantInput() {
      this.variantList.push({ name: null, price: null });
    },
    removeVariant(id, i) {
      if (id) {
        this.remove({ id, parentId: this.groupId });
      }
      this.variantList.splice(i, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-header {
    background-color: #e1e0ff;
  }
  &-body {
    min-height: 150px;
  }
  &-footer button {
    flex: 1;
  }
}
.variants-list {
  transition: height 1s;
  height: 400px;
}
.hidden-list {
  height: 0;
  padding: 0 !important;
}
.limit-control {
  background: #fff;
  border-radius: 5px;
  border: solid 2px $primary;
  font-weight: bold;
  &:active {
    background: $primary;
    color: #fff;
  }
}
.dollar {
  color: #6c757d;
  bottom: 12px;
  left: 16px;
}
</style>
