<template>
  <button
    v-if="parent"
    class="btn w-100 flex-center px-5 py-4 justify-content-start"
    type="button"
    aria-expanded="false"
    data-bs-toggle="collapse"
    :data-bs-target="`#collapse${id}`"
    :aria-controls="`collapse${id}`">
    <!-- Drag and Drop Control -->
    <div class="position-absolute flex-center start-0 top-0 bottom-0" :class="{ handle: sortable }">
      <i class="icon-reorder icon-handle fs-2 py-4 px-2" :class="{ 'd-none': !sortable }"></i>
      <i class="icon-arrow-down icon-nohandle p-4" :class="{ 'd-none': !children }"></i>
      <i class="fs-2 ps-4" :class="{ [icon]: !!icon }"></i>
    </div>
    <!-- Collapse Item Name -->
    <div class="name col-3 text-start">
      <span class="ms-4" :class="{ 'ps-5': !!icon }"> {{ name }}</span>
    </div>
    <div v-if="tagsMax.length">
      <span>{{ tagsTitle }}</span>
      <span
        v-for="(t, i) in tagsMax"
        :key="i"
        class="ms-2 py-2 px-3"
        style="font-size: 14px; font-weight: 400; background: #e1e0ff"
        >{{ t }}</span
      >
    </div>
  </button>
  <div class="flex-grow-1" v-else>
    <div v-if="sortable" class="handle position-absolute flex-center">
      <i class="icon-reorder handle-icon fs-2 py-3 px-5"></i>
    </div>
    <div class="row">
      <span class="ms-4 col-3 d-flex align-items-center">{{ name }}</span>
      <span class="col-2 col-lg-1" v-show="price">$ {{ price }}</span>
      <div class="col-2 col-lg-1">
        <span v-if="recommended" class="material-symbols-outlined text-primary me-2">star</span>
        <span v-if="featured" class="material-symbols-outlined text-primary me-2">thumb_up</span>
      </div>
      <div class="col" v-if="tagsMax.length">
        <span>{{ tagsTitle }}</span>
        <span
          v-for="(t, i) in tagsMax"
          :key="i"
          class="ms-2 py-2 px-3"
          style="font-size: 14px; font-weight: 400; background: #e1e0ff"
          >{{ t }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoardItem',
  data() {
    return {};
  },
  props: {
    id: [String, Number],
    sortable: Boolean,
    parent: Boolean,
    recommended: Boolean,
    featured: Boolean,
    price: Number,
    name: String,
    icon: String,
    children: String,
    tagsTitle: String,
    variantTags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tagsMax() {
      const list = this.variantTags.filter((t, i) => i < 5);

      if (this.variantTags.length > 5) list.push('...');

      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-nohandle {
  transition: transform 0.5s;
}
.collapsed {
  .icon-nohandle {
    transform: rotateZ(-90deg);
  }
}
.handle {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &:hover {
    .icon-handle {
      display: block;
    }
    .icon-nohandle {
      display: none;
    }
  }
  .icon-handle {
    display: none;
  }
  .icon-nohandle {
    display: block;
  }
}
</style>
