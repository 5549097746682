<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100 overflow-auto" v-if="!profile.loading">
      <div v-if="tab === 'details'" class="col-10 offset-lg-1 mt-5 py-5">
        <div v-if="!alreadyUseDemo" class="alert alert-primary" role="alert">
          Hey! Prueba gratis durante 15 días uno de los planes premium y descubre todos los
          beneficios que tenemos para ti.
        </div>
        <h2 class="fs-2 mb-5">Información de facturación</h2>
        <div class="billing-wrapper row">
          <SubscriptionDetails
            class="billing-membership mb-5"
            :subscriptions="activeSubscriptions"
            :alreadyUseDemo="alreadyUseDemo"
            @update="setSubsToUpdate"
            @changePlan="setProductToUpdate" />
          <div v-if="menuSubscription.plan !== 'Freemium'" class="billing-membership mb-5">
            <h3 class="mb-4">Detalles recurrentes</h3>

            <div class="border rounded">
              <div class="row p-4 border-bottom" v-if="menuSubscription.next_plan">
                <span class="col-3">Renovación</span>
                <span class="col-6 fw-bold">{{ nextPlan }}</span>
                <a
                  class="col-3 text-end"
                  href="#"
                  @click="setProductToUpdate('menú')"
                  >Cambiar plan</a
                >
              </div>
              <div class="row p-4 border-bottom" v-if="menuSubscription.next_plan">
                <span class="col-3">Monto total</span>
                <span class="col-6 fw-bold"
                  >{{ $priceFormat(menuSubscription.next_amount, profile.info.currency) }} {{profile.info.currency}} / {{ menuSubscription.next_interval === 'yearly' ? 'Anual' : 'Mensual' }}</span
                >
              </div>
              <div class="row p-4 border-bottom" v-else-if="menuSubscription.status === 'active'">
                <span class="col-3">Monto total</span>
                <span class="col-6 fw-bold"
                  >{{ $priceFormat(subsTotalAmount, profile.info.currency) }} {{profile.info.currency}} / {{ period }}</span
                >
              </div>
              <div v-if="$moment(menuSubscription.expires_at).diff($moment(), 'days') <= 0" class="row p-4 border-bottom">
                <span class="col-3">Finalizo el</span>
                <span class="col-6 fw-bold">{{
                  $moment(menuSubscription.expires_at).format('DD MMMM YYYY')
                }}</span>
              </div>
              <div v-else class="row p-4 border-bottom">
                <span class="col-3">{{
                  menuSubscription.status === 'active' ? 'Proximo cargo' : 'Finaliza el'
                }}</span>
                <span class="col-6 fw-bold">{{
                  $moment(menuSubscription.expires_at).format('DD MMMM YYYY')
                }}</span>
                <a
                  v-if="menuSubscription.status === 'active'"
                  class="col-3 text-end"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#cancelSubscription"
                  >Cancelar</a
                >
              </div>
              <div class="row p-4">
                <span class="col-3">Método de pago</span>
                <div class="col-6 fw-bold">
                  <div v-if="defaultPaymentMethod">
                    <img
                      class="me-3"
                      :src="require(`@/assets/images/${defaultPaymentMethod.brand}.png`)"
                      width="35"
                      alt="" />
                    <span>**** **** **** {{ defaultPaymentMethod.last4 }}</span>
                  </div>
                </div>
                <a
                  v-if="defaultPaymentMethod"
                  class="col-3 text-end"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#changePaymentMethod"
                  >Cambiar método</a
                >
                <a
                  v-else
                  class="col-3 text-end"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#changePaymentMethod"
                  >Agregar tarjeta</a
                >
              </div>
            </div>
          </div>
          <SubscriptionHistory class="billing-membership mb-5" :history="billingHistory" />
        </div>
      </div>
      <!-- Plan Selector -->
      <div v-else-if="tab === 'selector'" class="col-10 offset-lg-1 mt-5 py-5">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h2 class="fs-2">Cambia tu plan</h2>

          <Button
            :styled="'normal'"
            :size="'sm'"
            style="border: 1px solid transparent"
            @click="returnTab">
            Regresar
          </Button>
        </div>
        <SubscriptionPlanSelector
          class="billing-wrapper"
          :product="productType"
          :subscriptions="subscriptions"
          :newMenuPlan="newMenuPlan"
          @planSelected="setNewPlan"
          @setupSucursales="setNewPlan" />
      </div>
      <!-- Add New Card -->
      <div v-else-if="tab === 'checkout'" class="col-10 offset-lg-1 mt-5 py-5">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h2 class="fs-2">Checkout</h2>

          <Button
            :styled="'normal'"
            :size="'sm'"
            style="border: 1px solid transparent"
            @click="returnTab">
            Regresar
          </Button>
        </div>
        <div class="billing-checkout mb-5">
          <Checkout :checkoutId="profile.emptyCard.checkout_id" @success="success" />
        </div>
        <div class="billing-checkout mb-5"></div>
      </div>
      <div v-else-if="tab === 'resume'" class="col-10 offset-lg-1 mt-5 py-5">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <h2 class="fs-2">Resumen</h2>

          <Button
            :styled="'normal'"
            :size="'sm'"
            style="border: 1px solid transparent"
            @click="returnTab">
            Regresar
          </Button>
        </div>
        <SubscriptionResume
          class="billing-resume rounded border col-lg-6 mb-5 p-5"
          :newPlan="newPlan"
          :product="productType"
          :newComplement="newComplement"
          :alreadyUseDemo="alreadyUseDemo"
          :paymentFailed="paymentFailed"
          :currentSubs="currentSubs"
          @newCard="initCheckout"
          @finish="changePlan" />
      </div>
    </div>
    <Spinner v-else :class="'text-primary'" :size="'lg'" :center="true" />
    <ConfirmModal
      :id="'cancelSubscription'"
      :title="'Finalizar suscripción'"
      :btnText2="'Seguir Suscrito'"
      :btnText="'Cancelar Suscripción'"
      :description="`<p>Al momento de cancelar, tu cuenta seguirá activa con los beneficios del plan suscrito hasta que el periodo facturado termine en <b>${$moment(
        menuSubscription.expires_at,
      ).format('DD MMMM YYYY')}</b>.</p>
      <p>Después de eso tu cuenta pasara a ser freemium por lo que tendrás beneficios limitados.</p>
      `"
      @click="cancelSubscription" />
    <ConfirmModal
      :id="'resumeSubscription'"
      :title="'Reanudar suscripción'"
      :btnMode="'reverse'"
      :btnText="'Reactivar Suscripción'"
      @click="resumeSubscription">
      <p>
        Reanuda tu suscripción donde la dejaste o
        <a href="#" data-bs-dismiss="modal" @click="tab = 'selector'; productType = 'menú'">cambia de plan</a>
      </p>
    </ConfirmModal>
    <Notifications :single="'true'" :hide-after="5000"></Notifications>

    <div
      id="changePaymentMethod"
      class="modal fade"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      aria-labelledby="changePaymentMethodLabel">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center">
            <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
              Cambiar Método de Pago
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="w-100 bg-light">
              <div
                v-for="(m, i) in profile.paymentMethods"
                :key="i"
                style="cursor: pointer"
                class="bank-card d-flex p-4 border border-1 rounded p-4 bg-light position-relative">
                <div
                  class="position-absolute start-0 top-0 bottom-0 end-0"
                  data-bs-dismiss="modal"
                  @click="setDefault(m.id)"></div>
                <img
                  :src="require(`@/assets/images/${m.brand}.png`)"
                  width="70px"
                  height="42px"
                  alt="" />
                <div class="ms-4">
                  <p class="mb-2">
                    <span class="me-4">Numero de tarjeta</span><b>**** **** **** {{ m.last4 }}</b>
                  </p>
                  <p class="m-0">
                    <span class="me-4">Fecha de expiración</span
                    ><b>{{ `${m.exp_month}/${m.exp_year}` }}</b>
                  </p>
                </div>
                <button
                  v-if="profile.paymentMethods.length > 1"
                  type="button"
                  class="btn-close position-absolute top-50 translate-middle"
                  style="height: 100%; width: 35px; right: -15px; z-index: 99"
                  @click="removePaymentMethod(m.id)"></button>
              </div>
              <Button class="w-100 mt-5" data-bs-dismiss="modal" @click="initCheckout"
                >Agregar Tarjeta</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Notifications from '@voerro/vue-notifications';
import SubscriptionPlanSelector from '@/components/subscription/SubscriptionPlanSelector.vue';
import SubscriptionHistory from '@/components/subscription/SubscriptionHistory.vue';
import SubscriptionDetails from '@/components/subscription/SubscriptionDetails.vue';
import SubscriptionResume from '@/components/subscription/SubscriptionResume.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import Checkout from '@/components/Checkout.vue';
import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'BoardBilling',
  components: {
    SubscriptionPlanSelector,
    SubscriptionHistory,
    SubscriptionDetails,
    SubscriptionResume,
    Notifications,
    ConfirmModal,
    Checkout,
    Spinner,
    Button,
  },
  mounted() {
    if (this.profile.emptyCard.checkout_id) {
      window.location.reload();
    }
    const conektaScript = document.createElement('script');
    conektaScript.setAttribute('src', 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js');
    document.head.appendChild(conektaScript);

    this.getPaymentMethods();
    this.billing();
  },
  data() {
    return {
      subscriptionToUpdate: {},
      tab: 'details', // details, selector, checkout, resume
      cardToken: '',
      productType: '',
      validated: false,
      newPlan: null,
      newMenuPlan: null,
      newComplement: null,
      currentSubs: null,
      paymentFailed: false,
      sucursalesToDisable: []
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      subscription: (state) => state.subscriptions,
    }),
    nextPlan() {
      if (this.menuSubscription.next_plan) {
        const features = this.menuSubscription.next_features.length ? this.menuSubscription.next_features.map((f) => `+ ${f.quantity} ${'Sucursal'}${f.quantity > 1 ? 'es' : ''}`) : []

        return `${this.menuSubscription.next_plan} ${features.join(' ')}`
      }

      return ''
    },
    period() {
      return this.menuSubscription.interval === 'monthly' ? 'Mensual' : 'Anual'
    },
    billingHistory() {
      return this.subscription.billing;
    },
    alreadyUseDemo() {
      return this.menuSubscription.used_demo
    },
    defaultPaymentMethod() {
      return this.profile.paymentMethods.find((p) => p.default);
    },
    menuSubscription() {
      return this.subscriptions.find((s) => !s.code.includes('SUCURSALES'));
    },
    subscriptions() {
      const subscriptions = this.profile.info.subscriptions.map((s) => {
        const product = s.code.includes('SUCURSALES') ? 'sucursales' : 'menú';
        return { ...s, product };
      });

      return subscriptions;
    },
    activeSubscriptions() {
      const active = this.subscriptions.filter((s) => s.status === 'active');

      if (active.length) {
        return active;
      }
      return this.subscriptions;
    },
    subsTotalAmount() {
      if (this.subscriptions[0]) {
        return this.subscriptions[0].amount;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions('profile', [
      'getProfile',
      'getPaymentMethods',
      'addPaymentMethod',
      'removePaymentMethod',
      'getEmptyCardToken',
    ]),
    ...mapActions('subscriptions', ['billing', 'create', 'cancel', 'resume', 'updatePlan']),
    ...mapActions('sucursales', { enableSucursal: 'enable' }),
    ...mapActions('profile', ['setDefaultMethod']),
    setDefault(cardId) {
      this.setDefaultMethod(cardId);
      this.showCards = false;
    },
    setNewPlan(plan) {
      const alreadyInUse = this.subscriptions.find((s) => s.code === plan.code);
      const featureInUse = this.subscriptions[0].features.find((f) => f.code === plan.code && f.quantity === plan.quantity );

      if (((featureInUse && !this.newPlan) || alreadyInUse) && this.subscriptions[0].status === 'active') {
        this.tab = 'details';
      } else {
        if (!plan.code.includes('SUCURSALES')) this.newMenuPlan = plan;
        if (
          ((this.newPlan && this.newPlan.code.includes('FRANCHISES')) ||
            this.activeSubscriptions[0].code.includes('FRANCHISES')) &&
          plan.code.includes('SUCURSALES')
        ) {
            this.newComplement = plan;
            this.sucursalesToDisable = plan.sucursalesToDisable;
            // eslint-disable-next-line
            this.currentSubs = this.subscriptions[0];
            // if (!this.newPlan) this.newPlan =  this.subscriptions[0]
          // }
        } else {
          const subs = this.subscriptions.find((s) => s.product === this.productType);
          const expired = this.$moment(subs.expires_at).unix() - this.$moment().unix() < 0;
          const renewal = expired
            ? this.$moment().add(1, plan.interval === 'yearly' ? 'Year' : 'Month')
            : // eslint-disable-next-line
            subs.expires_at;
          this.currentSubs = subs;
          this.newPlan = { ...plan, renewal };
          this.newComplement = null;
        }

        if (plan.code.includes('FRANCHISES')) {
          this.productType = 'sucursales';
        } else if (this.profile.paymentMethods.length || plan.amount === 0) {
          this.tab = 'resume';
        } else {
          this.initCheckout();
        }
      }
    },
    initCheckout() {
      this.getEmptyCardToken().then(() => {
        this.tab = 'checkout';
      });
    },
    changePlan() {
      const orderPlan = { plan_code: this.newPlan ? this.newPlan.code : this.menuSubscription.code };

      if (this.newComplement)
        orderPlan.features = [
          { code: this.newComplement.code, quantity: this.newComplement.quantity },
        ];

      this.updatePlan(orderPlan)
        .then(async () => {
          this.paymentFailed = false;

          if (this.sucursalesToDisable.length) {
            await Promise.all(
              this.sucursalesToDisable.map((s) => this.enableSucursal({ ...s, boolean: false }))
            );
          }

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.paymentFailed = true;
        });
    },
    setSubsToUpdate(s) {
      this.subscriptionToUpdate = s;
    },
    cancelSubscription() {
      this.cancel(this.menuSubscription).then(() => this.getProfile());
    },
    resumeSubscription() {
      this.resume(this.menuSubscription).then(() => {
        const subsSucursales = this.subscriptions.find((s) => s.code.includes('SUCURSALES'));

        if (this.menuSubscription.code.includes('FRANCHISES') && subsSucursales) {
          this.resume(subsSucursales).then(() => this.getProfile());
        } else {
          this.getProfile();
        }
      });
    },
    setProductToUpdate(product) {
      this.productType = product;
      this.tab = 'selector';
    },
    success(cardToken) {
      this.cardToken = cardToken;
      this.addPaymentMethod({ card_token: cardToken }).then(() => {
        this.tab = this.newPlan ? 'resume' : 'details';
        this.getPaymentMethods();
      });
    },
    returnTab() {
      if (this. tab === 'checkout') {
        this.tab = this.profile.paymentMethods.length ? 'resume' : 'selector'
      } else {
        this.tab = this.tab === 'selector' ? this.tab = 'details' : 'selector'
      }
      this.productType = this.newPlan ? 'menú' : 'sucursales'
      this.newPlan = null
      this.newMenuPlan = null
      this.newComplement = null
      this.paymentFailed = false
      this.sucursalesToDisable = []
      }
  },
};
</script>

<style lang="scss" scoped>
.board-wrapper {
  height: calc(100% - 30px);
}
</style>
