<template>
  <div class="checkout">
    <Spinner v-if="loading" class="text-primary" :size="'lg'" :center="true" />
    <div
      id="conektaIframeContainer"
      ref="conektaIframeContainer"
      class="position-absolute top-50 start-50 translate-middle"
      style="height: 500px; width: 500px"></div>
  </div>
</template>

<script>
import Spinner from '@/components/common/Spinner.vue';

import { licenses } from '../config';

export default {
  name: 'Checkout',
  components: {
    Spinner,
  },
  mounted() {
    this.checkout();
  },
  data() {
    return {
      loading: true,
      publicKey: licenses.conektaPubKey,
      logo64: '',
    };
  },
  props: {
    checkoutId: String,
  },
  methods: {
    convertImageToBase64(imgUrl, callback) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        [this.logo64] = dataUrl.split(',').reverse();
        if (callback) callback(dataUrl);
      };
      image.src = imgUrl;
    },
    checkout() {
      const sendToken = (token) => {
        this.$emit('success', token.id);
      };

      window.ConektaCheckoutComponents.Card({
        targetIFrame: '#conektaIframeContainer',
        allowTokenization: true,
        checkoutRequestId: this.checkoutId,
        publicKey: this.publicKey,
        options: {
          styles: {
            states: {
              empty: {
                borderColor: '#D0273B',
              },
              invalid: {
                borderColor: '#FF00E0',
              },
              valid: {
                borderColor: '#6EC388',
              },
            },
          },
          languaje: 'es',
          button: {
            colorText: '#ffffff',
            text: 'Agregar Tarjeta',
            backgroundColor: '#5227D0',
          },
          iframe: {
            colorText: '#242424',
            backgroundColor: '#FFFFFF',
          },
        },
        onCreateTokenSucceeded(token) {
          sendToken(token);
        },
        onCreateTokenError(error) {
          this.$log(error);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
