import service from '../../services/profiles.service';

/* eslint-disable */

export default {
  namespaced: true,
  state: { info: {}, paymentMethods: [], emptyCard: {}, loading: false, error: null },

  actions: {
    getProfile({ commit }) {
      commit('loading');
      return service.getOne().then(({ data }) => {
        commit('getProfileSuccess', data);
      });
    },
    updateCompanyName({ commit }, company) {
      return service
        .update({ company })
        .then(() => commit('updateSuccess', { company }))
        .catch((err) => handleError(commit, err));
    },
    updateMP({ commit }, facebook_pixel) {
      return service
        .update({ facebook_pixel })
        .then(() => commit('updateSuccess', { facebook_pixel }))
        .catch((err) => handleError(commit, err));
    },
    // updateGA({ commit }, google_analytics) {
    //   return service
    //     .update({ google_analytics })
    //     .then(() => commit('updateSuccess', { google_analytics }))
    //     .catch((err) => handleError(commit, err));
    // },
    updateGTM({ commit }, gtm_id) {
      return service
        .update({ gtm_id })
        .then(() => commit('updateSuccess', { gtm_id }))
        .catch((err) => handleError(commit, err));
    },
    updateFont({ commit }, fonts) {
      return service
        .update(fonts)
        .then(() => commit('updateSuccess', fonts))
        .catch((err) => handleError(commit, err));
    },
    updateBusinessKind({ commit }, business_kind) {
      return service
        .update({ business_kind })
        .then(() => commit('updateSuccess', { business_kind }))
        .catch((err) => handleError(commit, err));
    },
    updateTheme({ commit }, theme) {
      return service
        .update(theme)
        .then(() => commit('updateSuccess', theme))
        .catch((err) => handleError(commit, err));
    },
    getPaymentMethods({ commit }) {
      return service
        .getPaymentMethods()
        .then(({ data }) => commit('paymentMethodsSuccess', data))
        .catch((err) => handleError(commit, err));
    },
    addPaymentMethod({ commit, dispatch }, payload) {
      return service
        .addPaymentMethod(payload)
        .then(({ data }) => {
          commit('addPaymentMethodSuccess', data);
          return dispatch('setDefaultMethod', data.id);
        })
        .catch((err) => handleError(commit, err));
    },
    removePaymentMethod({ commit, dispatch }, cardId) {
      return service
        .removePaymentMethod(cardId)
        .then(() => dispatch('getPaymentMethods'))
        .catch((err) => handleError(commit, err));
    },
    setDefaultMethod({ commit }, card_id) {
      return service
        .update({ default_payment_method_id: card_id })
        .then(() => commit('setDefaultMethodSuccess', card_id))
        .catch((err) => handleError(commit, err));
    },
    getEmptyCardToken({ commit }) {
      return service
        .getEmptyCardToken()
        .then(({ data }) => commit('emptyCardSuccess', data.info))
        .catch((err) => handleError(commit, err));
    },
    uploadLogo({ commit }, { logo, base64 }) {
      return service.uploadLogo(logo).then(() => commit('uploadSuccess', base64));
    },
  },

  mutations: {
    loading(state) {
      state.loading = true;
    },
    requestSuccess(state) {
      state.loading = false;
    },
    requestFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
    getProfileSuccess(state, profile) {
      state.loading = false;
      state.info = profile;
      state.error = null;
    },
    paymentMethodsSuccess(state, methods) {
      state.loading = false;
      state.paymentMethods = methods;
      state.error = null;
    },
    addPaymentMethodSuccess(state, card) {
      state.paymentMethods.push(card);
    },
    setDefaultMethodSuccess(state, card_id) {
      const method = state.paymentMethods.find((p) => p.id === card_id);
      const defaultMethod = state.paymentMethods.find((p) => p.default);
      defaultMethod.default = false;
      method.default = true;
    },
    emptyCardSuccess(state, emptyCard) {
      state.loading = false;
      state.emptyCard = emptyCard;
      state.error = null;
    },
    updateSuccess(state, data) {
      state.info = { ...state.info, ...data };
    },
    uploadSuccess(state, logo) {
      state.info.logo = logo;
    },
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}
