import service from '../../services/variants.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre del grupo',
      value: '',
      required: true,
      placeholder: 'Aderezos',
    },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.groups.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  getByTemp({ commit }, templateId) {
    commit('loading');

    return service.groups.getByTemp(templateId).then(({ data }) => {
      commit('requestSuccess');
      let variants = [];
      data.forEach((g) => {
        const list = g.variants.map((v) => ({ ...v, group: g.name }));

        variants = variants.concat(list);
      });

      return variants;
    });
  },
  create({ state, dispatch, commit }, parentId) {
    const target = parentId ? 'variants' : 'groups';
    const formError = state.inputs.find((i) => !i.valid);
    let payload = {};

    if (formError) {
      state.validated = true;
      throw new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    if (!parentId) commit('loading');

    return service[target]
      .create(payload, parentId)
      .then(({ data }) => {
        if (parentId) {
          // Add new category into parent family
          const parent = state.items.find((i) => i.id === parentId);
          parent.variants.push({ id: data.info.id, ...payload });
        } else {
          dispatch('getAll');
        }
        state.validated = false;

        return data.info.id;
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, commit }, { id, parentId }) {
    const target = parentId ? 'variants' : 'groups';
    const formError = state.inputs.find((i) => !i.valid);
    let payload = {};

    if (formError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    return service[target]
      .update(id, payload)
      .then(() => {
        if (parentId) {
          state.items.find((p, i) => {
            if (p.id === parentId) {
              p.variants.find((itm, j) => {
                // Update local item data
                if (itm.id === id) {
                  state.items[i].variants.splice(j, 1, { ...itm, ...payload });
                  return true;
                }
              });
            }
          });
        } else {
          state.items.find((itm, i) => {
            // Update local item data
            if (itm.id === id) {
              state.items.splice(i, 1, { ...itm, ...payload });
              return true;
            }
          });
        }
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  updateByTemp({ commit }, { templateId, variants }) {
    const items = variants.map(({ id, price, enabled }) => ({ variant_id: id, price, enabled }));

    commit('loading');

    return service.variants.updateByTemp(templateId, items).then(() => commit('requestSuccess'));
  },
  orderPosition({ dispatch }, itemList) {
    // Setting index like new position to items
    const itemsNewPosition = itemList.map(({ id }, i) => ({ id, position: i }));
    return service.variants.orderPosition(itemsNewPosition).then(() => dispatch('getAll'));
  },
  remove({ commit }, { id, parentId }) {
    const target = parentId ? 'variants' : 'groups';
    commit('deleteRequest', { id, parentId });

    // if parentId will delete category else family
    service[target]
      .remove(id)
      .then(() => commit('deleteSuccess', { id, parentId }))
      .catch((err) => {
        commit('deleteFailure', { id, parentId });
        handleError(commit, err);
      });
  },
  setVariantInputs({ state }) {
    state.inputs = [
      {
        name: 'name',
        label: 'Nombre de variante',
        value: '',
        required: true,
        placeholder: 'Agua mineral',
      },
      {
        name: 'price',
        label: 'Precio',
        value: '',
        type: 'number',
        required: false,
        placeholder: '00.00',
        editInTemplate: true,
      },
    ];
  },
  setGroupInputs({ state }) {
    state.inputs = [
      {
        name: 'name',
        label: 'Nombre del grupo',
        value: '',
        required: true,
        placeholder: 'Aderezos',
      },
    ];
  },
  setGroupInput({ state }) {
    if (state.inputs.length === 1) {
      state.imageInputs.pop();
    }
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  // Will set item data to inputs and show on editor
  setItemValues({ state, commit }, { id, parentId }) {
    log('Setting item data to inputs');
    if (parentId) {
      const parent = state.items.find((i) => i.id === parentId);
      const item = parent.variants.find((i) => i.id === id);
      commit('setItemValues', item);
    } else {
      const item = state.items.find((i) => i.id === id);
      commit('setItemValues', item);
      // commit('setItemImages', item);
    }
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  requestSuccess(state) {
    state.loading = false;
  },
  // Get all
  getAllSuccess(state, groups) {
    state.items = groups;
    state.loading = false;
  },
  // Remove item
  deleteRequest(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).variants : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Set signal that will be delete
    items.splice(index, 1, { ...itm, deleting: true });
  },
  deleteSuccess(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).variants : state.items;
    let index;

    items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove from local items
    items.splice(index, 1);
  },
  deleteFailure(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).variants : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove delete signal
    delete itm.deleting;
    items.splice(index, 1, itm);
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
