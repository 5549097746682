<template>
  <div class="admin-panel wh-100" id="admin-panel">
    <div class="admin-panel-container d-flex wh-100">
      <Sidebar :section="section" @click="switchSection" />
      <div v-if="profile.loading" class="position-relative wh-100">
        <Spinner class="text-primary" :size="'lg'" :center="true" />
      </div>
      <BoardMenu
        v-else-if="['categories', 'products', 'variants', 'tags'].includes(section)"
        ref="menu"
        :tab="section"
        :existTemplates="!!templates.items.length"
        @click="switchSection"
        @action="boardAction" />
      <BoardPromotions
        v-else-if="section === 'promotions'"
        ref="promotions"
        :existTemplates="!!templates.items.length"
        @action="boardAction" />
      <BoardAdvertising
        v-else-if="section === 'advertising'"
        ref="advertising"
        :existTemplates="!!templates.items.length"
        @goTo="switchSection"
        @action="boardAction" />
      <BoardMultisucursal
        v-else-if="['sucursales', 'templates'].includes(section)"
        ref="multisucursal"
        :tab="section"
        @click="switchSection"
        @action="boardAction" />
      <BoardTutorials
        v-else-if="section === 'tutorials'"
        ref="tutorials"
        @goTo="switchSection"
        @action="boardAction" />
      <BoardSettings
        v-else-if="['general', 'account', 'customize', 'marketing'].includes(section)"
        ref="settings"
        :tab="section"
        @click="switchSection" />
      <BoardBilling v-else-if="section === 'billing'" />
      <BoardDisabled v-else-if="section === 'disabled'" @goTo="switchSection('billing')" />
      <div
        v-if="
          !profile.loading &&
          profile.info.subscriptions &&
          payAlert &&
          $moment(profile.info.subscriptions[0].expires_at).diff($moment(), 'days') <= 0
        "
        class="pay-alert p-4 fs-3">
        El servicio a sido suspendido por falta de pago. Por favor realiza el pago de
        {{ $priceFormat(profile.info.subscriptions[0].amount) }} para reanudar el servicio.
        <button type="button" class="btn-close" @click="payAlert = false"></button>
      </div>
    </div>
    <!-- Resource editor -->
    <EditorModal
      ref="editorModal"
      :inputs="inputs"
      :toggleInputs="toggleInputs"
      :productId="productToUpdate"
      :variantGroupId="variantGroupToUpdate"
      :mode="editorMode"
      :errorMsg="errorMsg"
      :loading="crudLoading"
      :validated="validated"
      :target="$wordUp(target)"
      :imageInputs="imageInputs"
      :iconInputs="iconInputs"
      :variants="variantGroups"
      :prodTags="prodTags"
      :newVariantGroup="newVariantGroup"
      :productVariants="section === 'products'"
      @save="saveItem"
      @valid="checkInput"
      @input="handleInput"
      @addNewVariantGroup="setVariantEditor"
      @updateVariantGroup="setUpdateVariantEditor"
      @validImage="checkImageInput"
      @imageInput="handleImageInput"
      @toggle="handleToggleInput"
      @reset="resetEditor" />

    <VariantsEditorModal
      v-bind="variantEditorSettings"
      @action="boardAction"
      @reset="variantEditorSettings.groupId = null"
      @newVariantGroup="(v) => (newVariantGroup = v)" />
    <MasterEnableModal v-bind="templateEnable" />
    <!-- Delete resource -->
    <ConfirmModal
      :id="'ConfirmDelete'"
      :btnText="'Eliminar ' + $wordUp(target)"
      :description="'Confirma para eliminar ' + target"
      @click="removeItem" />
    <!-- Logout modal -->
    <ConfirmModal
      :id="'ConfirmLogout'"
      :btnText="'Cerrar Sesión'"
      :description="'Confirma para cerrar sesión'"
      @click="logoutAccount" />

    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BoardMultisucursal from '@/components/boards/BoardMultisucursal.vue';
import BoardAdvertising from '@/components/boards/BoardAdvertising.vue';
import VariantsEditorModal from '@/components/VariantsEditorModal.vue';
import MasterEnableModal from '@/components/MasterEnableModal.vue';
import BoardPromotions from '@/components/boards/BoardPromotions.vue';
import BoardTutorials from '@/components/boards/BoardTutorials.vue';
import BoardSettings from '@/components/boards/BoardSettings.vue';
import BoardDisabled from '@/components/boards/BoardDisabled.vue';
import BoardBilling from '@/components/boards/BoardBilling.vue';
import BoardMenu from '@/components/boards/BoardMenu.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import EditorModal from '@/components/EditorModal.vue';
import Spinner from '@/components/common/Spinner.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
  components: {
    VariantsEditorModal,
    MasterEnableModal,
    BoardMultisucursal,
    BoardAdvertising,
    BoardPromotions,
    BoardTutorials,
    BoardSettings,
    BoardDisabled,
    BoardBilling,
    BoardMenu,
    ConfirmModal,
    EditorModal,
    Spinner,
    Sidebar,
  },
  mounted() {
    const route = window.location.pathname;
    this.switchSection(route);

    this.getProfile().then(() => {
      this.getAllSucursales().then(async () => {
        if (!this.sucursales.items.length) {
          await this.createSucursal({ name: this.profile.info.company }).then(async (id) => {
            await this.enableSucursal({ id, boolean: true });
          });
        }
      });
    });

    if (!this.templates.items.length) this.getAllTemplates();

    document.documentElement.style.setProperty(
      '--headline-font',
      `"SS Nickson One", Arial, sans-serif`,
    );

    document.documentElement.style.setProperty('--body-font', `"Aktiv Grotesk", Arial, sans-serif`);
  },
  data() {
    return {
      target: null,
      section: null,
      errorMsg: '',
      editorMode: 'Agregar',
      variantGroups: [],
      prodTags: [],
      productToUpdate: null,
      variantGroupToUpdate: null,
      variantToDelete: null,
      payAlert: true,
      newVariantGroup: null,
      newTag: null,
      variantEditorSettings: {
        mode: 'Agregar',
        productId: null,
        groupId: null,
        chained: true,
      },
      templateEnable: {
        id: null,
        target: null,
        mode: 'update',
      },
      groupTab: null,
      tabs: [
        'categories',
        'products',
        'variants',
        'tags',
        'sucursales',
        'templates',
        'general',
        'account',
        'customize',
        'marketing',
      ],
    };
  },
  computed: {
    ...mapState({
      tags: (state) => state.tags,
      profile: (state) => state.profile,
      products: (state) => state.products,
      variants: (state) => state.variants,
      categories: (state) => state.categories,
      promotions: (state) => state.promotions,
      advertising: (state) => state.advertising,
      templates: (state) => state.templates,
      sucursales: (state) => state.sucursales,
    }),
    productList() {
      return this.products.items;
    },
    validated: {
      get() {
        if (this.section) return this[this.section]?.validated;
        return false;
      },
      set(input) {
        return this.$store.dispatch(`${this.section}/setValidated`, input);
      },
    },
    inputs: {
      get() {
        if (this.section) return this[this.section]?.inputs;
        return [];
      },
      set(input) {
        return this.$store.dispatch(`${this.section}/setInputValue`, input);
      },
    },
    imageInputs: {
      get() {
        if (this.section) return this[this.section]?.imageInputs;
        return [];
      },
      set(input) {
        return this.$store.dispatch(`${this.section}/setImageInputValue`, input);
      },
    },
    iconInputs: {
      get() {
        if (this.section) return this[this.section]?.iconInputs;
        return [];
      },
      set(input) {
        return this.$store.dispatch(`${this.section}/setIconInputValue`, input);
      },
    },
    toggleInputs: {
      get() {
        if (this.section) return this[this.section]?.toggleInputs;
        return [];
      },
      set(input) {
        return this.$store.dispatch(`${this.section}/setToggleInputValue`, input);
      },
    },
    crudLoading() {
      if (this.section) return this[this.section]?.loading;
      return true;
    },
  },
  watch: {
    productList() {
      if (this.target === 'producto' && this.productToUpdate) {
        const product = this.products.items.find((p) => p.id === this.productToUpdate);
        if (product) this.variantGroups = product.variant_groups;
        if (product) this.prodTags = product.tags;
      }
    },
  },
  methods: {
    ...mapActions('account', ['logout']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('sucursales', {
      getAllSucursales: 'getAll',
      createSucursal: 'initSucursal',
      enableSucursal: 'enable',
    }),
    ...mapActions('templates', {
      getAllTemplates: 'getAll',
    }),
    logoutAccount() {
      this.logout();
      this.$router.push('login');
    },
    switchSection(route) {
      // eslint-disable-next-line
      if (route === '/') route = '/menu/categories';

      const [section, groupTab] = route.split('/').reverse();
      this.groupTab = groupTab;

      if (section !== 'logout') {
        window.history.pushState({}, '', route);
        this.section = section;
      }

      this.templateEnable.target = null;
    },
    boardAction(action, target, id) {
      this.$log(action, target, id);
      this.target = target;
      this.productToUpdate = null;
      this.variantGroupToUpdate = null;

      if (action === 'create') {
        this.editorMode = 'Agregar';
        this.templateEnable.id = id;
        this.templateEnable.target = target;
        this.templateEnable.mode = 'enable';

        if (['grupo', 'Grupo'].includes(target)) {
          this.setVariantEditor();
          this.variantEditorSettings.chained = false;
        }
      } else if (action === 'update') {
        this.editorMode = 'Actualizar';
        this.templateEnable.id = id;
        this.templateEnable.target = target;
        this.templateEnable.mode = 'replicate';

        if (['grupo', 'Grupo'].includes(target)) {
          this.setUpdateVariantEditor(id);
          this.variantEditorSettings.chained = false;
        }
        if (target === 'producto') {
          const product = this.products.items.find((p) => p.id === id);
          this.productToUpdate = id;
          if (product) this.variantGroups = product.variant_groups;
          if (product) this.prodTags = product.tags;
        }
      } else if (action === 'parent') {
        this.editorMode = 'Agregar';
        this.variantGroups = [];
        this.prodTags = [];
        this.templateEnable.id = id;
        this.templateEnable.target = target;
        this.templateEnable.mode = 'enable';
      } else if (action === 'template') {
        this.templateEnable.id = id;
        this.templateEnable.target = target;
        this.templateEnable.mode = 'update';
      }

      if (target === 'grupo') {
        this.variantGroupToUpdate = id;
      }
    },
    checkInput(input) {
      this.inputs = input;
    },
    handleInput(input) {
      this.inputs = { ...input, updated: true };
    },
    checkImageInput(input) {
      this.imageInputs = input;
    },
    handleImageInput(input) {
      this.imageInputs = input;
    },
    checkIconInput(input) {
      this.iconInputs = input;
    },
    handleIconInput(input) {
      this.iconInputs = input;
    },
    handleToggleInput(input) {
      this.toggleInputs = input;
    },
    saveItem() {
      const action = this.editorMode === 'Actualizar' ? 'update' : 'create';

      if (this.groupTab) {
        this.$refs[this.groupTab].$refs[this.section].$refs[action].click();
      } else {
        this.$refs[this.section].$refs[action].click();
      }
    },
    removeItem() {
      if (this.groupTab) {
        this.$refs[this.groupTab].$refs[this.section].$refs.remove.click();
      } else {
        this.$refs[this.section].$refs.remove.click();
      }
    },
    setVariantEditor(productId) {
      this.variantEditorSettings.mode = 'Agregar';
      this.variantEditorSettings.groupId = null;
      this.variantEditorSettings.productId = productId;
    },
    setUpdateVariantEditor(groupId) {
      this.variantEditorSettings.mode = 'Actualizar';
      this.variantEditorSettings.groupId = groupId;
      this.variantEditorSettings.productId = null;
    },
    resetEditor() {
      if (this.groupTab) {
        this.$refs[this.groupTab].$refs[this.section].$refs.reset.click();
      } else {
        this.$refs[this.section].$refs.reset.click();
      }

      this.errorMsg = '';
      this.validated = false;
      this.editorMode = 'Agregar';
      this.variantEditorSettings = {
        mode: 'Agregar',
        productId: null,
        groupId: null,
        chained: true,
      };
    },
  },
};
</script>

<style lang="scss">
.admin-panel {
  background: $gray-bg;

  .pay-alert {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: $light;
    color: $danger;
    border: 1px solid $danger;

    .btn-close {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}
</style>
