import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';

import authHeader from '../helpers/authHeader';

/**
 * Return profile data
 * @param {string} id
 * @returns
 */
const create = (data) => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/menu',
    headers: authHeader(),
    data,
  };

  console.log('Create subscription', data);
  return menu.http(config).catch(handleError);
};

const changePlan = (data) => {
  const config = {
    method: 'put',
    url: '/v1/subscriptions/menu',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  console.log('Change subscription', data);
  return menu.http(config).catch(handleError);
};

const cancel = () => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/menu/cancel',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const resume = () => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/menu/resume',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const createSucursales = (data) => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/sucursales',
    headers: authHeader(),
    data,
  };

  console.log('Create sucursales subscription', data);
  return menu.http(config).catch(handleError);
};

const changeSucursales = (data) => {
  const config = {
    method: 'put',
    url: '/v1/subscriptions/sucursales',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  console.log('Change sucursales subscription', data);
  return menu.http(config).catch(handleError);
};

const cancelSucursales = () => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/sucursales/cancel',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const resumeSucursales = () => {
  const config = {
    method: 'post',
    url: '/v1/subscriptions/sucursales/resume',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

/**
 * Return profile data
 * @param {string} id
 * @returns
 */
const billing = () => {
  const config = {
    method: 'get',
    url: '/v1/subscriptions/billing?amount=true',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

export default {
  create,
  cancel,
  resume,
  billing,
  changePlan,
  createSucursales,
  changeSucursales,
  cancelSucursales,
  resumeSucursales,
};
