<template>
  <div class="tab-wrapper position-relative w-100 bg-light">
    <div class="board-content h-100 overflow-auto" v-if="!profile.loading">
      <div class="col-10 offset-lg-1 mt-5 py-5">
        <div class="settings-wrapper row">
          <div v-if="planCode !== 'FREEMIUM'" class="settings-colors mb-5 col-12">
            <h2 class="mb-5">Identidad de marca</h2>
            <div class="mb-5">
              <div class="logoHo position-relative mt-3" style="width: 160px; height: 160px">
                <Spinner v-if="uploadingLogo" :class="'text-primary'" :size="'lg'" :center="true" />
                <img
                  v-else-if="info.logo"
                  ref="imageProfile"
                  class="rounded-circle h-100"
                  :src="info.logo"
                  :alt="info.company" />
                <img v-else class="profile-pic" src="@/assets/images/profile.png" alt="" />
              </div>

              <ImageInput
                class="mt-3"
                ref="imageInput"
                :validated="true"
                v-bind="logoInput"
                @valid="checkImage"
                @input="changeImage" />
              <Button class="mt-4" @click="$refs.imageInput.$refs.input.click()">
                Cambiar Logo
              </Button>
            </div>
            <div class="mb-4">
              <div class="d-flex">
                <div class="settings-pick-color mb-3 me-5" v-for="(cp, i) in colorPickers" :key="i">
                  <p class="fs-3 mb-3">{{ cp.title }}</p>
                  <div class="settings-color-inputs d-flex align-items-center">
                    <input type="color" v-model="cp.color" @change="saveColor" />
                    <Input
                      class="ms-3"
                      style="width: 110px"
                      :name="cp.title"
                      v-model="cp.color"
                      @change="saveColor" />
                  </div>
                </div>
              </div>
              <Button :styled="'outline'" @click="resetColors">Restablecer Colores</Button>
            </div>
            <div class="mb-4">
              <div class="my-5">
                <h3 class="headline-text">Título de ejemplo</h3>
                <p class="body-text">Texto de ejemplo</p>
              </div>
              <Input
                style="max-width: 300px"
                :name="'headline_font'"
                :label="'Fuente del título'"
                v-model="fonts.headline_font"
                :type="'list'"
                :options="googleFonts"
                @input="saveFont" />
              <Input
                style="max-width: 300px"
                :name="'body_font'"
                :label="'Fuente del contenido'"
                v-model="fonts.body_font"
                :type="'list'"
                :options="googleFonts"
                @input="saveFont" />
              <Button class="mt-2" :styled="'outline'" @click="resetFonts"
                >Restablecer Fuentes</Button
              >
            </div>
            <div class="d-flex">
              <Button class="me-4" target="_blank" :href="previewMenu"> Vista Previa </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else :class="'text-primary'" :size="'lg'" :center="true" />
    <ShareLinkModal v-bind="sucursal" @click="saveLink" />
    <Notifications :single="'true'" :hide-after="5000"></Notifications>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ImageInput from '@/components/common/ImageInput.vue';
import Notifications from '@voerro/vue-notifications';
import ShareLinkModal from '@/components/ShareLinkModal.vue';
import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';
import Input from '@/components/common/Input.vue';

import { menu } from '../../../config';

export default {
  name: 'TabCustomize',
  components: {
    ShareLinkModal,
    Notifications,
    ImageInput,
    Spinner,
    Button,
    Input,
  },
  async mounted() {
    await this.$http
      .get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${this.$gKey}`)
      .then(({ data }) => {
        this.googleFonts = data.items
          .filter((v) => v.category === 'sans-serif')
          .map((v) => ({ id: v.family, name: v.family }));

        this.googleFonts.unshift({ id: '', name: 'Default' });
      });

    if (!this.sucursal.length) {
      await this.getAllSucursales();
    }

    this.sucursal = await this.sucursales.find((s) => s.enabled);

    this.previewMenu = `${menu.url}/${this.sucursal.route_key}`;

    this.company = this.info.company;
    this.gtm = this.info.gtm_id;
    this.fonts = { headline_font: this.info.fonts.headline, body_font: this.info.fonts.body };
    this.colorPickers[0].color = this.info.theme.background;
    this.colorPickers[1].color = this.info.theme.font;
    this.colorPickers[2].color = this.info.theme.button_background;
    this.colorPickers[3].color = this.info.theme.button_font;

    const fontsSection = document.getElementsByTagName('link')[9];

    if (this.info.fonts.headline) {
      const googleFont = document.createElement('link');

      googleFont.rel = 'stylesheet';
      googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${this.info.fonts.headline.replace(
        / /g,
        '+',
      )}&display=swap`;

      document.getElementsByTagName('head')[0].insertBefore(googleFont, fontsSection);

      document.documentElement.style.setProperty(
        '--headline-font',
        `"${this.info.fonts.headline}", Arial, sans-serif`,
      );
    }

    if (this.info.fonts.body) {
      const googleFont = document.createElement('link');

      googleFont.rel = 'stylesheet';
      googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${this.info.fonts.body.replace(
        / /g,
        '+',
      )}&display=swap`;

      document.getElementsByTagName('head')[0].insertBefore(googleFont, fontsSection);

      document.documentElement.style.setProperty(
        '--body-font',
        `"${this.info.fonts.body}", Arial, sans-serif`,
      );
    }
  },
  data() {
    return {
      fonts: {
        headline_font: '',
        body_font: '',
      },
      googleFonts: [],
      previewMenu: null,
      sucursal: {},
      company: null,
      gtm: null,
      validated: false,
      colorPickers: [
        {
          title: 'Color de fondo',
          color: null,
        },
        {
          title: 'Color del texto',
          color: null,
        },
        {
          title: 'Color de botones',
          color: null,
        },
        {
          title: 'Color del texto de botones',
          color: null,
        },
      ],
      defaultTheme: {
        background_color: '#FFFFFF',
        font_color: '#242424',
        button_color: '#5227D0',
        button_font_color: '#FFFFFF',
      },
      pass: {
        inputs: [
          {
            name: 'currentPass',
            label: 'Contraseña actual',
            value: null,
            required: true,
            type: 'password',
            placeholder: 'Contraseña actual',
          },
          {
            name: 'newPass',
            label: 'Contraseña',
            value: null,
            required: true,
            type: 'text',
            placeholder: 'Contraseña',
          },
          {
            name: 'passwordMatch',
            label: 'Confirmar contraseña',
            value: null,
            required: true,
            type: 'text',
            placeholder: 'Confirmar contraseña',
            validation: !this.isPasswordMatch,
            message: 'La contraseña no coincide',
          },
        ],
      },
      logoInput: {
        name: 'logo',
        value: null,
        formats: '.jpg, .jpeg, .png, .gif',
        showInput: false,
      },
      uploadingLogo: false,
      gtmRegex: /^([GW](?:T|M|A)*)-([a-zA-Z0-9]{7,9})$/,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      sucursales: (state) => state.sucursales.items,
    }),
    body_font() {
      return this.fonts.body_font;
    },
    headline_font() {
      return this.fonts.headline_font;
    },
    planCode() {
      return this.profile.info.plan.code;
    },
    info() {
      return this.profile.info;
    },
    changePassReady() {
      return !!this.pass.inputs.find((i) => !i.value);
    },
    password() {
      return this.pass.inputs[1].value;
    },
    confirmPassword() {
      return this.pass.inputs[2].value;
    },
    isPasswordMatch() {
      return this.password && this.password === this.confirmPassword;
    },
  },
  watch: {
    isPasswordMatch(v) {
      this.pass.inputs[2].validation = !v;
    },
    headline_font(v) {
      const fontsSection = document.getElementsByTagName('link')[9];

      if (v) {
        const googleFont = document.createElement('link');

        googleFont.rel = 'stylesheet';
        googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${v.replace(
          / /g,
          '+',
        )}&display=swap`;

        document.getElementsByTagName('head')[0].insertBefore(googleFont, fontsSection);
      }

      document.documentElement.style.setProperty(
        '--headline-font',
        `"${v || 'SS Nickson One'}", Arial, sans-serif`,
      );
    },
    body_font(v) {
      const fontsSection = document.getElementsByTagName('link')[9];

      if (v) {
        const googleFont = document.createElement('link');

        googleFont.rel = 'stylesheet';
        googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${v.replace(
          / /g,
          '+',
        )}&display=swap`;

        document.getElementsByTagName('head')[0].insertBefore(googleFont, fontsSection);
      }

      document.documentElement.style.setProperty(
        '--body-font',
        `"${v || 'Aktiv Grotesk'}", Arial, sans-serif`,
      );
    },
  },
  methods: {
    ...mapActions('sucursales', {
      getAllSucursales: 'getAll',
      updateSucursal: 'simpleUpdate',
      updateRoute: 'updateRoute',
    }),
    ...mapActions('account', ['changePassword']),
    ...mapActions('profile', [
      'getOne',
      'updateCompanyName',
      'updateGTM',
      'updateFont',
      'updateTheme',
      'uploadLogo',
    ]),
    changeImage(file) {
      const fr = new FileReader();

      if (file && this.logoInput.valid) {
        this.uploadingLogo = true;
        fr.readAsDataURL(file);

        fr.addEventListener('load', (evt) => {
          this.uploadLogo({ logo: file, base64: evt.target.result })
            .then(() => {
              this.uploadingLogo = false;
            })
            .catch(() => {
              this.uploadingLogo = false;
            });
        });
      }
    },
    saveLink(id, route) {
      const data = { route_key: route };
      this.updateRoute({ data, id });
    },
    checkImage({ valid }) {
      this.logoInput.valid = valid;
    },
    saveColor() {
      const colors = {
        background_color: this.colorPickers[0].color,
        font_color: this.colorPickers[1].color,
        button_color: this.colorPickers[2].color,
        button_font_color: this.colorPickers[3].color,
      };

      this.updateTheme(colors);
    },
    saveFont() {
      this.updateFont(this.fonts);
    },
    resetColors() {
      this.colorPickers[0].color = this.defaultTheme.background_color;
      this.colorPickers[1].color = this.defaultTheme.font_color;
      this.colorPickers[2].color = this.defaultTheme.button_color;
      this.colorPickers[3].color = this.defaultTheme.button_font_color;

      this.updateTheme(this.defaultTheme);
    },
    updatePassword() {
      const formError = this.pass.inputs.find((i) => !i.valid);
      this.validated = true;

      if (!formError) {
        const payload = {
          password: this.pass.inputs[0].value,
          new_password: this.pass.inputs[1].value,
        };

        this.changePassword(payload)
          .then(() => {
            const notify = {
              text: `<p><b>Contraseña actualizada</b></p>`,
              theme: 'success',
            };

            window.notify(notify);

            this.resetInputs();
          })
          .catch((err) => {
            if (err.response.data.error.code === 'CURRENT_NOT_MATCH') {
              const notifyErr = {
                text: `
              <p><b>La contraseña no fue actualizada</b></p>
              <p>La contraseña actual no es correcta</p>
              `,
                theme: 'error',
              };

              window.notify(notifyErr);
            }
          });
      }
    },
    resetFonts() {
      this.fonts = { headline_font: '', body_font: '' };

      this.updateFont(this.fonts).then(() => {
        document.documentElement.style.setProperty(
          '--headline-font',
          `"SS Nickson One", Arial, sans-serif`,
        );

        document.documentElement.style.setProperty(
          '--body-font',
          `"Aktiv Grotesk", Arial, sans-serif`,
        );
      });
    },
    handleInput({ value, i }) {
      this.pass.inputs[i].value = value;
    },
    checkInput({ valid, i }) {
      this.pass.inputs[i].valid = valid;
    },
    resetInputs() {
      this.pass.inputs.forEach((inp, i) => {
        this.pass.inputs[i].value = '';
      });
      this.validated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
.settings {
  &-pick-color {
    input[type='color'] {
      -webkit-appearance: none;
      border: none;
      width: 54px;
      height: 54px;
      background: transparent;
    }
    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
      border: 1px solid $dark-gray-bg;
    }
    input[type='text'] {
      width: 110px;
    }
  }
  &-picture {
    max-width: 250px;
    .file-upload {
      border-radius: 50%;
    }
  }
}
.headline-text {
  font-family: var(--headline-font);
  font-size: 32px;
}
.body-text {
  font-family: var(--body-font);
}
</style>
