<template>
  <div class="">
    <div v-if="plan" class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>Menú Digital</p>
      <!-- <p>{{ newPlan.name }}</p> -->
      <p>{{ plan.name || plan.plan }}</p>
    </div>
    <div v-if="plan" class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>{{ `Plan ${period}` }}</p>
      <p>{{ $priceFormat(plan.amount) }} {{profile.info.currency}}</p>
    </div>
    <div v-if="newComplement" class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>{{ newComplement.name }}</p>
      <p>{{ $priceFormat(newComplement.amount) }} {{profile.info.currency}}</p>
    </div>
    <div
      v-if="plan"
      class="d-flex justify-content-between py-4 fw-bold">
      <p>Renovación</p>
      <p>
        {{
          $moment(alreadyUseDemo ? plan.renewal : $moment().add(15, 'days')).format(
            'DD MMMM YYYY',
          )
        }}
      </p>
    </div>
    <div v-if="newComplement" class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>Total</p>
      <p>{{ $priceFormat((plan ? plan.amount : 0) + newComplement.amount) }} {{profile.info.currency}}</p>
    </div>
    <div v-if="$moment(currentSubs.expires_at).diff($moment(), 'days') > 0 && currentSubs.plan !== 'Freemium'" class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>Tiempo restante</p>
      <p>{{ $moment(currentSubs.expires_at).from($moment(), true) }}</p>
    </div>
    <div
      v-if="amountToPayNow && alreadyUseDemo && !subscriptions[0].demo"
      class="d-flex justify-content-between py-4 fw-bold border-bottom m-0">
      <p>Cobrar ahora</p>
      <p>{{ $priceFormat(amountToPayNow, profile.info.currency) }} {{profile.info.currency}}</p>
    </div>

    <div v-if="paymentFailed" class="alert alert-danger" role="alert">
      El pago falló. Por favor intenta más tarde o usa otra tarjeta diferente
    </div>

    <div v-if="((plan && (plan.name !== 'Freemium' || plan.plan !== 'Freemium')) || newComplement) && defaultMethod" class="position-relative">
      <div class="bank-card d-flex p-4 border border-1 rounded p-4" @click="showCards = !showCards">
        <img :src="require(`@/assets/images/${defaultMethod.brand}.png`)" alt="" />
        <div class="ms-4">
          <p class="mb-2">
            <span class="me-4">Numero de tarjeta</span><b>**** **** **** {{ defaultMethod.last4 }}</b>
          </p>
          <p class="m-0">
            <span class="me-4">Fecha de expiración</span
            ><b class="me-4">{{ `${defaultMethod.exp_month}/${defaultMethod.exp_year}` }}</b>
            <a href="#">Cambiar</a>
          </p>
        </div>
      </div>
      <div v-show="showCards" class="position-absolute w-100 bg-light">
        <div
          v-for="(m, i) in otherMethods"
          :key="i"
          class="bank-card d-flex p-4 border border-1 rounded p-4 bg-light"
          @click="setDefault(m.id)">
          <img :src="require(`@/assets/images/${m.brand}.png`)" alt="" />
          <div class="ms-4">
            <p class="mb-2">
              <span class="me-4">Numero de tarjeta</span><b>**** **** **** {{ m.last4 }}</b>
            </p>
            <p class="m-0">
              <span class="me-4">Fecha de expiración</span><b>{{ `${m.exp_month}/${m.exp_year}` }}</b>
            </p>
          </div>
        </div>
        <Button class="w-100" @click="$emit('newCard')">Nueva Tarjeta</Button>
      </div>
    </div>

    <div class="d-flex justify-content-between py-4">
      <Button style="min-width: 180px" :animation="'loading'" :submitted="submitted" @click="finish"
        >Realizar Cambio</Button
      >
    </div>
    <div>
      <p v-if="!alreadyUseDemo" class="m-0">
        Estas por iniciar tu prueba gratis por 15 días, al terminar se realizaran cargos recurrentes
        a tu tarjeta
      </p>
      <p
        v-if="
          currentSubs && newPlan && (newPlan.amount / newPlan.interval === 'yearly' ? 10 : 1 ) < (currentSubs.plan_price / currentSubs.interval === 'yearly' ? 10 : 1) && newPlan.name !== currentSubs.plan
        "
        class="m-0">
        El plan actual es mejor que este, al momento de hacer el cambio seguirás teniendo acceso a
        los beneficios del plan anterior hasta la renovación de la suscripción
      </p>
      <p v-else-if="currentSubs && newPlan && !currentSubs.used_demo && newPlan.amount" class="m-0">
        Actualmente tienes una demo activa, por lo que podrás utilizar los nuevos beneficios sin
        costo hasta la finalización del periodo de prueba
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Button from '@/components/common/Button.vue';

export default {
  name: 'SubscriptionResume',
  components: {
    Button,
  },
  created() {},
  data() {
    return {
      showCards: false,
      submitted: false,
    };
  },
  props: {
    newPlan: Object,
    newComplement: Object,
    currentSubs: Object,
    alreadyUseDemo: Boolean,
    paymentFailed: Boolean,
  },
  watch: {
    paymentFailed() {
      this.submitted = false;
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      paymentMethods: (state) => state.profile.paymentMethods,
    }),
    period() {
      if (this.plan) return this.plan.interval === 'monthly' ? 'Mensual' : 'Anual'

      return ''
    },
    plan() {
      const plan = this.newPlan || {...this.currentSubs, amount: this.currentSubs.plan_price};
      return plan;
    },
    defaultMethod() {
      return this.paymentMethods.find((p) => p.default);
    },
    otherMethods() {
      return this.paymentMethods.filter((p) => !p.default);
    },
    subscriptions() {
      const subscriptions = this.profile.info.subscriptions.map((s) => {
        const product = s.code.includes('SUCURSALES') ? 'sucursales' : 'menú';
        return { ...s, product };
      });

      return subscriptions;
    },
    amountToPayNow() {
      const currentSubs = this.subscriptions[0]
      const currentFeat = currentSubs.features.find((f) => f.name === 'Sucursales') || { amount: 0 }

      const menuRemainingAmount = this.diffAmountSubsPlan(currentSubs, this.newPlan);
      let sucRemainingAmount = 0

      if (this.newComplement) sucRemainingAmount += (this.diffAmountSubsPlan(
        { ...currentSubs, ...currentFeat },
        { ...currentSubs, ...this.newComplement },
      ));

      const remainingDays = this.$moment(currentSubs.expires_at).diff(this.$moment(), 'days');

      const total = menuRemainingAmount + sucRemainingAmount;

      const {interval} = this.newPlan || currentSubs;

      return interval === 'yearly' && remainingDays < 0 ? total * 10 : total;
    },
  },
  methods: {
    ...mapActions('profile', ['setDefaultMethod']),
    setDefault(cardId) {
      this.setDefaultMethod(cardId);
      this.showCards = false;
    },
    finish() {
      this.submitted = true;
      this.$emit('finish');
    },
    diffAmountSubsPlan(subscription, plan) {
      let remainingAmount = 0;
      if (subscription && plan) {
        // Subscription amount by month
        const subsMonthlyAmount =
          subscription.interval === 'monthly' ? subscription.amount : subscription.amount / 10;

        // Plan amount by month
        const planMonthlyAmount = plan.interval === 'monthly' ? plan.amount : plan.amount / 10;

        if (planMonthlyAmount > subsMonthlyAmount) {
          // Diff between subscription and plan amount by day
          const diffDailyAmount = (planMonthlyAmount - subsMonthlyAmount) / 30;
          // Remaining days till expiry
          const remainingDays = this.$moment(subscription.expires_at).diff(this.$moment(), 'days');

          remainingAmount =
            remainingDays < 30 && remainingDays > 0 && subsMonthlyAmount ? diffDailyAmount * remainingDays : planMonthlyAmount - subsMonthlyAmount;

          if (remainingDays > 0 && subscription.interval === 'yearly') remainingAmount *= 10

          if (remainingDays < 0) remainingAmount =  planMonthlyAmount;
        }
      }
      return Math.ceil(remainingAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-card {
  cursor: pointer;
  img {
    width: 70px;
    height: 42px;
  }
}
</style>
