<template>
  <div>
    <h3 class="mb-4">Historial de facturación</h3>
    <div class="bg-light">
      <table class="table">
        <col style="width: 150px" />
        <col style="width: 150px" />
        <col style="width: auto" />
        <col style="width: 130px" />
        <col style="width: 150px" />
        <thead>
          <tr>
            <th scope="col">Fecha</th>
            <th scope="col">Acción</th>
            <th scope="col">Descripción</th>
            <th scope="col">Suscripción</th>
            <th scope="col">Cantidad</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="b in history" :key="b.id">
            <td class="py-4">{{ $moment(b.created).format('MMM DD, YYYY') }}</td>
            <td class="py-4">{{ setAction(b) }}</td>
            <td class="py-4">{{ b.description }}</td>
            <td class="py-4">{{ setInterval(b) }}</td>
            <td class="py-4">{{ $priceFormat(b.amount, profile.info.currency) }} {{profile.info.currency}} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'SubscriptionHistory',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,}),
  },
  props: {
    history: Array,
  },
  methods: {
    setInterval(subs) {
      const intervalDays = this.$moment(subs.expires_at).diff(
            this.$moment(subs.created),
            'days',
          );
            // eslint-disable-next-line
            return subs.interval === 'monthly' ? 'Mensual' : subs.interval === 'yearly' ? 'Anual' : `${intervalDays} Dias`;
    },
    setAction(subs) {
      let action = '';

      switch (subs.action) {
        case 'activation':
          action = 'Activación';
          break;
        case 'resume':
          action = 'Reanudar';
          break;
        case 'renewal':
          action = 'Renovación';
          break;
        case 'upgraded':
          action = 'Mejorado';
          break;
        case 'downgraded':
          action = 'Degradado';
          break;
        case 'updated':
          action = 'Actualización';
          break;
        default:
          action = '';
          break;
      }

      return action;
    }
  },
};
</script>

<style lang="scss" scoped></style>
