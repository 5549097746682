import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const getAll = () => {
  const config = {
    method: 'GET',
    url: '/v1/advertising',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const getOne = (id) => {
  const config = {
    method: 'GET',
    url: `/v1/advertising/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const getByTemp = (templateId) => {
  const config = {
    method: 'GET',
    url: `/v1/templates/${templateId}/advertising`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const create = (advertising) => {
  const config = {
    method: 'POST',
    url: '/v1/advertising',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: advertising,
  };

  return menu.http(config).catch(handleError);
};

const update = (id, data) => {
  const config = {
    method: 'PUT',
    url: `/v1/advertising/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

const updateByTemp = (templateId, advertising) => {
  const config = {
    method: 'PUT',
    url: `/v1/templates/${templateId}/advertising`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: advertising,
  };

  return menu.http(config).catch(handleError);
};

const uploadImages = (id, images) => {
  const formData = new FormData();
  images.forEach((element) => formData.append(element.name, element.img));

  const config = {
    method: 'POST',
    url: `/v1/advertising/${id}/attachment`,
    headers: authHeader(),
    data: formData,
  };

  return menu.http(config).catch(handleError);
};

const remove = (id) => {
  const config = {
    method: 'DELETE',
    url: `/v1/advertising/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

export default {
  getAll,
  getOne,
  create,
  update,
  remove,
  uploadImages,
  getByTemp,
  updateByTemp,
};
