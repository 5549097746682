import { render, staticRenderFns } from "./PlanCard2.vue?vue&type=template&id=7ced6440&scoped=true&"
import script from "./PlanCard2.vue?vue&type=script&lang=js&"
export * from "./PlanCard2.vue?vue&type=script&lang=js&"
import style0 from "./PlanCard2.vue?vue&type=style&index=0&id=7ced6440&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ced6440",
  null
  
)

export default component.exports