<template>
  <div class="tab-wrapper position-relative w-100 bg-light">
    <div class="board-content h-100 overflow-auto" v-if="!profile.loading">
      <div class="col-10 offset-lg-1 mt-5 py-5">
        <h2 class="mb-5">Marketing</h2>
        <div class="settings-wrapper row">
          <div class="settings-profile mb-5 col-6">
            <!-- <Input
              class="input"
              :name="'companyName'"
              :label="'Nombre de la empresa'"
              v-model="company"
              v-bind="{ required: true, validated: true }"
              @change="updateCompanyName(company)" />
            <Input
              class="input"
              :label="'Giro comercial'"
              v-model="businessKind.value"
              v-bind="businessKind"
              @input="updateBusinessKind(businessKind.value)" /> -->
            <Input
              v-if="planCode !== 'FREEMIUM'"
              class="input"
              :name="'gtm'"
              :label="'Google Tag Manager / Google Analytics'"
              :placeholder="'Google Tag Manager ID'"
              :validation="!gtmRegex.test(gtm_id)"
              :validated="!!gtm_id"
              :message="'No coincide con el formato adecuado'"
              v-model="gtm_id"
              @change="updateGTM(gtm_id)" />
            <!-- <Input
              v-if="planCode !== 'FREEMIUM'"
              class="input"
              :name="'google_analytics'"
              :label="'Google Analytics ID'"
              :placeholder="'Google Analytics ID'"
              :validation="!gtmRegex.test(google_analytics)"
              :validated="!!google_analytics"
              :message="'No coincide con el formato adecuado'"
              v-model="google_analytics"
              @change="updateGA(google_analytics)" /> -->
            <Input
              v-if="planCode !== 'FREEMIUM'"
              class="input"
              :name="'facebook_pixel'"
              :label="'Facebook Pixel ID'"
              :placeholder="'Facebook Pixel ID'"
              :validation="
                !(facebook_pixel && facebook_pixel.length > 14 && facebook_pixel.length < 19)
              "
              :validated="!!facebook_pixel"
              :message="'El identificador deben ser entre 15 y 18 dígitos'"
              v-model="facebook_pixel"
              @change="updateMP(facebook_pixel)" />
            <!-- <Input
              v-if="planCode !== 'FREEMIUM'"
              class="input"
              :name="'gtm'"
              :label="'Google Tag Manager ID'"
              :placeholder="'GTM-XXXXXXX'"
              :validation="!gtmRegex.test(gtm)"
              :validated="!!gtm"
              :message="'No coincide con el formato adecuado'"
              v-model="gtm"
              @change="updateGTM(gtm)" /> -->
            <Button class="mt-3 me-3">Guardar</Button>
            <div v-if="planCode !== 'FRANCHISES'">
              <h3 class="mt-4 mb-3">Sucursal</h3>
              <Input
                class="input"
                :name="'sucursalName'"
                :label="'Nombre'"
                v-model="sucursal.name"
                v-bind="{ required: true, validated: true }"
                @change="updateSucursal({ id: sucursal.id, payload: { name: sucursal.name } })" />
              <!-- <Input
                class="input"
                :name="'sucursalDescription'"
                :label="'Descripción'"
                :type="'textarea'"
                :placeholder="'Descripción'"
                v-model="sucursal.description"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({
                    id: sucursal.id,
                    payload: { description: sucursal.description },
                  })
                " /> -->
              <Input
                class="input"
                :name="'sucursalAddress'"
                :label="'Dirección'"
                :placeholder="'Calle, número, colonia, ciudad y estado'"
                v-model="sucursal.address"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({ id: sucursal.id, payload: { address: sucursal.address } })
                " />
              <Input
                class="input"
                :name="'sucursalPhone'"
                :label="'Teléfono'"
                :type="'tel'"
                :placeholder="'Teléfono'"
                v-model="sucursal.phone"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({
                    id: sucursal.id,
                    payload: { phone: sucursal.phone },
                  })
                " />
            </div>
            <div v-if="planCode !== 'FRANCHISES'" class="d-flex">
              <Button class="mt-3 me-3" target="_blank" :href="previewMenu"> Vista Previa </Button>
              <Button
                class="mt-3"
                :styled="'secondary'"
                data-bs-toggle="modal"
                data-bs-target="#ShareLinkModal">
                Generar Link
              </Button>
            </div>
          </div>
          <div class="settings-profile mb-5 col-6">
            <div v-if="planCode !== 'FRANCHISES'">
              <h3 class="mt-4 mb-3">Redes Sociales</h3>
              <Input
                class="input"
                :name="'sucursalWhatsapp'"
                :label="'Whatsapp'"
                :placeholder="'Whatsapp'"
                :type="'tel'"
                v-model="sucursal.whatsapp_num"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({
                    id: sucursal.id,
                    payload: { whatsapp_num: sucursal.whatsapp_num },
                  })
                " />
              <Input
                class="social-input"
                :name="'sucursalFacebook'"
                :label="'Facebook'"
                :prefix="'https://www.facebook.com/'"
                :type="'network'"
                :placeholder="'Usuario'"
                v-model="sucursal.facebook_usr"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({
                    id: sucursal.id,
                    payload: { facebook_usr: sucursal.facebook_usr },
                  })
                " />
              <Input
                class="social-input"
                :name="'sucursalInstagram'"
                :label="'Instagram'"
                :prefix="'https://www.instagram.com/'"
                :type="'network'"
                :placeholder="'Usuario'"
                v-model="sucursal.instagram_usr"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({
                    id: sucursal.id,
                    payload: { instagram_usr: sucursal.instagram_usr },
                  })
                " />
              <Input
                class="social-input"
                :name="'sucursalTikTok'"
                :label="'TikTok'"
                :prefix="'https://www.tiktok.com/@'"
                :type="'network'"
                :placeholder="'Usuario'"
                v-model="sucursal.tiktok_usr"
                v-bind="{ required: false, validated: true }"
                @change="
                  updateSucursal({ id: sucursal.id, payload: { tiktok_usr: sucursal.tiktok_usr } })
                " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else :class="'text-primary'" :size="'lg'" :center="true" />
    <ShareLinkModal v-bind="sucursal" @click="saveLink" />
    <Notifications :single="'true'" :hide-after="5000"></Notifications>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Notifications from '@voerro/vue-notifications';
import ShareLinkModal from '@/components/ShareLinkModal.vue';
import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';
import Input from '@/components/common/Input.vue';

import { menu } from '../../../config';

export default {
  name: 'TabMarketing',
  components: {
    ShareLinkModal,
    Notifications,
    Spinner,
    Button,
    Input,
  },
  async mounted() {
    if (!this.sucursal.length) {
      await this.getAllSucursales();
    }

    this.sucursal = await this.sucursales.find((s) => s.enabled);

    this.previewMenu = `${menu.url}/${this.sucursal.route_key}`;

    // this.company = this.info.company;
    // this.businessKind.value = this.info.business_kind;
    this.gtm_id = this.info.gtm_id;
    this.google_analytics = this.info.google_analytics;
    this.facebook_pixel = this.info.facebook_pixel;
    this.colorPickers[0].color = this.info.theme.background;
    this.colorPickers[1].color = this.info.theme.font;
    this.colorPickers[2].color = this.info.theme.button_background;
    this.colorPickers[3].color = this.info.theme.button_font;
  },
  data() {
    return {
      // businessKind: {
      //   name: 'business_kind',
      //   value: '',
      //   type: 'list',
      //   required: true,
      //   validated: true,
      //   placeholder: 'Giro del negocio',
      //   options: [
      //     'Agencia',
      //     'Alitas',
      //     'Antojitos',
      //     'Antro',
      //     'Bar',
      //     'Botanero',
      //     'Cafeteria',
      //     'Cervecería',
      //     'Comida asiática',
      //     'Comida mexicana',
      //     'Hotel',
      //     'Mariscos',
      //     'Pizzeria',
      //     'Pub',
      //     'Restaurante italiano',
      //     'Otro',
      //   ],
      //   child: {
      //     trigger: 'Otro',
      //     name: 'business_kind',
      //     value: '',
      //     placeholder: 'Dinos tu giro',
      //   },
      // },
      previewMenu: null,
      sucursal: {},
      // company: null,
      gtm_id: null,
      google_analytics: null,
      facebook_pixel: null,
      validated: false,
      colorPickers: [
        {
          title: 'Color de fondo',
          color: null,
        },
        {
          title: 'Color del texto',
          color: null,
        },
        {
          title: 'Color de botones',
          color: null,
        },
        {
          title: 'Color del texto de botones',
          color: null,
        },
      ],
      defaultTheme: {
        background_color: '#FFFFFF',
        font_color: '#242424',
        button_color: '#5227D0',
        button_font_color: '#FFFFFF',
      },
      pass: {
        inputs: [
          {
            name: 'currentPass',
            label: 'Contraseña actual',
            value: null,
            required: true,
            type: 'password',
            placeholder: 'Contraseña actual',
          },
          {
            name: 'newPass',
            label: 'Contraseña',
            value: null,
            required: true,
            type: 'text',
            placeholder: 'Contraseña',
          },
          {
            name: 'passwordMatch',
            label: 'Confirmar contraseña',
            value: null,
            required: true,
            type: 'text',
            placeholder: 'Confirmar contraseña',
            validation: !this.isPasswordMatch,
            message: 'La contraseña no coincide',
          },
        ],
      },
      logoInput: {
        name: 'logo',
        value: null,
        formats: '.jpg, .jpeg, .png, .gif',
        showInput: false,
      },
      uploadingLogo: false,
      gtmRegex: /^([GAU](?:T|M|W|A)*)-([a-zA-Z0-9]{7,9})-?(?:[a-zA-Z0-9]{1,1})?$/,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      sucursales: (state) => state.sucursales.items,
    }),
    planCode() {
      return this.profile.info.plan.code;
    },
    info() {
      return this.profile.info;
    },
    changePassReady() {
      return !!this.pass.inputs.find((i) => !i.value);
    },
    password() {
      return this.pass.inputs[1].value;
    },
    confirmPassword() {
      return this.pass.inputs[2].value;
    },
    isPasswordMatch() {
      return this.password && this.password === this.confirmPassword;
    },
  },
  watch: {
    isPasswordMatch(v) {
      this.pass.inputs[2].validation = !v;
    },
  },
  methods: {
    ...mapActions('sucursales', {
      getAllSucursales: 'getAll',
      updateSucursal: 'simpleUpdate',
      updateRoute: 'updateRoute',
    }),
    ...mapActions('account', ['changePassword']),
    ...mapActions('profile', [
      'getOne',
      // 'updateCompanyName',
      // 'updateBusinessKind',
      'updateMP',
      'updateGA',
      'updateGTM',
      'updateTheme',
      'uploadLogo',
    ]),
    changeImage(file) {
      const fr = new FileReader();

      if (file && this.logoInput.valid) {
        this.uploadingLogo = true;
        fr.readAsDataURL(file);

        fr.addEventListener('load', (evt) => {
          this.uploadLogo({ logo: file, base64: evt.target.result })
            .then(() => {
              this.uploadingLogo = false;
            })
            .catch(() => {
              this.uploadingLogo = false;
            });
        });
      }
    },
    saveLink(id, route) {
      const data = { route_key: route };
      this.updateRoute({ data, id });
    },
    checkImage({ valid }) {
      this.logoInput.valid = valid;
    },
    saveColor() {
      const colors = {
        background_color: this.colorPickers[0].color,
        font_color: this.colorPickers[1].color,
        button_color: this.colorPickers[2].color,
        button_font_color: this.colorPickers[3].color,
      };

      this.updateTheme(colors);
    },
    resetColors() {
      this.colorPickers[0].color = this.defaultTheme.background_color;
      this.colorPickers[1].color = this.defaultTheme.font_color;
      this.colorPickers[2].color = this.defaultTheme.button_color;
      this.colorPickers[3].color = this.defaultTheme.button_font_color;

      this.updateTheme(this.defaultTheme);
    },
    updatePassword() {
      const formError = this.pass.inputs.find((i) => !i.valid);
      this.validated = true;

      if (!formError) {
        const payload = {
          password: this.pass.inputs[0].value,
          new_password: this.pass.inputs[1].value,
        };

        this.changePassword(payload)
          .then(() => {
            const notify = {
              text: `<p><b>Contraseña actualizada</b></p>`,
              theme: 'success',
            };

            window.notify(notify);

            this.resetInputs();
          })
          .catch((err) => {
            if (err.response.data.error.code === 'CURRENT_NOT_MATCH') {
              const notifyErr = {
                text: `
              <p><b>La contraseña no fue actualizada</b></p>
              <p>La contraseña actual no es correcta</p>
              `,
                theme: 'error',
              };

              window.notify(notifyErr);
            }
          });
      }
    },
    handleInput({ value, i }) {
      this.pass.inputs[i].value = value;
    },
    checkInput({ valid, i }) {
      this.pass.inputs[i].valid = valid;
    },
    resetInputs() {
      this.pass.inputs.forEach((inp, i) => {
        this.pass.inputs[i].value = '';
      });
      this.validated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
.settings {
  &-pick-color {
    input[type='color'] {
      -webkit-appearance: none;
      border: none;
      width: 54px;
      height: 54px;
      background: transparent;
    }
    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
      border: 1px solid $dark-gray-bg;
    }
    input[type='text'] {
      width: 110px;
    }
  }
  &-picture {
    max-width: 250px;
    .file-upload {
      border-radius: 50%;
    }
  }
}
.input {
  max-width: 400px;
}
.social-input {
  min-width: 510px;
  max-width: 510px;
}
</style>
