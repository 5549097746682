<template>
  <div class="tab-wrapper position-relative bg-light">
    <BoardItemsChained
      v-if="products.items.length || categoryList.length"
      ref="itemsChained"
      class="board-content h-100"
      :items="items"
      :childsProp="'products'"
      :templateEnable="existTemplates"
      :modal="'#EditorModal'"
      :tagsTitle="'Variantes asignadas'"
      v-bind="listSettings"
      @action="emitAction"
      @orderList="orderList"
      @changeParent="changeParent" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :cta="true"
      :title="title"
      :target="$wordUp(target)"
      :description="`Antes de agregar productos debes crear al menos una familia y una categoría.`">
      <template v-slot:button>
        <Button class="m-auto" @click="$emit('goTo')">Ir a Familias y Categorías</Button>
      </template>
      <span class="material-symbols-outlined me-2" style="font-size: 8rem">fastfood</span>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>

    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="removeItem"></button>
    <button ref="reset" class="d-none" @click="resetInputs"></button>
    <button
      ref="masterModal"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#MasterEnableModal"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
import BoardItemsChained from '@/components/boards/BoardItemsChained.vue';

export default {
  name: 'BoardProducts',
  components: {
    Button,
    Spinner,
    BoardPlaceholder,
    BoardItemsChained,
  },
  mounted() {
    if (!this.families.length) {
      this.getAllCategories().then(() => this.getAll());
    } else {
      this.getAll();
    }
  },
  data() {
    return {
      title: 'Productos',
      target: 'producto',
      caption: `- Ordena la posición de los productos arrastrando los elementos.
      - Filtra productos por familia o categoria haciendo click sobre el icono de la esquina superior izquierda de la tabla `,
      category: null,
      itemToUpdate: null,
      itemToDelete: null,
      listSettings: {
        parents: { target: 'categoría', sort: false, hideActions: true, actions: { edit: true } },
        childs: { target: 'producto', sort: true, actions: { edit: true } },
      },
    };
  },
  props: {
    search: String,
    filterFamily: Number,
    existTemplates: Boolean,
  },
  computed: {
    ...mapState({
      products: (state) => state.products,
      templates: (state) => state.templates,
      categories: (state) => state.categories,
    }),
    items() {
      const productList =
        this.search && this.products.items.length
          ? this.products.items.filter((p) =>
              p.name.toLowerCase().includes(this.search.toLowerCase()),
            )
          : this.products.items;

      const productsInCategories = this.categoryList.map((c) => {
        const products = productList.filter((p, i) => {
          if (!p.added && p.category_id === c.id) {
            productList[i].added = true;
            return true;
          }
          return false;
        });
        return {
          ...c,
          products: products.map((pp) => ({
            ...pp,
            variantTags: pp.variant_groups.map((vg) => vg.name),
          })),
        };
      });

      const productsUnclassified = {
        id: 0,
        name: 'Productos sin clasificar',
        products: productList.filter((p) => !p.added),
      };

      if (productsUnclassified.products.length && !this.filterFamily)
        productsInCategories.unshift(productsUnclassified);

      productList.forEach((p, i) => {
        productList[i].added = false;
      });

      return this.search
        ? productsInCategories.filter((c) => c.products.length)
        : productsInCategories;
    },
    categoryList() {
      const categories = this.filterFamily
        ? this.families.find((f) => f.id === this.filterFamily).categories
        : this.families.map((f) => f.categories);

      if (Array.isArray(categories[0])) return categories.reduce((pre, cur) => pre.concat(cur));
      if (categories.length) return categories;
      return [];
    },
    families() {
      return this.categories.items;
    },
    loading() {
      return this.categories.loading;
    },
    error() {
      return this.categories.error;
    },
  },
  methods: {
    ...mapActions('categories', { getAllCategories: 'getAll' }),
    ...mapActions('products', [
      'getAll',
      'create',
      'update',
      'remove',
      'resetInputs',
      'setItemValues',
      'orderPosition',
      'changeCategory',
    ]),
    emitAction(action, target, id, parent) {
      this.$emit('action', action, target, id, parent);

      this.category = parent;

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues(id);
        this.$emit('updateProducto', id);
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    createItem() {
      try {
        this.create({ parentId: this.category }).then((id) => {
          if (this.templates.items.length) {
            this.$emit('action', 'create', 'producto', id);
            this.$refs.masterModal.click();
          }
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    updateItem() {
      this.update(this.itemToUpdate).then((err) => {
        if (!err) {
          const updateInTemplate = this.products.inputs.some(
            (inp) => inp.updated && inp.editInTemplate,
          );

          if (this.templates.items.length && updateInTemplate) {
            this.$emit('action', 'update', 'producto', this.itemToUpdate);
            this.$refs.masterModal.click();
          }
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
        }
      });
    },
    orderList(itemList) {
      this.orderPosition(itemList);
    },
    removeItem() {
      this.remove({ id: this.itemToDelete }).then(() => {});
    },
    changeParent(parentId, childId) {
      if (parentId) {
        this.changeCategory({ id: childId, category_id: parentId });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
