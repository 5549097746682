<template>
  <div :class="classList" :title="!value ? 'Activar' : 'Desactivar'">
    <input
      :id="name"
      type="checkbox"
      :disabled="disabled"
      :checked="checked"
      v-model="value"
      @click="$emit('click')" />
    <label :for="name">Toggle</label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  data() {
    return {
      value: this.checked,
      classList: {
        'toggle': this.size === 'lg',
        'toggle-sm': this.size === 'sm',
      },
    };
  },
  props: {
    name: String,
    size: { type: String, default: 'sm' },
    error: Boolean,
    checked: Boolean,
    disabled: Boolean,
  },
  watch: {
    checked(v) {
      this.value = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 96px;
    height: 44px;
    background: #e6e6f5;
    display: block;
    border: 1px solid $primary;
    border-radius: 50px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    background: $primary;
    border-radius: 50px;
    transition: 0.3s;
  }

  input:checked + label:after {
    left: 100%;
    transform: translateX(-100%);
  }

  label:active:after {
    width: 44px;
  }
}
.toggle-sm {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    border-radius: 20px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 14px;
    transition: 0.3s;
  }

  input:checked + label {
    background: $primary;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 14px;
  }

  // centering
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
</style>
