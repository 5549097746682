import { log, http, menu } from '../config';

import authHeader from './authHeader';

export const logout = () => {
  // Remove logging session from local storage to log user out
  localStorage.clear();
};

export const refreshToken = () => {
  const token = localStorage.getItem('refresh_token');

  const config = {
    method: 'get',
    url: '/auth/refresh-token',
    headers: { Authorization: `Bearer ${token}` },
  };

  return menu
    .http(config)
    .then(({ data }) => {
      if (data.access_token) localStorage.setItem('access_token', data.access_token);
    })
    .catch((error) => {
      const { status, data } = error.response;

      // if refresh token expires, then will logout
      if (
        status === 401 &&
        (data.error.message === 'Token expired' || data.error.message === 'Wrong token')
      ) {
        logout();
        window.location.reload(true);
      }
    });
};

export const handleError = (error) => {
  if (error.response) {
    const { status, data } = error.response;
    const { config } = error;

    if (
      status === 401 &&
      (data.error.message === 'Token expired' || data.error.message === 'Wrong token')
    ) {
      return refreshToken()
        .then(() => {
          log('Reattempting request');

          const cfg = {
            method: config.method,
            url: config.baseURL + config.url,
            data: config.data,
            headers: { ...config.headers, ...authHeader() },
          };

          // Reattempting request
          return http(cfg);
        })
        .catch((err) => log(err));
    }
    throw error;
  } else if (error.request) {
    log(error.request);
  } else {
    log('handleError', error.message);
  }

  return null;
};

export default {
  handleError,
};
