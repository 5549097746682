import service from '../../services/sucursales.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre de Sucursal',
      value: '',
      required: true,
      placeholder: 'Sucursal Zapopan Sur',
    },
    {
      name: 'template_id',
      label: 'Plantilla',
      value: '',
      type: 'list',
      placeholder: 'Selecciona una plantilla',
      options: [],
    },
    {
      icon: '<span class="material-symbols-outlined">location_on</span>',
      name: 'address',
      label: 'Dirección',
      value: '',
      placeholder: 'Calle, número, colonia, ciudad y estado',
    },
    {
      icon: '<span class="material-symbols-outlined">call</span>',
      name: 'phone',
      label: 'Teléfono',
      type: 'tel',
      value: '',
      placeholder: 'Teléfono',
    },
    {
      icon: '<i class="icon-whatsapp" style="font-size: 24px"></i>',
      name: 'whatsapp_num',
      label: 'Número de whatsapp',
      type: 'tel',
      value: '',
      placeholder: 'Número de whatsapp',
    },
    {
      icon: '<i class="icon-facebook" style="font-size: 24px"></i>',
      name: 'facebook_usr',
      label: 'Facebook Url',
      value: '',
      type: 'network',
      placeholder: 'Usuario',
      prefix: 'https://www.facebook.com/',
    },
    {
      icon: '<i class="icon-instagram" style="font-size: 24px"></i>',
      name: 'instagram_usr',
      label: 'Instagram Url',
      value: '',
      type: 'network',
      placeholder: 'Usuario',
      prefix: 'https://www.instagram.com/',
    },
    {
      icon: '<i class="icon-tiktok" style="font-size: 24px"></i>',
      name: 'tiktok_usr',
      label: 'TikTok Url',
      value: '',
      type: 'network',
      placeholder: 'Usuario',
      prefix: 'https://www.tiktok.com/@',
    },
    {
      icon: '<span class="material-symbols-outlined">language</span>',
      name: 'website',
      label: 'Página Web',
      value: '',
      placeholder: 'Página Web',
    },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  create({ state, dispatch, commit }) {
    let payload = {};

    const formError = state.inputs.find((i) => !i.valid);

    if (formError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    commit('loading');
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    log('New Sucursal', payload);

    return service
      .create(payload)
      .then(async () => {
        state.validated = false;
        return dispatch('getAll');
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, commit }, { id, templates }) {
    let payload = {};

    const formError = state.inputs.find((i) => !i.valid);

    if (formError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    state.inputs.forEach((i) => {
      if (i.value) {
        payload[i.name] = i.value;
      } else if (!i.required || (!i.value && i.name === 'template_id')) {
        payload[i.name] = null;
      }
    });

    log('Update Sucursal', payload);

    return service
      .update(id, payload)
      .then(() => {
        if (payload.template_id) {
          payload.template = templates.find((t) => t.id === payload.template_id);
        } else {
          payload.template = { id: null };
        }
        updateLocalItem(id, state.items, payload);
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  updateRoute({ state, commit }, { id, data }) {
    log('Update Sucursal Route', data);

    return service
      .update(id, data)
      .then(() => updateLocalItem(id, state.items, data))
      .catch((err) => handleError(commit, err));
  },
  initSucursal({ commit, dispatch }, payload) {
    return service
      .create(payload)
      .then(async ({ data }) => {
        await dispatch('getAll');
        return data.info.id;
      })
      .catch((err) => handleError(commit, err));
  },
  simpleUpdate({ commit }, { id, payload }) {
    return service.update(id, payload).catch((err) => handleError(commit, err));
  },
  enable({ commit, state }, { id, boolean }) {
    const item = state.items.find((i) => i.id === id);

    return service
      .enable(id, boolean)
      .then(() => {
        item.enabled = boolean;
      })
      .catch((err) => {
        if (item) item.enabled = false;

        handleError(commit, err);

        if (err.response) throw err.response;
      });
  },
  remove({ commit }, id) {
    commit('deleteRequest', id);

    service
      .remove(id)
      .then(() => commit('deleteSuccess', id))
      .catch((err) => commit('deleteFailure', { id, error: err }));
  },
  removeTemplateInput({ state }) {
    if (state.inputs.length === 3) {
      state.inputs.pop();
    }
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  setInputOptions({ commit }, input) {
    commit('setInputOptions', input);
  },
  setItemValues({ state, commit }, id) {
    const item = state.items.find((i) => i.id === id);
    commit('setItemValues', item);
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  // Get all
  getAllSuccess(state, sucursales) {
    state.items = sucursales;
    state.loading = false;
  },
  // Remove item
  deleteRequest(state, id) {
    // add 'deleting:true' property to sucursal being deleted
    state.items = state.items.map((sucursal) =>
      sucursal.id === id ? { ...sucursal, deleting: true } : sucursal,
    );
  },
  deleteSuccess(state, id) {
    // remove deleted sucursal from state
    state.items = state.items.filter((sucursal) => sucursal.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to sucursal
    state.items = state.items.map((sucursal) => {
      if (sucursal.id === id) {
        // make copy of sucursal without 'deleting:true' property
        const { deleting, ...itemCopy } = sucursal;
        // return copy of sucursal with 'deleteError:[error]' property
        return { ...itemCopy, deleteError: error };
      }

      return sucursal;
    });
  },
  setInputOptions(state, { name, options }) {
    const index = state.inputs.findIndex((i) => i.name === name);
    state.inputs[index].options = options;
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setItemValues(state, item) {
    log('setItemValues', item);
    state.inputs = state.inputs.map((i) => {
      if (i.name === 'template_id') return { ...i, value: item.template.id || '' };
      if (i.name === 'phone' && item.phone) {
        const phone = item.phone.replace(/\D/g, '');

        const value = `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 10)}`;

        return { ...i, value: value };
      }
      return { ...i, value: item[i.name] };
    });
  },
};

// Updated props into local items, avoid api request to update
function updateLocalItem(id, items, data) {
  const item = items.find((i) => i.id === id);
  Object.entries(data).forEach(([prop, value]) => {
    item[prop] = value;
  });
}

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
