<template>
  <!-- Link button -->
  <a v-if="href" :href="href" :class="classList" :disabled="disabled" @click="$emit('click')">
    <slot></slot>
  </a>
  <!-- App button -->
  <button v-else :class="classList" :type="type" :disabled="disabled" @click="$emit('click')">
    <transition name="fade" mode="out-in">
      <span v-if="!submitted">
        <slot></slot>
      </span>
      <Spinner :size="'sm'" v-else-if="animation === 'loading'" />
    </transition>
  </button>
</template>

<script>
import Spinner from '@/components/common/Spinner.vue';

export default {
  name: 'Button',
  components: {
    Spinner,
  },
  props: {
    type: { type: String, default: 'button' },
    styled: { type: String, default: 'primary' },
    href: String,
    size: String,
    animation: String,
    disabled: Boolean,
    submitted: Boolean,
  },
  computed: {
    classList() {
      return {
        'btn': true,
        'd-block': true,
        'btn-sm': this.size === 'sm',
        'btn-lg': this.size === 'lg',
        'btn-light': this.styled === 'light',
        'btn-normal': this.styled === 'normal',
        'btn-danger': this.styled === 'danger',
        'btn-outline': this.styled === 'outline',
        'btn-primary': this.styled === 'primary',
        'btn-secondary': this.styled === 'secondary',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: $light;
  color: $primary;
  &:hover {
    background: $primary-xx-light;
  }
  &:active {
    background: $primary;
    color: $light;
  }
  &-normal {
    background: $light;
    color: $primary;
    &:hover {
      background: $primary-x-light;
    }
    &:active {
      background-color: $primary;
      color: $light;
      border: 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &-outline {
    background: $light;
    color: $primary;
    border: 1px solid $primary;
    &:hover {
      background: $primary;
      color: $light;
    }
  }
  &-light {
    background: $primary-light;
    color: $light;
    &:hover {
      background: rgba($primary, 0.85);
    }
  }
  &-primary {
    background: $primary;
    color: $light;
    &:hover {
      background: rgba($primary, 0.85);
    }
  }
  &-secondary {
    background: $secondary;
    color: $light;
    &:hover {
      background: rgba($secondary, 0.85);
    }
  }
  &-danger {
    background: $tertiary;
    color: $light;
    &:hover {
      background: rgba($tertiary, 0.85);
    }
  }
}

/* Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
