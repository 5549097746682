import service from '../../services/templates.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre de plantilla',
      value: '',
      required: true,
      placeholder: 'Sucursales Guadalajara',
    },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  getOne({ commit }, id) {
    return service.getOne(id).then(({ data }) => {
      commit('getOneSuccess');
      return data;
    });
  },
  create({ state, dispatch, commit }, cb) {
    let payload = {};
    const formError = state.inputs.find((i) => !i.valid);
    if (formError) {
      state.validated = true;
      return cb(null, new Error('FORM_ERROR'));
    }
    commit('loading');
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });
    log('New Template', payload);
    return service
      .create(payload)
      .then(async ({ data }) => {
        const newTemplate = await dispatch('getOne', data.info.id);
        log('getOne', newTemplate);
        commit('createSuccess', newTemplate);
        state.validated = false;
        cb(data.info.id);
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, commit }, id) {
    let payload = {};
    const formError = state.inputs.find((i) => !i.valid);
    if (formError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });
    log('Update Sucursal', payload);
    return service
      .update(id, payload)
      .then(() => {
        updateLocalItem(id, state.items, payload);
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  clone({ commit, dispatch }, id) {
    commit('loading');

    return service
      .clone(id)
      .then(async ({ data }) => {
        const newTemplate = await dispatch('getOne', data.info.id);
        commit('createSuccess', newTemplate);
      })
      .catch((err) => handleError(commit, err));
  },
  remove({ commit }, id) {
    commit('deleteRequest', id);

    service
      .remove(id)
      .then(() => commit('deleteSuccess', id))
      .catch((err) => commit('deleteFailure', { id, error: err }));
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  setItemValues({ state, commit }, id) {
    const item = state.items.find((i) => i.id === id);
    commit('setItemValues', item);
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  // Get all
  getAllSuccess(state, templates) {
    state.items = templates;
    state.loading = false;
  },
  getOneSuccess(state) {
    state.loading = false;
  },
  createSuccess(state, product) {
    state.items.push(product);
  },
  // Remove item
  deleteRequest(state, id) {
    // add 'deleting:true' property to template being deleted
    state.items = state.items.map((template) =>
      template.id === id ? { ...template, deleting: true } : template,
    );
  },
  deleteSuccess(state, id) {
    // remove deleted template from state
    state.items = state.items.filter((template) => template.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to template
    state.items = state.items.map((template) => {
      if (template.id === id) {
        // make copy of template without 'deleting:true' property
        const { deleting, ...itemCopy } = template;
        // return copy of template with 'deleteError:[error]' property
        return { ...itemCopy, deleteError: error };
      }

      return template;
    });
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
};

// Updated props into local items, avoid api request to update
function updateLocalItem(id, items, data) {
  const item = items.find((i) => i.id === id);
  Object.entries(data).forEach(([prop, value]) => {
    item[prop] = value;
  });
}

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
