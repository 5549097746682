<template>
  <div class="row">
    <div class="mb-5">
      <div v-if="product === 'menú'" class="flex-center">
        <span class="fs-3">Mensual</span>
        <Toggle
          class="d-flex mx-2"
          :name="'planAnual'"
          :checked="planAnual"
          @click="planAnual = !planAnual" />
        <span class="fs-3">Anual</span>
      </div>
    </div>

    <div
      v-if="!plans.loading && product === 'menú'"
      class="md-pricing-plans m-auto d-lg-flex justify-content-center pt-3 px-2">
      <PlanCard
        class="md-pricing-card flex-grow-1 d-none d-lg-block"
        v-for="(p, i) in menuPlans"
        :key="i"
        v-bind="p"
        :product="product"
        :planAnual="planAnual"
        :currentPlan="currentPlan"
        @click="choosePlan(p)" />
    </div>
    <div v-else-if="!plans.loading && product === 'sucursales'" class="row">
      <div class="md-pricing-plans m-auto col-4 pt-3 px-2">
        <Input
          class="mb-2"
          :name="'numSucursales'"
          :type="'number'"
          :label="'Indica las sucursales que necesitas'"
          :placeholder="'Cantidad de sucursales'"
          v-model="numSucursales" />
          <!-- :options="plans.sucursalesPlans" /> -->

        <small class="text-danger" v-if="error">Indica 1 o más sucursales</small>

        <PlanCard
          v-if="sucursalesPlan"
          class="md-pricing-card flex-grow-1 d-none d-lg-block mt-5"
          :product="product"
          :planAnual="planAnual"
          v-bind="sucursalesPlan"
          :currentPlan="currentPlan"
          @click="setSucursales(sucursalesPlan)" />

        <!-- <Button
          v-if="planInUse && newMenuPlan"
          class="mx-auto mt-5"
          @click="choosePlan(sucursalesPlan)"
          >Continuar</Button
        > -->
      </div>
      <!-- Sucursales Selector -->
      <div v-if="showSucursalesSelector" class="offset-2 col-8">
        <p>
          De las sucursales activas actualmente desmarca las sucursales que no necesitas, Se
          mantendrán activas hasta que termine el periodo actual acorde a tu plan de sucursales
        </p>
        <ul
          class="p-0"
          style="list-style: none; max-width: 300px; margin: auto; height: 100px; overflow: auto">
          <li v-for="s in enabledSucursales" :key="s.id">
            <div class="form-check">
              <label class="form-check-label" :for="s.id">
                {{ s.name }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                :checked="s.enabled"
                :id="s.id"
                @click="addRemoveSuc(s)" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Spinner
      v-else
      :class="'text-primary'"
      style="width: fit-content"
      :size="'lg'"
      :center="true" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import PlanCard from '@/components/common/PlanCard2.vue';
import Spinner from '@/components/common/Spinner.vue';
// import Button from '@/components/common/Button.vue';
import Toggle from '@/components/common/Toggle.vue';
import Input from '@/components/common/Input.vue';

export default {
  name: 'SubscriptionPlanSelector',
  components: {
    PlanCard,
    // Button,
    Spinner,
    Toggle,
    Input,
  },
  mounted() {
    this.getPlans();

    if (!this.sucursales.items.length) {
      this.getAllSucursales();
    }
    if (this.currentPlan) {
      this.planAnual = this.currentPlan.interval === 'yearly';
    } else {
      this.planAnual = this.newMenuPlan.interval === 'yearly';
    }

    if (this.currentPlan.features && this.currentPlan.features.length) {
      const [feature] = this.currentPlan.features;
      this.numSucursales = feature.quantity;
    }
  },
  data() {
    return {
      planAnual: false,
      numSucursales: 1,
      error: false,
      showSucursalesSelector: false,
      sucursalesToDisable: [],
    };
  },
  props: {
    product: String,
    subscriptions: Array,
    newMenuPlan: Object,
  },
  computed: {
    ...mapState({
      plans: (state) => state.plans,
      profile: (state) => state.profile,
      sucursales: (state) => state.sucursales,
    }),
    currentPlan() {
      const [current] = this.subscriptions;
      // if (current && this.product === 'sucursales' && this.plans.sucursalesPlans.length) {
      //   const plan = this.plans.sucursalesPlans.find((p) => p.name === current.plan);
      //   return { ...current, id: plan.id };
      // }
      return current;
    },
    enabledSucursales() {
      return this.sucursales.items.filter((s) => s.enabled);
    },
    planInUse() {
      if (this.currentPlan && this.sucursalesPlan) {
        return this.sucursalesPlan.price === this.currentPlan.amount;
      }
      return false;
    },
    menuPlans() {
      const features = [
        // {
        //   id: 1,
        //   title: 'Gratis',
        //   price: 0,
        //   subtitle: 'para siempre',
        //   description: 'Ideal para pequeños negocios o que van iniciando',
        //   features: ['1 sola sucursal', 'Hasta 35 productos', 'Hasta 5 promociones'],
        // },
        {
          id: 3,
          title: 'Negocio',
          price: 299,
          subtitle: 'en crecimiento',
          description: 'Ideal para negocios establecidos con una sucursal',
          features: [
            '1 sola sucursal',
            'Productos ilimitados',
            'Promociones ilimitadas',
            'Personalízalo con tu marca y colores',
            'Módulo de publicidad de tu negocio',
          ],
        },
        {
          id: 4,
          title: 'Franquicias',
          price: 399,
          subtitle: 'y cadenas',
          description: 'Ideal para negocios con múltiples sucursales',
          plan: 'MENU-FRANCHISES',
          features: [
            'Multisucursales',
            'Productos ilimitados',
            'Promociones ilimitadas',
            'Personalízalo con tu marca y colores',
            'Módulo de publicidad de tu negocio',
            'Plantilla multisucursal',
          ],
        },
      ];

      const plans = this.plans.menuPlans.map((p) => {
        const feat = features.find((f) => f.id === p.id);

        return { ...p, ...feat, price: p.intervals[0]?.amount };
      });

      return plans.filter((p) => !!p.title);
    },
    sucursalesPlan() {
      return {
        title: `${this.numSucursales} Sucursales`,
        price: (this.profile.info.currency === 'USD' ? 12.5 : 250) * this.numSucursales,
      };
    },
  },
  watch: {
    currentPlan(v) {
      if (v && v.features) {
        const feature = v.features.find((f) => f.name === 'Sucursales');
        this.numSucursales = feature.quantity;
      }
    },
    newMenuPlan(v) {
      if (v) {
        this.planAnual = v.interval === 'yearly';
      }
    },
    sucursalesPlan(v) {
      if (v) {
        this.showSucursalesSelector = this.enabledSucursales.length > this.numSucursales;
      }
    },
  },
  methods: {
    ...mapActions('plans', ['getMenuPlans']),
    ...mapActions('sucursales', { getAllSucursales: 'getAll', enableSucursal: 'enable' }),
    addRemoveSuc(s) {
      const index = this.sucursalesToDisable.indexOf(s);

      if (!s.enabled) return;

      if (this.sucursalesToDisable.length && index >= 0) {
        this.sucursalesToDisable.splice(index, 1);
      } else {
        this.sucursalesToDisable.push(s);
      }
    },
    choosePlan(plan) {
      const interval = this.getInterval(plan.intervals);

      // const [newLimit] = interval.code.includes('SUCURSALES') ? plan.name.split(' ') : [];

      // const keepSuc = this.enabledSucursales.length - this.sucursalesToDisable.length;

      // if (this.product === 'sucursales' && keepSuc > newLimit) {
      //   this.notifyLimit();
      // } else {
      // if (this.sucursalesToDisable.length) {
      //   this.sucursalesToDisable.map((s) => this.enableSucursal({ ...s, boolean: s.enabled }));
      // }

      this.$emit('planSelected', { name: plan.name, ...interval });
      // }

      // if (interval.code.includes('FRANCHISES')) {
      //   this.getSucursalesPlans();
      // }
    },
    setSucursales(plan) {
      // const newLimit = interval.code.includes('SUCURSALES') ? plan.name.split(' ') : [];
      if (this.numSucursales <= 0) {
        this.error = true;
      } else {
        const keepSuc = this.enabledSucursales.length - this.sucursalesToDisable.length;

        if (keepSuc > this.numSucursales) {
          this.notifyLimit();
        } else {
          // this.sucursalesToDisable.map((s) => this.enableSucursal({ ...s, boolean: s.enabled }));

          this.$emit('setupSucursales', {
            name: plan.title,
            amount: this.planAnual ? plan.price * 10 : plan.price,
            code: `MENU-SUCURSALES-${this.profile.info.currency}`,
            interval: this.planAnual ? 'yearly' : 'monthly',
            quantity: this.numSucursales,
            sucursalesToDisable: this.sucursalesToDisable,
          });
        }
      }
    },
    getPlans() {
      this.getMenuPlans(this.profile.info.currency);
      // if (this.product === 'menú') {
      // } else {
      //   this.getSucursalesPlans();
      // }
    },
    getInterval(intervals) {
      const interval = this.planAnual ? 'yearly' : 'monthly';
      return intervals.find((i) => i.interval === interval) || intervals[0];
    },
    notifyLimit() {
      const notify = {
        text: `
        <p>El limite de sucursales que elegiste es menor a las sucursales habilitadas actualmente.</p>
        `,
        theme: 'grey',
      };

      window.notify(notify);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-pricing {
  padding-top: 100px;
  &-plans {
    padding-bottom: 100px;
  }
}

@media (min-width: 992px) {
  .md-pricing {
    padding: 140px 0;
    &-plans {
      padding-bottom: 20px;
    }
  }
}
@media (min-width: 1200px) {
  .md-pricing {
    &-plans {
      gap: 20px;
    }
  }
}
</style>
