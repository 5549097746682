import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const groups = {
  getAll: () => {
    const config = {
      method: 'GET',
      url: '/v1/groups',
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  getOne: (id) => {
    const config = {
      method: 'GET',
      url: `/v1/groups/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  getByTemp: (templateId) => {
    const config = {
      method: 'GET',
      url: `/v1/templates/${templateId}/groups/variants`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  create: (data) => {
    const config = {
      method: 'POST',
      url: `/v1/groups`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  update: (id, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/groups/${id}`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  remove: (id) => {
    const config = {
      method: 'DELETE',
      url: `/v1/groups/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
};

const variants = {
  create: (variant, groupId) => {
    const config = {
      method: 'POST',
      url: `/v1/groups/${groupId}/variants`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: variant,
    };

    return menu.http(config).catch(handleError);
  },
  update: (id, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/groups/variants/${id}`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  updateByTemp: (templateId, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/templates/${templateId}/groups/variants`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  orderPosition: (variantList) => {
    const config = {
      method: 'PUT',
      url: `/v1/groups/0000/variants`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: variantList,
    };

    return menu.http(config).catch(handleError);
  },
  remove: (id) => {
    const config = {
      method: 'DELETE',
      url: `/v1/groups/variants/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
};

export default {
  groups,
  variants,
};
