<template>
  <div
    id="ShareLinkModal"
    class="modal fade"
    aria-hidden="true"
    aria-labelledby="ShareLinkModalLabel">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title flex-grow-1 text-primary fs-4" id="ShareLinkModalLabel">
            Compartir Link
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body text-center pb-0">
          <!-- Full Link Preview -->
          <p class="text-disabled">{{ `${prefix}/${value}` }}</p>

          <div class="text-start w-100 mb-5">
            <label for="link" class="form-label input-box ps-3 mb-0">
              <input
                class="form-control ps-0 pe-3"
                ref="link"
                id="link"
                name="link"
                type="link"
                v-model="value"
                required
                @input="changed = value !== route" />
              <button v-if="!changed" class="btn copy text-light" @click="copy">Copiar</button>
              <button v-else class="btn btn-primary" @click="saveLink(id, value)">Guardar</button>
            </label>
            <small class="text-danger" v-if="error">{{ msg }}</small>
          </div>
          <!-- <canvas ref="qrHolder"></canvas> -->
          <img :src="dataURL" alt="QR Holder" />
        </div>
        <div class="modal-footer justify-content-between pt-5">
          <a :href="dataURL" :download="'QR_' + route_key" class="btn btn-secondary w-100">
            <i class="icon-qr me-3"></i>
            Descargar QR
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

import { mapState } from 'vuex';
import { menu } from '../config';

export default {
  name: 'ShareLinkModal',
  created() {},
  data() {
    return {
      value: this.link,
      route: null,
      prefix: menu.url.split('/')[2],
      dataURL: '',
      changed: false,
      error: false,
      msg: '',
    };
  },
  props: {
    id: String,
    route_key: String,
  },
  computed: {
    ...mapState({
      sucursales: (state) => state.sucursales.all,
    }),
  },
  watch: {
    route_key(val) {
      this.value = val;
      this.route = val;
      this.getQR(`${menu.url}/${this.id}`);
      this.error = false;
      this.changed = false;
    },
  },
  methods: {
    saveLink(id, link) {
      if (!link) {
        this.error = true;
        this.msg = 'No puede ser vacio';
      } else if (this.checkString(link)) {
        this.error = true;
        this.msg = 'No debe contener espacios en blanco';
      } else {
        this.$http
          .get(`${menu.apiUrl}/v1/sucursales/url/${link}`)
          .then(() => {
            this.error = true;
            this.msg = 'Ya esta en uso';
          })
          .catch(() => {
            this.$emit('click', id, link);
            this.changed = false;
            this.error = false;
          });
      }
    },
    copy() {
      navigator.clipboard.writeText(`${this.prefix}/${this.$refs.link.value}`);
    },
    getQR(link) {
      const opts = {
        errorCorrectionLevel: 'H',
        // type: 'image/jpeg',
        quality: 0.5,
        margin: 2,
        scale: 6,
      };

      QRCode.toDataURL(link, opts, (err, url) => {
        this.dataURL = url;
      });
    },
    checkString(s) {
      return /\s/g.test(s);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-disabled {
  color: #6c757d;
}
.modal {
  &-dialog {
    max-width: 450px;
  }
  &-header {
    background-color: #e1e0ff;
  }
  &-body {
    .input-box {
      display: flex;
      align-items: center;
      border: 2px solid #ced4da;
      border-radius: 4px;
      overflow: hidden;
      input {
        flex-grow: 1;
        border: none;
        outline: none;
        &:focus {
          border: 0;
          box-shadow: none;
        }
      }
      .prefix {
        font-weight: 400;
        color: #999;
      }
    }
    .copy {
      background: $primary-light;
    }
  }
  &-footer button {
    flex: 1;
  }
}
.btn-qr {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: $primary-light;
  cursor: pointer;
  & > span {
    background: transparent;
    width: 100%;
    font-size: 2rem;
    // border: 3px solid $primary;
    border-radius: $border-radius;
    line-height: 1;
    span {
      font-size: 3rem;
    }
  }
}
</style>
