<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100" style="background-color: #f9f9f9">
      <Board
        :title="title"
        :caption="caption"
        :target="$wordUp(target)"
        :modal="target === 'plantilla' ? '#TemplateEditorModal': ''"
        @action="emitAction">
        <template v-slot:icon>
          <i class="icon-store"></i>
        </template>
        <Tabs class="my-3" :tabs="tabs" @click="switchTab"/>

        <TabSucursales
          v-if="tab === 'sucursales'"
          ref="sucursales"
          @action="(a,b,c,d) => $emit('action', a,b,c,d)" />
        <TabTemplates
          v-else-if="tab === 'templates'"
          ref="templates"
          @action="(a,b,c,d) => $emit('action', a,b,c,d)" />
      </Board>
    </div>
  </div>
</template>

<script>
import TabSucursales from '@/components/boards/boardTabs/TabSucursales.vue';
import TabTemplates from '@/components/boards/boardTabs/TabTemplates.vue';
import Board from '@/components/boards/Board.vue';
import Tabs from '@/components/common/Tabs.vue';

export default {
  name: 'BoardMenu',
  components: {
    TabSucursales,
    TabTemplates,
    Board,
    Tabs,
  },
  mounted() {
    this.caption = this.tabs[0].caption;

    this.switchTab(this.tab)
  },
  data() {
    return {
      title: 'Multisucursal',
      target: 'sucursal',
      caption: '',
      filterFamily: null,
      itemToUpdate: null,
      itemToDelete: null,
      tabs: [
        {
          title: 'Sucursales',
          icon: 'store',
          tab: 'sucursales',
          target: 'sucursal',
          caption: '',
          active: false,
        },
        {
          title: 'Plantillas',
          icon: 'content_copy',
          tab: 'templates',
          target: 'plantilla',
          caption: `Utiliza las plantillas para asignar productos, promociones, precios y publicidad distintos en las templates, asi cada que crees una nueva sucursal puedes asignarle una plantilla ya guardada y ahorrar mucho tiempo.`,
          active: false,
        },
      ]
    };
  },
  props: {
    tab: String,
  },
  methods: {
    switchTab(v) {
      this.$emit('click', `/multisucursal/${v}`)

      this.tabs = this.tabs.map((t) => {
        if (t.tab === v) {
          this.caption = t.caption
          this.target = t.target
          return {...t, active: true};
        }

        return {...t, active: false};
      })
    },
    emitAction(action, target) {
      if (action === 'create' && target === 'Plantilla') this.$refs.templates.setAddMode()
      this.$emit('action', action, target);
    },
  },
};
</script>

<style lang="scss" scoped></style>
