<template>
  <div class="tab-wrapper position-relative bg-light">
    <BoardItemsChained
      v-if="items.length || search"
      ref="itemsChained"
      class="board-content h-100"
      :items="items"
      :childsProp="'variants'"
      :templateEnable="existTemplates"
      :tagsTitle="'Productos asignados:'"
      v-bind="listSettings"
      :modal="'#VariantsEditorModal'"
      @orderList="orderList"
      @action="emitAction" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :title="title"
      :target="'Grupo de variantes'"
      :modal="'#EditorModal'"
      :description="`Aún no tienes ningún grupo de variantes registrado.`"
      @click="emitAction('create', target)">
      <span class="material-symbols-outlined me-2" style="font-size: 8rem">category</span>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>

    <button ref="imgInputs" class="d-none" @click="updateImageInputs()"></button>
    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="removeItem"></button>
    <button ref="reset" class="d-none" @click="resetInputs"></button>
    <button
      ref="masterModal"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#MasterEnableModal"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
import BoardItemsChained from '@/components/boards/BoardItemsChained.vue';

export default {
  name: 'BoardVariants',
  components: {
    Spinner,
    BoardPlaceholder,
    BoardItemsChained,
  },
  mounted() {
    this.getAll();

    if (!this.products.items.length) {
      this.getAllProducts();
    }
  },
  data() {
    return {
      title: 'Grupos y Variantes',
      target: 'grupo',
      group: '',
      itemToUpdate: null,
      itemToDelete: null,
      listSettings: {
        parents: { target: 'grupo', sort: false, actions: { edit: false } },
        childs: {
          target: 'variante',
          sort: true,
          actions: { edit: false },
          parentAction: 'Editar grupo de variantes',
        },
      },
    };
  },
  props: {
    search: String,
    existTemplates: Boolean,
  },
  computed: {
    ...mapState({
      variants: (state) => state.variants,
      products: (state) => state.products,
      templates: (state) => state.templates,
    }),
    items() {
      let searchVariants = [];

      if (this.search) {
        searchVariants = this.variants.items.map((g) => {
          // Filtering groups
          const variants = g.variants.filter((v) =>
            v.name.toLowerCase().includes(this.search.toLowerCase()),
          );

          return { ...g, variants };
        });
      }

      const varList = this.search
        ? searchVariants.filter(
            (g) => g.name.toLowerCase().includes(this.search.toLowerCase()) || g.variants.length,
          )
        : this.variants.items.slice(0).sort((a, b) => a.name.localeCompare(b.name));

      return varList.map((v) => {
        const products = this.products.items.filter((p) => {
          return p.variant_groups.find((vp) => vp.id === v.id);
        });

        return { ...v, products, tags: products.map((pp) => pp.name) };
      });
    },
    loading() {
      return this.variants.loading;
    },
    error() {
      return this.variants.error;
    },
  },
  methods: {
    ...mapActions('variants', [
      'getAll',
      'create',
      'update',
      'remove',
      'resetInputs',
      'orderPosition',
      'setItemValues',
      'setGroupInputs',
      'setVariantInputs',
    ]),
    ...mapActions('products', { getAllProducts: 'getAll' }),
    emitAction(action, target, id, parent) {
      console.log(action, target, id, parent);
      this.$emit('action', action, target, id, parent);

      this.group = parent;

      this.switchInputs(parent);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues({ id, parentId: this.group });
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    createItem() {
      try {
        this.create(this.group).then((id) => {
          if (this.templates.items.length) {
            this.$emit('action', 'create', 'variante', id);
            this.$refs.masterModal.click();
          }
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          // this.resetInputs();
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    updateItem() {
      this.update({ id: this.itemToUpdate, parentId: this.group }).then((err) => {
        if (!err) {
          const updateInTemplate = this.variants.inputs.some(
            (inp) => inp.updated && inp.editInTemplate,
          );

          if (this.templates.items.length && updateInTemplate) {
            this.$emit('action', 'update', 'variante', this.itemToUpdate);
            this.$refs.masterModal.click();
          }
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          // this.resetInputs();
        }
      });
    },
    orderList(itemList) {
      this.orderPosition(itemList);
    },
    removeItem() {
      this.remove({ id: this.itemToDelete, parentId: this.group }).then(() => {});
    },
    switchInputs(parent) {
      if (!parent) {
        this.setGroupInputs();
        this.group = '';
      } else {
        this.setVariantInputs();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
