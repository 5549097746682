<template>
  <ul class="checklist fs-5 p-0" :class="[`checklist-${weight}`, `fs-${size}-4`]">
    <li
      class="checklist-item d-flex align-items-center mb-2"
      :class="{ 'checklist-item-active': l.includes('*'), 'rounded-pill': check }"
      v-for="(l, i) in list"
      :key="i">
      <span
        v-if="check"
        class="icon-circle-check fs-4 text-primary pe-1"
        :class="[`fs-${size}-3`]"></span>
      <p class="ps-3" :class="{ 'm-0': fitList, 'py-1': !check }" v-html="l.replace('*', '')"></p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Checklist',
  data() {
    return {};
  },
  props: {
    list: Array,
    size: String,
    check: Boolean,
    weight: String,
    fitList: Boolean,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.checklist {
  list-style: none;
  &-item {
    position: relative;
    padding: 2px;
    border-radius: 1.2rem;
    &-active {
      background: #dadaf5;
    }
  }
  &-bold {
    font-weight: 500;
  }
  &-bolder {
    font-weight: 700;
  }
  &-item span {
    font-weight: 600;
  }
}
.icon-circle-check::before {
  position: absolute;
  top: 4px;
  left: 5px;
}
@media (min-width: 992px) {
  .icon-circle-check::before {
    top: 5px;
  }
}
</style>
