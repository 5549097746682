<template>
  <div class="plan-card position-relative rounded-5 p-4" :class="{ active: planInUse }">
    <div class="plan-card-title text-center">
      <h3 class="fs-3 lh-1">{{ `${title} / ${planAnual ? 'Anual' : 'Mensual'}` }}</h3>
      <p class="fs-6">{{ subtitle }}</p>
    </div>
    <div class="plan-card-description text-center">
      <p class="fs-6" style="max-width: 300px">{{ description }}</p>
    </div>
    <div
      v-if="price >= 0"
      class="plan-card-price text-center mb-3"
      :class="{ 'mb-lg-4': !sucursal }">
      <strong>{{ $priceFormat(planPrice, currencyCode) }}</strong
      ><small class="fs-5 fw-bolder ms-1">{{ currencyCode }}</small>
      <p class="fs-6 mt-n1" v-if="sucursal">
        {{ `+ $${sucursalPrice} ${currencyCode} por cada sucursal` }}
      </p>
    </div>
    <Checklist
      class="plan-card-feature fs-5"
      :weight="'bold'"
      :check="true"
      :fitList="true"
      :list="features" />
    <Button
      v-if="planInUse"
      :styled="'normal'"
      class="plan-card-cta position-absolute m-auto"
      @click="$emit('click')">
      Plan Actual
    </Button>
    <Button v-else class="plan-card-cta position-absolute m-auto" @click="$emit('click')">
      Elegir plan
    </Button>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Button from '@/components/common/Button.vue';
import Checklist from '@/components/common/Checklist.vue';

export default {
  name: 'PlanCard',
  components: {
    Checklist,
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    nationalUser() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.$mexTimezone.includes(timezone);
    },
    planPrice() {
      return this.planAnual ? this.currencyPrice * 10 : this.currencyPrice;
    },
    currencyPrice() {
      return this.nationalUser ? this.price : Math.ceil(this.price / 20);
    },
    currencyPriceSuc() {
      return this.nationalUser ? this.sucursal : Math.ceil(this.sucursal / 20);
    },
    currencyCode() {
      // return this.nationalUser ? 'MXN' : 'USD';
      return this.profile.info.currency;
    },
    planPeriod() {
      if (this.price) return this.planAnual ? '-Year' : '-Month';
      return '';
    },
    sucursalPrice() {
      return this.planAnual ? this.currencyPriceSuc * 10 : this.currencyPriceSuc;
    },
    ctaLink() {
      if (this.cta) return this.cta.link;
      return `/form/registro?plan=${this.plan}${this.planPeriod}`;
    },
    interval() {
      if (this.intervals) {
        const interval = this.planAnual ? 'yearly' : 'monthly';
        return this.intervals.find((i) => i.interval === interval) || this.intervals[0];
      }

      return {};
    },
    planInUse() {
      if (this.currentPlan) {
        if (this.product === 'sucursales' && this.currentPlan.features.length) {
          const [quantity, name] = this.title.split(' ');
          const [feature] = this.currentPlan.features;

          return name === feature.name && parseInt(quantity, 10) === feature.quantity;
        }
        return this.interval.code === this.currentPlan.code;
      }
      return false;
    },
  },
  props: {
    cta: Object,
    plan: String,
    title: String,
    price: Number,
    product: String,
    features: Array,
    sucursal: Number,
    subtitle: String,
    intervals: Array,
    planAnual: Boolean,
    currentPlan: Object,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.plan-card {
  max-width: 320px;
  border: 1px solid $primary;
  border-radius: 2rem;
  font-family: Avenir, sans-serif;
  &-feature {
    margin-bottom: 60px;
  }
  &-price {
    font-size: 3.2rem;
  }
  &-cta {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 63px;
    padding-right: 63px;
  }
  &.active {
    border-width: 5px;
  }
}

@media (min-width: 992px) {
  .plan-card {
    max-width: 370px;
  }
}
</style>
