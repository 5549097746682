<template>
  <button
    class="btn fs-3 px-2 py-0 lh-1 text-primary"
    :title="tooltipTitle"
    data-bs-toggle="modal"
    :data-bs-target="modal"
    @click="$emit('click')">
    <span :class="icon"></span>
  </button>
</template>

<script>
export default {
  name: 'ActionIcon',
  data() {
    return {
      icon: `icon-${this.action}`,
    };
  },
  props: {
    action: String,
    modal: String,
    tooltipTitle: String,
  },
};
</script>

<style lang="scss" scoped></style>
