<template>
  <div class="tab-wrapper position-relative bg-light">
    <BoardItemsChained
      v-if="items.length"
      ref="itemsChained"
      class="board-content h-100"
      :items="items"
      :childsProp="'categories'"
      :modal="'#EditorModal'"
      v-bind="listSettings"
      @action="emitAction"
      @orderList="orderList"
      @changeParent="changeParent" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :title="title"
      :target="$wordUp(target)"
      :modal="'#EditorModal'"
      :description="`Aún no tienes ninguna ${target} registrada.`"
      @click="emitAction('create', target)">
      <i class="icon-extension"></i>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>

    <button ref="imgInputs" class="d-none" @click="updateImageInputs()"></button>
    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="removeItem"></button>
    <button ref="reset" class="d-none" @click="reset"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
import BoardItemsChained from '@/components/boards/BoardItemsChained.vue';

export default {
  name: 'BoardCategories',
  components: {
    Spinner,
    BoardPlaceholder,
    BoardItemsChained,
  },
  mounted() {
    this.getAll();
  },
  data() {
    return {
      title: 'Familias y Categorías',
      target: 'familia',
      family: '',
      itemToUpdate: null,
      itemToDelete: null,
      listSettings: {
        parents: { target: 'familia', sort: true, actions: { edit: true } },
        childs: { target: 'categoría', sort: true, actions: { edit: true } },
      },
    };
  },
  watch: {
    family(v) {
      this.updateInputs(v);
    },
  },
  computed: {
    ...mapState({
      categories: (state) => state.categories,
    }),
    items() {
      return this.categories.items;
    },
    loading() {
      return this.categories.loading;
    },
    error() {
      return this.categories.error;
    },
  },
  methods: {
    ...mapActions('categories', [
      'getAll',
      'create',
      'update',
      'remove',
      'resetInputs',
      'changeFamily',
      'setItemValues',
      'orderPosition',
      'setInput',
      'removeInput',
      'setImageInput',
      'removeImageInput',
    ]),
    emitAction(action, target, id, parent) {
      console.log('TabCategories', action, target, id, parent);
      this.$emit('action', action, target, id, parent);

      this.family = parent;

      // Unable for new db structure (using google icons library instead uploaded icons)
      // this.updateImageInputs(parent);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues({ id, parentId: this.family });
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    createItem() {
      this.create(this.family).then((err) => {
        if (!err) {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
    updateItem() {
      this.update({ id: this.itemToUpdate, parentId: this.family }).then((err) => {
        if (!err) {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
    orderList(itemList, parentId) {
      this.orderPosition({ itemList, parentId });
    },
    removeItem() {
      this.remove({ id: this.itemToDelete, parentId: this.family }).then(() => {});
    },
    updateInputs(parent) {
      console.log('parent', parent);
      if (parent) {
        this.setInput();
      } else {
        this.family = '';
        this.removeInput();
      }
    },
    updateImageInputs(parent) {
      if (!parent) {
        this.setImageInput();
        this.family = '';
      } else {
        this.removeImageInput();
      }
    },
    changeParent(parentId, categoryId) {
      this.changeFamily({ id: categoryId, family_id: parentId });
    },
    reset() {
      this.resetInputs();
      this.family = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
