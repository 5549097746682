import service from '../../services/products.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  variants: [],
  tags: [],
  inputs: [
    {
      name: 'name',
      label: 'Nombre del producto',
      value: '',
      required: true,
      placeholder: 'Agua mineral',
    },
    {
      name: 'price',
      label: 'Precio único',
      value: '',
      type: 'number',
      required: true,
      placeholder: '00.00',
      editInTemplate: true,
    },
    {
      name: 'description',
      label: 'Descripción',
      value: '',
      type: 'textarea',
      placeholder: 'Descripción de la promoción, lo que incluye, restricciones, información extra.',
    },
  ],
  imageInputs: [
    {
      name: 'image',
      label: 'Imagen',
      value: null,
      required: false,
      formats: '.jpg, .jpeg, .png, .gif',
      placeholder: '1080x1080',
    },
  ],
  toggleInputs: [
    {
      name: 'featured',
      label: 'Producto destacado',
      placeholder: 'Cambia la apariencia del producto, resaltando entre la lista.',
      icon: '<span class="material-symbols-outlined">thumb_up</span>',
      value: false,
      error: false,
      message: 'Imagen requerida',
    },
    {
      name: 'recommended',
      label: 'Producto recomendado',
      placeholder: 'Muestra recomendaciones al inicio de la familia de los productos.',
      icon: '<span class="material-symbols-outlined">star</span>',
      value: false,
      error: false,
      message: 'Imagen requerida',
    },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.products.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  getOne({ commit }, id) {
    return service.products.getOne(id).then(({ data }) => {
      commit('getOneSuccess');
      return data;
    });
  },
  getByTemp({ commit }, templateId) {
    commit('loading');

    return service.products.getByTemp(templateId).then(({ data }) => {
      commit('requestSuccess');
      return data;
    });
  },
  create({ state, dispatch, commit }, { parentId }) {
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    let payload = {};
    let images = [];

    if (formError || imageError) {
      state.validated = true;
      throw new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });
    state.toggleInputs.forEach((i) => {
      payload[i.name] = i.value;
    });

    payload.position = state.items.length;

    // Setting images to upload
    state.imageInputs.forEach(({ name, value }) => {
      if (value && typeof value !== 'string') images.push({ name, img: value });
    });

    log('New product', payload);
    commit('loading');

    return service.products
      .create(payload, parentId)
      .then(async ({ data }) => {
        const id = data.info.id;

        if (images.length) {
          // Upload product image
          await service.products.uploadImage(id, images).then(async () => {
            await Promise.all(
              state.variants.map(async (v) => {
                return dispatch('addVariantGroup', { productId: id, group_id: v.id, isNew: true });
              }),
              state.tags.map(async (v) => {
                return dispatch('addTag', { productId: id, tag_id: v.id, isNew: true });
              }),
            );

            const newProduct = await dispatch('getOne', id);
            log('getOne', newProduct);
            commit('createSuccess', newProduct);
            state.validated = false;
          });
        } else {
          await Promise.all(
            state.variants.map(async (v) => {
              return dispatch('addVariantGroup', { productId: id, group_id: v.id, isNew: true });
            }),
            state.tags.map(async (v) => {
              return dispatch('addTag', { productId: id, tag_id: v.id, isNew: true });
            }),
          );
          const newProduct = await dispatch('getOne', id);
          log('getOne', newProduct);
          commit('createSuccess', newProduct);
          state.validated = false;
        }

        return id;
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, dispatch, commit }, id) {
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    let payload = {};
    let images = [];

    if (formError || imageError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) {
        payload[i.name] = i.value;
      } else if (!i.required) {
        payload[i.name] = null;
      }
    });

    state.toggleInputs.forEach((i) => {
      payload[i.name] = i.value;
    });

    // Setting images to upload
    state.imageInputs.forEach(({ name, value }) => {
      if (typeof value !== 'string') images.push({ name, img: value });
    });

    // If a image will update, we need getAll to update item icon url
    commit('loading');

    return service.products
      .update(id, payload)
      .then(async () => {
        let product;

        // If a image will update, we need getAll to update item image url
        if (images.length) {
          await service.products.uploadImage(id, images).then(async () => {
            product = await dispatch('getOne', id);
          });
        }

        // Update local item data
        const prodIndx = await state.items.findIndex((p) => p.id === id);
        // {
        //   if (p.id === id) {
        //     prodIndx = i;
        //     return i;
        //   }
        // });
        // await state.items.find(async (itm, i) => {
        //   if (itm.id === id) {
        const prd = await dispatch('getOne', id);
        state.items.splice(prodIndx, 1, prd);
        // return true;
        //   }
        // });

        commit('requestSuccess');
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  updateByTemp({ commit }, { templateId, products }) {
    const items = products.map(({ id, price, enabled }) => ({ product_id: id, price, enabled }));

    commit('loading');

    return service.products.updateByTemp(templateId, items).then(() => commit('requestSuccess'));
  },
  changeCategory({ commit }, { id, category_id }) {
    return service.products.update(id, { category_id }).catch((err) => handleError(commit, err));
  },
  orderPosition({ commit }, itemList) {
    // Setting index like new position to items
    const itemsNewPosition = itemList.map(({ id }, i) => ({ id, position: i }));
    return service.products.orderPosition(itemsNewPosition);
  },
  remove({ commit }, { id }) {
    // if parentId, will delete variant else product
    commit('deleteRequest', { id });

    service.products
      .remove(id)
      .then(() => commit('deleteSuccess', { id }))
      .catch((err) => {
        commit('deleteFailure', { id });
        handleError(commit, err);
      });
  },
  addVariantGroup({ commit, state, dispatch }, { productId, group_id, isNew }) {
    // commit('loading');

    return service.products.addVariantGroup(productId, { group_id }).then(async () => {
      if (!isNew) {
        const prodIndx = await state.items.findIndex((p) => p.id === productId);
        const prd = await dispatch('getOne', productId);
        state.items.splice(prodIndx, 1, prd);
      }

      commit('requestSuccess');
    });
  },
  updateVariantGroup({ commit, state, dispatch }, { productId, group }) {
    return service.products.updateVariantGroup(productId, group).then(async () => {
      const prodIndx = await state.items.findIndex((p) => p.id === productId);
      const prd = await dispatch('getOne', productId);
      state.items.splice(prodIndx, 1, prd);
      commit('requestSuccess');
    });
  },
  removeVariantGroup({ commit, state, dispatch }, { group_id, productId }) {
    return service.products.removeVariantGroup(group_id, productId).then(async () => {
      const prodIndx = await state.items.findIndex((p) => p.id === productId);
      const prd = await dispatch('getOne', productId);
      state.items.splice(prodIndx, 1, prd);
      commit('requestSuccess');
    });
  },
  addTag({ commit, state, dispatch }, { productId, tag_id, isNew }) {
    return service.products.addTag(productId, { tag_id }).then(async () => {
      if (!isNew) {
        const prodIndx = await state.items.findIndex((p) => p.id === productId);
        const prd = await dispatch('getOne', productId);
        state.items.splice(prodIndx, 1, prd);
      }

      commit('requestSuccess');
    });
  },
  removeTag({ commit, state, dispatch }, { tag_id, productId }) {
    return service.products.removeTag(tag_id, productId).then(async () => {
      const prodIndx = await state.items.findIndex((p) => p.id === productId);
      const prd = await dispatch('getOne', productId);
      state.items.splice(prodIndx, 1, prd);
      commit('requestSuccess');
    });
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  setVariantList({ commit }, variants) {
    commit('variantsForNewProduct', variants);
  },
  setTagList({ commit }, tags) {
    commit('tagsForNewProduct', tags);
  },
  setImageInputValue({ state, commit }, input) {
    let indx;

    // Setting value to input index, i might be a input name
    if (typeof input.i === 'string') {
      state.imageInputs.find((o, i) => {
        if (o.name === input.i) {
          indx = i;
          return true;
        }
      });
    } else {
      indx = input.i;
    }

    commit('setImageInputValue', { ...input, i: indx });
  },
  setToggleInputValue({ commit, state }, input) {
    commit('setToggleValue', input);
    if (!state.imageInputs[0].value) {
      // } else {
      setTimeout(() => {
        commit('setToggleError', input);
      }, 500);
    }
  },
  // Will set item data to inputs and show on editor
  setItemValues({ state, commit }, id) {
    log('Setting item data to inputs');
    const item = state.items.find((i) => i.id === id);
    commit('setItemValues', item);
    commit('setItemImages', item);
    commit('setItemToggle', item);
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
    state.imageInputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
    state.toggleInputs.forEach((inp) => {
      inp.value = false;
      inp.error = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestSuccess(state) {
    state.loading = false;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  // Get all
  getAllSuccess(state, products) {
    state.items = products;
    state.loading = false;
  },
  getOneSuccess(state) {
    state.loading = false;
  },
  createSuccess(state, product) {
    state.items.push(product);
  },
  // Remove item
  deleteRequest(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Set signal that will be delete
    items.splice(index, 1, { ...itm, deleting: true });
  },
  deleteSuccess(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove from local items
    items.splice(index, 1);
  },
  deleteFailure(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove delete signal
    delete itm.deleting;
    items.splice(index, 1, itm);
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setToggleValue(state, { i }) {
    state.toggleInputs[i].value = !state.toggleInputs[i].value;
    state.toggleInputs[i].error = false;
  },
  setToggleError(state, { i }) {
    state.toggleInputs[i].value = false;
    state.toggleInputs[i].error = true;
  },
  variantsForNewProduct(state, variants) {
    state.variants = variants;
  },
  tagsForNewProduct(state, tags) {
    state.tags = tags;
  },
  setImageInputValue(state, { value, valid, i }) {
    if (value !== undefined) state.imageInputs[i].value = value;
    if (valid !== undefined) state.imageInputs[i].valid = valid;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
  setItemToggle(state, item) {
    state.toggleInputs = state.toggleInputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
  setItemImages(state, item) {
    state.imageInputs = state.imageInputs.map((i) => {
      if (item[i.name]) {
        return { ...i, value: item[i.name] };
      }
      return { ...i, value: '' };
    });
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
