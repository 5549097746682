<template>
  <div class="sidebar-actions" v-if="!profile.loading">
    <!-- First group actions -->
    <ul class="list-group fs-5 px-lg-4">
      <li
        v-for="a in groupOne"
        :key="a.route"
        class="list-group-item"
        :class="{ 'active': a.sections.includes(active), 'list-group-item-disabled': a.disabled }"
        @click="$emit('click', a.disabled ? 'disabled' : a.route)">
        <span class="fs-3 list-group-item-icon" v-html="a.icon"></span
        ><span class="ps-4">{{ a.name }}</span>
      </li>
    </ul>
    <!-- Second group actions -->
    <ul class="list-group fs-5 px-lg-4 py-lg-3 border-top">
      <li
        v-for="a in groupTwo"
        :key="a.route"
        class="list-group-item"
        :class="{ active: a.sections.includes(active) }"
        :data-bs-toggle="a.route === '/logout' ? 'modal' : null"
        :data-bs-target="a.route === '/logout' ? '#ConfirmLogout' : null"
        @click="$emit('click', a.route)">
        <span class="fs-3 list-group-item-icon" v-html="a.icon"></span
        ><span class="ps-4">{{ a.name }}</span>
      </li>
    </ul>
  </div>
  <!-- Loading Skeleton -->
  <div class="sidebar-actions-skeleton" v-else>
    <ul class="list-group fs-5 px-lg-4">
      <li v-for="i in 3" :key="i" class="list-group-item loading"></li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SidebarActions',
  data() {
    return {
      groupOne: [
        {
          sections: ['categories', 'products', 'variants', 'tags'],
          route: '/menu/categories',
          name: 'Menú',
          icon: '<span class="material-symbols-outlined">menu_book</span>',
        },
        {
          sections: ['promotions'],
          route: '/promotions',
          name: 'Promociones',
          icon: '<i class="icon-star"></i>',
        },
        {
          sections: ['advertising'],
          route: '/advertising',
          name: 'Publicidad',
          icon: '<i class="icon-percent"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></i>',
        },
        {
          sections: ['sucursales', 'templates'],
          route: '/multisucursal/sucursales',
          name: 'Multisucursal',
          icon: '<i class="icon-store"></i>',
        },
      ],
      groupTwo: [
        {
          sections: ['tutorials'],
          route: '/tutorials',
          name: 'Tutoriales',
          icon: '<span class="material-symbols-outlined">play_lesson</span>',
        },
        {
          sections: ['billing'],
          route: '/billing',
          name: 'Facturación',
          icon: '<i class="icon-billing"></i>',
        },
        {
          sections: ['general', 'account', 'customize', 'marketing'],
          route: '/settings/general',
          name: 'Configuración',
          icon: '<i class="icon-settings"></i>',
        },
        {
          sections: [],
          route: '/logout',
          name: 'Cerrar Sesión',
          icon: '<i class="icon-power"></i>',
        },
      ],
    };
  },
  props: {
    active: String,
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    planCode() {
      return this.profile.info.plan.code;
    },
    scopes() {
      if (this.profile.info.plan) {
        return this.profile.info.plan.scopes;
      }

      return null;
    },
  },
  watch: {
    scopes() {
      this.groupOne.forEach((e, i) => {
        const ss = this.scopes.find((s) => `/${s.name.toLowerCase()}` === e.route);

        if (!ss) {
          this.groupOne[i].disabled = true;
        }

        if (this.groupOne[i].route === '/menu/categories') {
          delete this.groupOne[i].disabled;
        }
        if (
          this.groupOne[i].route === '/multisucursal/sucursales' &&
          this.planCode === 'FRANCHISES'
        ) {
          delete this.groupOne[i].disabled;
        }
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebar-actions-skeleton {
  .list-group {
    &-item {
      min-height: 46px;
      background: $gray-bg;
    }
  }
}
.list-group {
  &-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 10px 35px;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: $primary-x-light;
    }
    &-icon {
      color: $primary;
    }
    &-disabled {
      &.active {
        background: $dark-gray-bg;
      }
      &:hover {
        background: $dark-gray-bg;
      }
      span {
        color: #6c6c6c;
      }
    }
    i {
      font-size: 2.2rem;
    }
    > span {
      font-weight: 500;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
    }
    .icon-box {
      font-size: 1.8rem;
    }
  }
}
</style>
