<template>
  <div class="tab-wrapper position-relative w-100 bg-light">
    <div class="board-content h-100 overflow-auto" v-if="!profile.loading">
      <div class="col-10 offset-lg-1 mt-5 py-5">
        <div class="settings-wrapper row">
          <div class="row">
            <div class="settings-password col-lg-5">
              <h2 class="mb-5">Cuenta</h2>
              <label class="form-label position-relative d-block w-100">
                <span class="d-block ps-3 mb-1">Correo</span>
                <input type="email" class="form-control" v-model="info.email" disabled />
              </label>
              <form @submit.prevent="updatePassword">
                <Input
                  class="mb-3"
                  v-for="(input, i) in pass.inputs"
                  :key="i"
                  v-bind="input"
                  :validated="validated"
                  @valid="checkInput"
                  @input="handleInput" />
                <Button class="w-100" :disabled="changePassReady" :type="'submit'">
                  Actualizar Contraseña
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else :class="'text-primary'" :size="'lg'" :center="true" />
    <ShareLinkModal v-bind="sucursal" @click="saveLink" />
    <Notifications :single="'true'" :hide-after="5000"></Notifications>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Notifications from '@voerro/vue-notifications';
import ShareLinkModal from '@/components/ShareLinkModal.vue';
import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';
import Input from '@/components/common/Input.vue';

import { menu } from '../../../config';

export default {
  name: 'TabAccount',
  components: {
    ShareLinkModal,
    Notifications,
    Spinner,
    Button,
    Input,
  },
  async mounted() {
    if (!this.sucursal.length) {
      await this.getAllSucursales();
    }

    this.sucursal = await this.sucursales.find((s) => s.enabled);

    this.previewMenu = `${menu.url}/${this.sucursal.route_key}`;

    this.company = this.info.company;
    this.gtm = this.info.gtm_id;
    this.colorPickers[0].color = this.info.theme.background;
    this.colorPickers[1].color = this.info.theme.font;
    this.colorPickers[2].color = this.info.theme.button_background;
    this.colorPickers[3].color = this.info.theme.button_font;
  },
  data() {
    return {
      previewMenu: null,
      sucursal: {},
      company: null,
      gtm: null,
      validated: false,
      colorPickers: [
        {
          title: 'Color de fondo',
          color: null,
        },
        {
          title: 'Color del texto',
          color: null,
        },
        {
          title: 'Color de botones',
          color: null,
        },
        {
          title: 'Color del texto de botones',
          color: null,
        },
      ],
      defaultTheme: {
        background_color: '#FFFFFF',
        font_color: '#242424',
        button_color: '#5227D0',
        button_font_color: '#FFFFFF',
      },
      pass: {
        inputs: [
          {
            name: 'currentPass',
            label: 'Contraseña actual',
            value: null,
            required: true,
            type: 'password',
            placeholder: 'Contraseña actual',
          },
          {
            name: 'newPass',
            label: 'Contraseña',
            value: null,
            required: true,
            type: 'password',
            placeholder: 'Contraseña',
          },
          {
            name: 'passwordMatch',
            label: 'Confirmar contraseña',
            value: null,
            required: true,
            type: 'password',
            placeholder: 'Confirmar contraseña',
            validation: !this.isPasswordMatch,
            message: 'La contraseña no coincide',
          },
        ],
      },
      logoInput: {
        name: 'logo',
        value: null,
        formats: '.jpg, .jpeg, .png, .gif',
        showInput: false,
      },
      uploadingLogo: false,
      gtmRegex: /^([GW](?:T|M|A)*)-([a-zA-Z0-9]{7,9})$/,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      sucursales: (state) => state.sucursales.items,
    }),
    planCode() {
      return this.profile.info.plan.code;
    },
    info() {
      return this.profile.info;
    },
    changePassReady() {
      return !!this.pass.inputs.find((i) => !i.value);
    },
    password() {
      return this.pass.inputs[1].value;
    },
    confirmPassword() {
      return this.pass.inputs[2].value;
    },
    isPasswordMatch() {
      return this.password && this.password === this.confirmPassword;
    },
  },
  watch: {
    isPasswordMatch(v) {
      this.pass.inputs[2].validation = !v;
    },
  },
  methods: {
    ...mapActions('sucursales', {
      getAllSucursales: 'getAll',
      updateSucursal: 'simpleUpdate',
      updateRoute: 'updateRoute',
    }),
    ...mapActions('account', ['changePassword']),
    ...mapActions('profile', [
      'getOne',
      'updateCompanyName',
      'updateGTM',
      'updateTheme',
      'uploadLogo',
    ]),
    changeImage(file) {
      const fr = new FileReader();

      if (file && this.logoInput.valid) {
        this.uploadingLogo = true;
        fr.readAsDataURL(file);

        fr.addEventListener('load', (evt) => {
          this.uploadLogo({ logo: file, base64: evt.target.result })
            .then(() => {
              this.uploadingLogo = false;
            })
            .catch(() => {
              this.uploadingLogo = false;
            });
        });
      }
    },
    saveLink(id, route) {
      const data = { route_key: route };
      this.updateRoute({ data, id });
    },
    checkImage({ valid }) {
      this.logoInput.valid = valid;
    },
    saveColor() {
      const colors = {
        background_color: this.colorPickers[0].color,
        font_color: this.colorPickers[1].color,
        button_color: this.colorPickers[2].color,
        button_font_color: this.colorPickers[3].color,
      };

      this.updateTheme(colors);
    },
    resetColors() {
      this.colorPickers[0].color = this.defaultTheme.background_color;
      this.colorPickers[1].color = this.defaultTheme.font_color;
      this.colorPickers[2].color = this.defaultTheme.button_color;
      this.colorPickers[3].color = this.defaultTheme.button_font_color;

      this.updateTheme(this.defaultTheme);
    },
    updatePassword() {
      const formError = this.pass.inputs.find((i) => !i.valid);
      this.validated = true;

      if (!formError) {
        const payload = {
          password: this.pass.inputs[0].value,
          new_password: this.pass.inputs[1].value,
        };

        this.changePassword(payload)
          .then(() => {
            const notify = {
              text: `<p><b>Contraseña actualizada</b></p>`,
              theme: 'success',
            };

            window.notify(notify);

            this.resetInputs();
          })
          .catch((err) => {
            if (err.response.data.error.code === 'CURRENT_NOT_MATCH') {
              const notifyErr = {
                text: `
              <p><b>La contraseña no fue actualizada</b></p>
              <p>La contraseña actual no es correcta</p>
              `,
                theme: 'error',
              };

              window.notify(notifyErr);
            }
          });
      }
    },
    handleInput({ value, i }) {
      this.pass.inputs[i].value = value;
    },
    checkInput({ valid, i }) {
      this.pass.inputs[i].valid = valid;
    },
    resetInputs() {
      this.pass.inputs.forEach((inp, i) => {
        this.pass.inputs[i].value = '';
      });
      this.validated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
.settings {
  &-pick-color {
    input[type='color'] {
      -webkit-appearance: none;
      border: none;
      width: 54px;
      height: 54px;
      background: transparent;
    }
    input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 50%;
      border: 1px solid $dark-gray-bg;
    }
    input[type='text'] {
      width: 110px;
    }
  }
  &-picture {
    max-width: 250px;
    .file-upload {
      border-radius: 50%;
    }
  }
}
</style>
