import service from '../../services/tags.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre de la etiqueta',
      value: '',
      required: true,
      placeholder: 'Platillo del dia',
    },
  ],
  iconInputs: [
    {
      name: 'icon',
      label: 'Icono',
      value: '',
      required: false,
    },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  create({ state, dispatch, commit }) {
    const formError = state.inputs.find((i) => !i.valid);
    let payload = {};

    if (formError) {
      state.validated = true;
      throw new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });
    state.iconInputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    return service
      .create(payload)
      .then(({ data }) => {
        dispatch('getAll');
        state.validated = false;

        return data.info.id;
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, commit }, { id }) {
    const formError = state.inputs.find((i) => !i.valid);
    let payload = {};

    if (formError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    return service
      .update(id, payload)
      .then(() => {
        state.items.find((itm, i) => {
          // Update local item data
          if (itm.id === id) {
            state.items.splice(i, 1, { ...itm, ...payload });
            return true;
          }
        });
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  remove({ commit }, { id }) {
    commit('deleteRequest', { id });

    // if parentId will delete category else family
    service
      .remove(id)
      .then(() => commit('deleteSuccess', { id }))
      .catch((err) => {
        commit('deleteFailure', { id });
        handleError(commit, err);
      });
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  // Will set item data to inputs and show on editor
  setItemValues({ state, commit }, { id }) {
    const item = state.items.find((i) => i.id === id);
    commit('setItemValues', item);
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
    state.iconInputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  requestSuccess(state) {
    state.loading = false;
  },
  // Get all
  getAllSuccess(state, tags) {
    state.items = tags;
    state.loading = false;
  },
  // Remove item
  deleteRequest(state, { id }) {
    let index;

    const itm = state.items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Set signal that will be delete
    state.items.splice(index, 1, { ...itm, deleting: true });
  },
  deleteSuccess(state, { id }) {
    let index;

    state.items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove from local items
    state.items.splice(index, 1);
  },
  deleteFailure(state, { id }) {
    let index;

    const itm = state.items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove delete signal
    delete itm.deleting;
    state.items.splice(index, 1, itm);
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
    state.iconInputs = state.iconInputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
  setIconInputValue(state, { value, valid, i }) {
    if (value !== undefined) state.IconInputs[i].value = value;
    if (valid !== undefined) state.IconInputs[i].valid = valid;
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
