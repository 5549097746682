<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100">
      <Board :title="title">
        <template v-slot:icon>
          <i class="icon-home" style="font-size: 5rem">
          </i>
        </template>
        <div class="yt-iframe p-3 d-flex">
          <div class="tutorial-card shadow rounded mx-2" v-for="(t, i) in tutorials" :key="i" data-bs-toggle="modal" data-bs-target="#tutorialModal" @click="setTutorial(i)">
            <div class="tutorial-thumbnail">
              <img :src="require(`@/assets/images/tutorials/${t.thumbnail}`)" :alt="t.title">
            </div>
            <p class="p-3 fw-bolder">{{ t.title }}</p>
          </div>
        </div>
      </Board>
    </div>
    <div class="modal fade" id="tutorialModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{tutorials[modalTutorial].title}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-html="tutorials[modalTutorial].iframe">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Board from '@/components/boards/Board.vue';

export default {
  name: 'BoardTutorials',
  components: {
    Board,
  },
  mounted() {},
  data() {
    return {
      title: 'Tutoriales',
      modalTutorial: 0,
      tutorials: [
        {title: 'Requisitos para crear mi menú', thumbnail: 'tutorial-thumbnail-01.jpg', iframe: '<iframe width="720" height="405" src="https://www.youtube.com/embed/0pyXKumeS5g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
        {title: 'Dar de alta productos', thumbnail: 'tutorial-thumbnail-02.jpg', iframe: '<iframe width="720" height="405" src="https://www.youtube.com/embed/gi_4IYfjVyw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
        {title: 'Configuración del menú', thumbnail: 'tutorial-thumbnail-03.jpg', iframe: '<iframe width="720" height="405" src="https://www.youtube.com/embed/lyzzzqdZeM0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'},
      ]
    };
  },
  computed: {
  },
  methods: {
    setTutorial(i) {
      this.modalTutorial = i
    }
  },
};
</script>

<style lang="scss" scoped>
.tutorial-card {
  width: 320px;
  cursor: pointer;
  .tutorial-thumbnail {
    height: 180px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
