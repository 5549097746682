/* eslint-disable */
const priceFormat = (price, currency) => {
  const num = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [int, dec] = num.format(price).split('.');

  return currency === 'USD' && parseInt(dec[0]) > 0 ? `${int}.${dec[0]}` : int;
};

export default { priceFormat };
