<template>
  <div>
    <label :for="name" class="form-label position-relative"
      ><span class="d-block ps-3 mb-1">{{ label }}</span>
      <!-- Select Input -->
      <select
        v-if="type === 'list'"
        :class="classList"
        :id="name"
        :name="name"
        v-model="content"
        autocomplete="off"
        @input="handleInput">
        <option v-if="placeholder" value="" disabled selected>{{ placeholder }}</option>
        <option v-for="({ id, name }, i) in optionList" :key="i" :value="id">
          {{ name }}
        </option>
      </select>
      <!-- Textarea field -->
      <textarea
        v-else-if="type === 'textarea'"
        :class="classList"
        :id="name"
        :name="name"
        v-model="content"
        :placeholder="placeholder"
        @input="handleInput"
        @change="$emit('change')"
        rows="5"></textarea>
      <!-- Social media link -->
      <div class="row w-100" v-else-if="type === 'network'">
        <span
          class="prefix col-lg-7 px-5 py-3"
          :style="icon ? 'padding-left: 45px !important' : ''"
          >{{ prefix }}</span
        >
        <input
          style="width: initial"
          class="user col-lg-4"
          :class="classList"
          :id="name"
          :name="name"
          v-model="content"
          :placeholder="placeholder"
          @input="handleInput"
          @keyup="formatValue"
          @change="$emit('change')" />
      </div>
      <!-- Normal Input -->
      <input
        v-else
        :style="icon ? 'padding-left: 40px' : ''"
        :type="type"
        :class="classList"
        :id="name"
        :name="name"
        v-model="content"
        autocomplete="off"
        :maxlength="maxlength"
        :placeholder="placeholder"
        @keyup="formatValue"
        @input="handleInput"
        @change="$emit('change')" />
      <span
        class="position-absolute icon-input"
        :class="{ 'text-light': type === 'network', 'text-primary': type !== 'network' }"
        v-if="icon"
        v-html="icon"></span>
      <span class="position-absolute dollar" v-if="name === 'price'">$</span>
    </label>
    <div v-if="validated && error && showErr" class="fs-6 text-danger">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: 'Input',
  mounted() {
    if (this.required) {
      this.$emit('valid', { valid: false, i: this.$vnode.key });
    }
  },
  data() {
    return {
      maxlength: this.type === 'tel' ? 12 : null,
      errorMessage: 'Campo obligatorio',
      content: this.value,
      error: false,
    };
  },
  props: {
    icon: String,
    name: String,
    size: String,
    type: { type: String, default: 'text' },
    label: String,
    showErr: { type: Boolean, default: true },
    options: Array,
    required: Boolean,
    validated: Boolean,
    placeholder: String,
    validation: Boolean,
    prefix: String,
    message: String,
    value: [String, Number],
  },
  computed: {
    classList() {
      return {
        'form-control-sm': this.size === 'sm',
        'form-control': this.type !== 'list',
        'form-select': this.type === 'list',
        'form-error': this.validated && this.error,
        'ps-5': this.name === 'price',
      };
    },
    optionList() {
      if (this.options && typeof this.options[0] === 'string') {
        return this.options.map((v) => ({ id: v, name: v }));
      }

      return this.options;
    },
  },
  watch: {
    content() {
      this.validate();
    },
    value(v) {
      this.content = v;
    },
    validated() {
      this.validate();
    },
  },
  methods: {
    handleInput(e) {
      if (this.$vnode.key >= 0) {
        this.$emit('input', { value: e.target.value, i: this.$vnode.key });
      } else {
        this.$emit('input', e.target.value);
      }
    },
    formatValue() {
      if (this.type === 'tel') this.content = this.parsePhone(this.content);
    },
    isEmail(value) {
      return this.$regex.email.test(value);
    },
    parsePhone(value) {
      const phone = value.replace(/\D/g, '');

      if (phone.length > 6) {
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 10)}`;
      }
      if (phone.length > 3) {
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)}`;
      }

      return phone;
    },
    validate() {
      const isEmpty = this.required && !this.content;
      const wrongEmail = this.type === 'email' && !this.isEmail(this.content) && this.content;
      const passTooShort = this.type === 'password' && this.content.length < 8 && this.content;
      const incompletePhone =
        this.type === 'tel' && this.content && this.content.length < this.maxlength;

      this.error = isEmpty || wrongEmail || passTooShort || incompletePhone || this.validation;

      if (wrongEmail) this.errorMessage = 'El formato de correo no es valido';
      if (passTooShort) this.errorMessage = 'Tu contraseña debe ser mínimo 8 caracteres';
      if (incompletePhone) this.errorMessage = 'El teléfono deben ser 10 dígitos';
      if (this.validation) this.errorMessage = this.message; // Custom Validation

      this.$emit('valid', { valid: !this.error, i: this.$vnode.key, name: this.name });
    },
  },
};
</script>

<style lang="scss" scoped>
label,
input,
textarea,
select {
  width: 100%;
}
.form-error {
  border: 1px solid $danger;
}
.dollar {
  color: #6c757d;
  bottom: 11px;
  left: 14px;
}
.form-control-sm {
  & + .dollar {
    bottom: 7px;
    left: 12px;
  }
}
.user {
  border-radius: 0 4px 4px 0;
}
.icon-input {
  top: 34px;
  left: 12px;
}
.prefix {
  background: $primary-light;
  color: $light;
  border-radius: 4px 0 0 4px;
}
</style>
