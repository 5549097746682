import Vue from 'vue';
import Vuex from 'vuex';

import tags from './modules/tags.module';
import plans from './modules/plans.module';
import account from './modules/account.module';
import profile from './modules/profiles.module';
import products from './modules/products.module';
import variants from './modules/variants.module';
import templates from './modules/templates.module';
import promotions from './modules/promotions.module';
import sucursales from './modules/sucursales.module';
import categories from './modules/categories.module';
import advertising from './modules/advertising.module';
import subscriptions from './modules/subscriptions.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    tags,
    plans,
    account,
    profile,
    products,
    variants,
    templates,
    promotions,
    sucursales,
    categories,
    advertising,
    subscriptions,
  },
});
