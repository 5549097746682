import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const getAll = () => {
  const config = {
    method: 'GET',
    url: '/v1/templates',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const getOne = (id) => {
  const config = {
    method: 'GET',
    url: `/v1/templates/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const create = (template) => {
  const config = {
    method: 'POST',
    url: '/v1/templates',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: template,
  };

  return menu.http(config).catch(handleError);
};

const update = (id, data) => {
  const config = {
    method: 'PUT',
    url: `/v1/templates/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

const clone = (id) => {
  const config = {
    method: 'POST',
    url: `/v1/templates/clone/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const remove = (id) => {
  const config = {
    method: 'DELETE',
    url: `/v1/templates/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

export default {
  getAll,
  getOne,
  create,
  update,
  clone,
  remove,
};
