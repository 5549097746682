<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100" style="background-color: #f9f9f9">
      <Board
        :title="title"
        :modal="modal"
        :caption="caption"
        :search="['producto', 'grupo', 'etiqueta'].includes(target)"
        :target="target !== 'producto' ? $wordUp(target) : ''"
        :filter="target === 'producto'"
        :filterName="'Familia'"
        :filterOptions="families"
        :showCollapseButton="!!items.length && !tabs[3].active"
        :accordionCollapsed="accordionCollapsed"
        @expandCollapse="expandCollapse"
        @filter="(v) => (filterFamily = parseInt(v))"
        @search="(v) => (search = v)"
        @action="emitAction">
        <template v-slot:icon>
          <span class="material-symbols-outlined" style="font-size: 5rem">menu_book</span>
        </template>
        <div class="d-flex justify-content-between w-100">
          <Tabs class="my-3" :tabs="tabs" @click="switchTab" />
          <!-- <Button v-if="items.length" class="my-3" :styled="'normal'" @click="expandCollapse">{{
            `${accordionCollapsed ? 'Expandir' : 'Contraer'} todo`
          }}</Button> -->
        </div>

        <TabCategories
          v-if="tab === 'categories'"
          ref="categories"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabProducts
          v-else-if="tab === 'products'"
          ref="products"
          :search="search"
          :filterFamily="filterFamily"
          :existTemplates="existTemplates"
          @goTo="switchTab('categories')"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabVariants
          v-else-if="tab === 'variants'"
          ref="variants"
          :search="search"
          :existTemplates="existTemplates"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabTags
          v-else-if="tab === 'tags'"
          ref="tags"
          :search="search"
          :existTemplates="existTemplates"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
      </Board>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TabCategories from '@/components/boards/boardTabs/TabCategories.vue';
import TabProducts from '@/components/boards/boardTabs/TabProducts.vue';
import TabVariants from '@/components/boards/boardTabs/TabVariants.vue';
import TabTags from '@/components/boards/boardTabs/TabTags.vue';
// import Button from '@/components/common/Button.vue';
import Board from '@/components/boards/Board.vue';
import Tabs from '@/components/common/Tabs.vue';

export default {
  name: 'BoardMenu',
  components: {
    TabCategories,
    TabProducts,
    TabVariants,
    TabTags,
    // Button,
    Board,
    Tabs,
  },
  mounted() {
    this.caption = this.tabs[0].caption;

    this.switchTab(this.tab);
  },
  data() {
    return {
      title: 'Menu',
      target: 'familia',
      caption: '',
      modal: '',
      search: '',
      filterFamily: null,
      itemToUpdate: null,
      itemToDelete: null,
      accordionCollapsed: false,
      tabs: [
        {
          title: 'Familias y Categorías',
          icon: 'extension',
          tab: 'categories',
          target: 'familia',
          caption: `- Ordena la posición de las familias y categorías arrastrando los elementos.
                    - Haz click en la familia para desplegar las categorías que contiene.`,
          active: false,
        },
        {
          title: 'Productos',
          icon: 'fastfood',
          tab: 'products',
          target: 'producto',
          caption: `- Ordena la posición de los productos arrastrando los elementos.
                    - Filtra productos por familia o categoría haciendo click sobre el icono de la esquina superior izquierda de la tabla `,
          active: false,
        },
        {
          title: 'Variantes',
          icon: 'category',
          tab: 'variants',
          target: 'grupo',
          active: false,
          modal: '#VariantsEditorModal',
        },
        {
          title: 'Etiquetas',
          icon: 'sell',
          tab: 'tags',
          target: 'etiqueta',
          active: false,
          modal: '#EditorModal',
        },
      ],
    };
  },
  props: {
    tab: String,
    existTemplates: Boolean,
  },
  computed: {
    ...mapState({
      categories: (state) => state.categories,
      products: (state) => state.products,
      variants: (state) => state.variants,
      tags: (state) => state.tags,
    }),
    families() {
      return this.categories.items;
    },
    items() {
      return this[this.tab].items;
    },
  },
  methods: {
    switchTab(v) {
      this.$emit('click', `/menu/${v}`);

      this.tabs = this.tabs.map((t) => {
        if (t.tab === v) {
          this.caption = t.caption;
          this.modal = t.modal;
          this.target = t.target;
          return { ...t, active: true };
        }

        return { ...t, active: false };
      });

      this.accordionCollapsed = false;
    },
    emitAction(action, target) {
      this.$emit('action', action, target);

      if (target === 'familia') {
        this.$refs.categories.$refs.imgInputs.click();
      }
    },
    expandCollapse() {
      const accordions = this.$refs[this.tab].$refs.itemsChained.$refs.accordion;

      accordions.forEach((a) => {
        const itemCollapsed = a.$el.className.includes('collapsed');

        if (
          (itemCollapsed && this.accordionCollapsed) ||
          (!itemCollapsed && !this.accordionCollapsed)
        ) {
          a.$el.click();
        }
      });

      this.accordionCollapsed = !this.accordionCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped></style>
