<template>
  <div class="template-edit-card">
    <div
      class="modal fade"
      id="TemplateEditorModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
      aria-labelledby="EditorModalLabel">
      <div
        class="modal-dialog modal-dialog-centered justify-content-center modal-dialog-scrollable">
        <div class="modal-content h-100 position-relative">
          <div class="modal-header text-center">
            <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
              {{ mode }} plantilla de sucursales
            </h5>
            <button
              type="button"
              ref="closeEditor"
              class="btn-close"
              aria-label="Close"
              data-bs-dismiss="modal"
              @click="reset"></button>
          </div>
          <div class="modal-body overflow-hidden pb-0">
            <div class="template h-100">
              <div class="template-name row col-lg-4 mb-4">
                <Input
                  v-for="(input, i) in inputs"
                  :key="i"
                  v-bind="input"
                  :validated="validated"
                  @valid="checkInput"
                  @input="handleInput" />
              </div>
              <div class="template-controls row gx-5 align-items-center">
                <Input class="col-lg-4" :placeholder="'Buscar'" v-model="search" />
                <!-- Resources Sections -->
                <div class="template-sections d-flex col-lg-8">
                  <Button
                    class="col-3"
                    :styled="'normal'"
                    :class="{ active: section === 'products' }"
                    @click="switchTo('products')">
                    Productos
                  </Button>
                  <Button
                    class="col-3"
                    :styled="'normal'"
                    :class="{ active: section === 'promotions' }"
                    @click="switchTo('promotions')">
                    Promociones
                  </Button>
                  <Button
                    class="col-3"
                    :styled="'normal'"
                    :class="{ active: section === 'advertising' }"
                    @click="switchTo('advertising')">
                    Publicidad
                  </Button>
                  <Button
                    class="col-3"
                    :styled="'normal'"
                    :class="{ active: section === 'variants' }"
                    @click="switchTo('variants')">
                    Variantes
                  </Button>
                </div>
              </div>
              <div v-if="!loading" class="template-items" style="height: calc(100% - 138px)">
                <div class="table h-100">
                  <!-- Table header -->
                  <div class="table-header flex-center justify-content-between text-center">
                    <div
                      class="table-header-col px-3 py-4"
                      :style="`width: ${col.width}; cursor: pointer;`"
                      v-for="(col, i) in cols[section]"
                      :key="i"
                      @click="sort(col.prop)">
                      {{ col.header }}<i class="icon-arrows ms-2"></i>
                    </div>
                  </div>
                  <!-- Table items -->
                  <div class="table-body text-center" style="height: calc(100% - 50px)">
                    <div
                      class="table-body-row d-flex justify-content-between align-items-center"
                      v-for="(item, i) in items"
                      :key="i">
                      <div
                        class="table-body-col"
                        :style="`width: ${width}`"
                        v-for="({ prop, width, required }, c) in cols[section]"
                        :key="c">
                        <!-- Template Variants Trigger -->
                        <Button
                          v-if="prop === 'variants' && item[prop].length"
                          class="m-auto"
                          data-bs-toggle="modal"
                          data-bs-target="#TemplateVariantsModal"
                          v-bind="{ styled: 'light', size: 'sm' }"
                          @click="editVariants(item)">
                          Editar variantes
                        </Button>
                        <Toggle
                          v-else-if="prop === 'enabled'"
                          :name="`${item.name + i}`"
                          :checked="item[prop]"
                          @click="
                            item[prop] = !item[prop];
                            item.changed = true;
                          " />
                        <div v-else-if="prop === 'price'" class="position-relative">
                          <Input
                            v-bind="{ name: 'price', type: 'number', size: 'sm' }"
                            v-model="item[prop]"
                            :required="required"
                            :validated="true"
                            :showErr="false"
                            @input="item.changed = true" />
                        </div>
                        <div v-else-if="prop === 'description'">
                          <Input
                            :type="'textarea'"
                            v-model="item[prop]"
                            @input="item.changed = true" />
                        </div>
                        <span v-else>
                          {{
                            prop === 'variants' && !item[prop].length
                              ? 'N/A'
                              : (prop === 'family' ||
                                  prop === 'category' ||
                                  prop === 'expiration') &&
                                !item[prop]
                              ? 'N/A'
                              : prop === 'expiration'
                              ? $moment(item[prop]).format('DD-MM-YYYY')
                              : prop === 'updated'
                              ? $moment(item[prop]).fromNow()
                              : item[prop]
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="position-relative col-12 h-75">
                <Spinner class="text-primary" :size="'lg'" :center="true" />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between pt-5">
            <div>
              <span v-if="error" class="text-danger fs-5">{{ error }}</span>
            </div>
            <div class="d-flex">
              <Button :styled="'danger'" data-bs-dismiss="modal" @click="reset">Cancelar</Button>
              <Button class="mx-2" @click="saveTemplate">{{ `${mode} ${target}` }}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import Button from '@/components/common/Button.vue';
import Toggle from '@/components/common/Toggle.vue';
import Input from '@/components/common/Input.vue';

export default {
  name: 'TemplateEditModal',
  components: {
    Spinner,
    Button,
    Toggle,
    Input,
  },
  mounted() {
    if (!this.items.length && this[this.section].items) {
      this[`getAll${this.$wordUp(this.section)}`]().then(() => {
        this[`${this.section}List`] = this[this.section].items.map((itm) => {
          return { ...itm, enabled: true };
        });
      });
    }
  },
  data() {
    return {
      search: '',
      sortBy: '',
      error: null,
      sortReverse: false,
      section: 'products',
      showVariantEditor: false,
      productToUpdate: null,
      productsList: [],
      promotionsList: [],
      advertisingList: [],
      variantsList: [],
      cols: {
        products: [
          {
            header: 'Activo',
            width: '60px',
            prop: 'enabled',
          },
          {
            header: 'Nombre',
            width: '300px',
            prop: 'name',
          },
          {
            header: 'Precio',
            width: '120px',
            prop: 'price',
            required: true,
          },
          // {
          //   header: 'Variantes',
          //   width: '160px',
          //   prop: 'variants',
          // },
          {
            header: 'Ultimo cambio',
            width: '200px',
            prop: 'updated',
          },
        ],
        promotions: [
          {
            header: 'Activo',
            width: '60px',
            prop: 'enabled',
          },
          {
            header: 'Nombre',
            width: '200px',
            prop: 'name',
          },
          {
            header: 'Descripción',
            width: '300px',
            prop: 'description',
          },
          {
            header: 'Vigencia',
            width: '120px',
            prop: 'expiration',
          },
          {
            header: 'Ultimo cambio',
            width: '200px',
            prop: 'updated',
          },
        ],
        advertising: [
          {
            header: 'Activo',
            width: '60px',
            prop: 'enabled',
          },
          {
            header: 'Nombre',
            width: '200px',
            prop: 'name',
          },
          {
            header: 'Descripción',
            width: '300px',
            prop: 'description',
          },
          {
            header: 'Vigencia',
            width: '120px',
            prop: 'expiration',
          },
          {
            header: 'Ultimo cambio',
            width: '200px',
            prop: 'updated',
          },
        ],
        variants: [
          {
            header: 'Activo',
            width: '60px',
            prop: 'enabled',
          },
          {
            header: 'Nombre',
            width: '200px',
            prop: 'name',
          },
          {
            header: 'Precio',
            width: '120px',
            prop: 'price',
            required: false,
          },
          {
            header: 'Grupo',
            width: '200px',
            prop: 'group',
          },
          {
            header: 'Ultimo cambio',
            width: '200px',
            prop: 'updated',
          },
        ],
      },
    };
  },
  props: {
    mode: String,
    target: String,
    validated: Boolean,
    itemToUpdate: String,
    productWidthVar: Object,
  },
  watch: {
    mode(v) {
      if (v === 'Agregar' && this[this.section].items) {
        this.resetLists();
        this[`getAll${this.$wordUp(this.section)}`]().then(() => {
          this[`${this.section}List`] = this[this.section].items.map((itm) => {
            return { ...itm, enabled: true };
          });
        });
      } else if (this.mode === 'Actualizar' && this.itemToUpdate) {
        this.resetLists();
        this[`get${this.$wordUp(this.section)}ByTemp`](this.itemToUpdate).then((items) => {
          this[`${this.section}List`] = items;
        });
      }
    },
    itemToUpdate(templateId) {
      if (this.mode === 'Actualizar' && templateId) {
        this.resetLists();
        this[`get${this.$wordUp(this.section)}ByTemp`](templateId).then((items) => {
          this[`${this.section}List`] = items;
        });
      }
    },
    productWidthVar(v) {
      const indx = this.productsList.findIndex((p) => p.id === v.id);

      this.productsList.splice(indx, 1, v);
    },
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates,
      products: (state) => state.products,
      promotions: (state) => state.promotions,
      advertising: (state) => state.advertising,
      variants: (state) => state.variants,
    }),
    loading() {
      return (
        this.templates.loading ||
        this.products.loading ||
        this.promotions.loading ||
        this.advertising.loading ||
        this.variants.loading
      );
    },
    inputs: {
      get() {
        return this.templates.inputs;
      },
      set(input) {
        return this.$store.dispatch(`templates/setInputValue`, input);
      },
    },
    items() {
      const { section, search, sortBy, sortReverse } = this;
      const list = this[`${section}List`];

      if (search) {
        return this[`${section}List`].filter(({ name, group }) => {
          if (this.section === 'variants') {
            return (
              name.toLowerCase().includes(search.toLowerCase()) ||
              group.toLowerCase().includes(search.toLowerCase())
            );
          }

          return name.toLowerCase().includes(search.toLowerCase());
        });
      }

      if (sortBy) {
        const value = list[0][sortBy];
        if (['updated', 'expiration'].includes(sortBy)) {
          return list.sort((a, b) => {
            if (sortReverse) return new Date(b[sortBy]) - new Date(a[sortBy]);

            return new Date(a[sortBy]) - new Date(b[sortBy]);
          });
        }
        if (typeof value === 'boolean') {
          return list.sort((a, b) => {
            if (sortReverse) return Number(b[sortBy]) - Number(a[sortBy]);

            return Number(a[sortBy]) - Number(b[sortBy]);
          });
        }
        if (typeof value === 'string') {
          return list.sort((a, b) => {
            if (sortReverse) return b[sortBy].localeCompare(a[sortBy]);

            return a[sortBy].localeCompare(b[sortBy]);
          });
        }
        if (typeof value === 'number') {
          return list.sort((a, b) => {
            if (sortReverse) return b[sortBy] - a[sortBy];

            return a[sortBy] - b[sortBy];
          });
        }
        if (Array.isArray(value)) {
          return list.sort((a, b) => {
            if (sortReverse) return b[sortBy].length - a[sortBy].length;

            return a[sortBy].length - b[sortBy].length;
          });
        }
      }

      return list;
    },
  },
  methods: {
    ...mapActions('templates', ['create', 'update', 'resetInputs', 'setItemValues']),
    ...mapActions('products', {
      getAllProducts: 'getAll',
      getProductsByTemp: 'getByTemp',
      updateProductsByTemp: 'updateByTemp',
      // updateVariantsByTemp: 'updateVarByTemp',
    }),
    ...mapActions('promotions', {
      getAllPromotions: 'getAll',
      getPromotionsByTemp: 'getByTemp',
      updatePromotionsByTemp: 'updateByTemp',
    }),
    ...mapActions('advertising', {
      getAllAdvertising: 'getAll',
      getAdvertisingByTemp: 'getByTemp',
      updateAdvertisingByTemp: 'updateByTemp',
    }),
    ...mapActions('variants', {
      getAllVariants: 'getAll',
      getVariantsByTemp: 'getByTemp',
      updateVariantsByTemp: 'updateByTemp',
    }),
    sort(prop) {
      this.sortBy = prop;
      this.sortReverse = !this.sortReverse;
    },
    editVariants(product) {
      if (this.mode === 'Agregar') {
        const variants = product.variants.map((itm) => {
          if (itm.enabled === undefined) {
            return { ...itm, enabled: true };
          }
          return itm;
        });
        this.$emit('editVariants', { ...product, variants });
      } else {
        this.$emit('editVariants', product);
      }
    },
    switchTo(section) {
      this.resetSorting();
      this.section = section;
      if (!this.items.length) {
        if (this.mode === 'Agregar') {
          this[`getAll${this.$wordUp(this.section)}`]().then(() => {
            if (this.section === 'variants') {
              let variants = [];

              this[this.section].items.forEach((g) => {
                const list = g.variants.map((v) => ({ ...v, group: g.name, enabled: true }));

                variants = variants.concat(list);
              });

              this[`${this.section}List`] = variants;
            } else {
              this[`${this.section}List`] = this[this.section].items.map((itm) => {
                return { ...itm, enabled: true };
              });
            }
          });
        } else if (this.mode === 'Actualizar' && this.itemToUpdate) {
          this[`get${this.$wordUp(this.section)}ByTemp`](this.itemToUpdate).then((items) => {
            this[`${this.section}List`] = items;
          });
        }
      }
    },
    saveTemplate() {
      const productsList = this.productsList.filter((p) => {
        return p.changed;
      });

      const productError = productsList.find((i) => !i.price);
      if (productError) {
        this.error = 'Campo vacío en los productos, los precios son obligatorios';
      } else {
        this.error = null;
      }

      if (this.mode === 'Agregar' && !productError) {
        this.create((templateId, err) => {
          this.$log('New template id', templateId);

          if (!err) {
            const variantsToUpdate = this.variantsList.filter((p) => {
              return p.changed;
            });
            const productsToUpdate = this.productsList.filter((p) => {
              return p.changed;
            });
            const promotionsToUpdate = this.promotionsList.filter((p) => {
              return p.changed;
            });
            const advertisingToUpdate = this.advertisingList.filter((p) => {
              return p.changed;
            });

            if (variantsToUpdate.length) {
              this.updateVariantsByTemp({ templateId, variants: variantsToUpdate }).then(() => {
                let variants = [];

                this.variants.items.forEach((g) => {
                  const list = g.variants.map((v) => ({ ...v, group: g.name, enabled: true }));

                  variants = variants.concat(list);
                });

                this.variantsList = variants;
              });
            }
            if (productsToUpdate.length) {
              this.updateProductsByTemp({ templateId, products: productsToUpdate }).then(() => {
                this.productsList = this.products.items.map((itm) => ({ ...itm, enabled: true }));
              });
            }
            if (promotionsToUpdate.length) {
              this.updatePromotionsByTemp({ templateId, promotions: promotionsToUpdate }).then(
                () => {
                  this.promotionsList = this.promotions.items.map((itm) => ({
                    ...itm,
                    enabled: true,
                  }));
                },
              );
            }
            if (advertisingToUpdate.length) {
              this.updateAdvertisingByTemp({ templateId, advertising: advertisingToUpdate }).then(
                () => {
                  this.advertisingList = this.advertising.items.map((itm) => ({
                    ...itm,
                    enabled: true,
                  }));
                },
              );
            }

            this.$refs.closeEditor.click();
            this.resetInputs();
            this.section = 'products';
          }
        });
      } else if (this.mode === 'Actualizar' && !productError) {
        const templateId = this.itemToUpdate;

        this.update(templateId).then((err) => {
          if (!err) {
            const variantsToUpdate = this.variantsList.filter((p) => {
              return p.changed;
            });
            const productsToUpdate = this.productsList.filter((p) => {
              return p.changed;
            });
            const promotionsToUpdate = this.promotionsList.filter((p) => {
              return p.changed;
            });
            const advertisingToUpdate = this.advertisingList.filter((p) => {
              return p.changed;
            });

            if (variantsToUpdate.length) {
              this.updateVariantsByTemp({ templateId, variants: variantsToUpdate });
            }
            if (productsToUpdate.length) {
              this.updateProductsByTemp({ templateId, products: productsToUpdate });
            }
            if (promotionsToUpdate.length) {
              this.updatePromotionsByTemp({ templateId, promotions: promotionsToUpdate });
            }
            if (advertisingToUpdate.length) {
              this.updateAdvertisingByTemp({ templateId, advertising: advertisingToUpdate });
            }

            this.$refs.closeEditor.click();
            this.resetInputs();
            this.section = 'products';
          }
        });
      }
    },
    handleInput(input) {
      this.inputs = input;
    },
    checkInput(input) {
      this.inputs = input;
    },
    reset() {
      this.resetInputs();
      this.section = 'products';
      this.error = null;

      if (this.mode === 'Agregar') {
        this.productsList = this.products.items.map((itm) => ({ ...itm, enabled: true }));
        this.promotionsList = this.promotions.items.map((itm) => ({ ...itm, enabled: true }));
        this.advertisingList = this.advertising.items.map((itm) => ({ ...itm, enabled: true }));

        let variants = [];
        this.variants.items.forEach((g) => {
          const list = g.variants.map((v) => ({ ...v, group: g.name, enabled: true }));

          variants = variants.concat(list);
        });

        this.variantsList = variants;
      }
    },
    resetSorting() {
      this.search = '';
      this.sortBy = '';
      this.sortReverse = false;
    },
    resetLists() {
      this.productsList = [];
      this.promotionsList = [];
      this.advertisingList = [];
      this.variantsList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-arrows {
  color: #d9d9d9;
}
.modal {
  &-header {
    background-color: #e1e0ff;
  }
  &-content {
    max-width: 1000px;
  }
  &-footer button {
    min-width: 160px;
  }
}
.template-sections {
  max-height: 43px;
  button {
    border: $input-border-width solid #ced4da;
    font-weight: 500;
  }
  .active {
    background: $primary-light;
    color: $light;
  }
}
.table {
  &-body {
    overflow-y: scroll;
  }
}

.modal-dialog {
  max-width: 1200px;
}
</style>
