<template>
  <div class="board-items d-flex flex-column justify-content-between">
    <div class="board-table table-responsive" ref="boardTable">
      <table class="table table-borderless table-hover pt-lg-2">
        <!-- Setting column sizes -->
        <col style="width: 45px" />
        <col v-for="({ width }, i) in cols" :key="i" :style="`width: ${width}`" />
        <col v-if="templateEnable" style="width: 100px" />
        <col style="width: 130px" />
        <!-- Table header -->
        <thead class="border-bottom text-center">
          <tr>
            <th scope="col position-relative"></th>
            <th scope="col" v-for="({ header }, i) in cols" :key="i">{{ header }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <!-- Table items -->
        <Draggable
          tag="tbody"
          v-model="list"
          handle=".handle"
          :sort="draggable"
          v-bind="dragOptions"
          @start="drag = false"
          @end="drag = false"
          @update="orderList(list)">
          <tr
            class="align-middle position-relative bg-transition"
            :class="{ 'to-delete': item.deleting }"
            v-for="(item, i) in list"
            :key="i">
            <!-- Draggable control -->
            <td class="text-center position-relative" :class="{ handle: draggable }">
              <span :class="{ nohandle: draggable }">{{ i + 1 }}</span>
              <i
                class="icon-reorder position-absolute fs-2 p-3"
                :class="{ 'handle-icon': draggable, 'd-none': !draggable }"></i>
            </td>
            <td
              :class="{
                'text-center': ['template', 'template_name', 'expiration', 'updated'].includes(
                  prop,
                ),
              }"
              v-for="({ prop, type }, i) in cols"
              :key="i">
              <Button
                v-if="type === 'link'"
                :href="`${menu.url}/${item.route_key}`"
                :target="'_blank'"
                :size="'sm'">
                Ver Menú
              </Button>
              <Button
                v-else-if="type === 'button'"
                :styled="'secondary'"
                :size="'sm'"
                data-bs-toggle="modal"
                data-bs-target="#ShareLinkModal"
                @click="$emit('getLink', item)">
                Generar Link
              </Button>
              <Toggle
                v-else-if="type === 'toggle'"
                :name="prop + item.id"
                :checked="item[prop]"
                v-model="item[prop]"
                @click="$emit('toggle', item.id, prop)" />
              <span v-else ref="cell">{{
                prop === 'description' && item[prop]
                  ? smallText(item[prop])
                  : prop === 'updated'
                  ? $moment(item[prop]).fromNow()
                  : prop === 'template' && item[prop].id
                  ? item[prop].name
                  : prop === 'template' && !item[prop].id
                  ? 'N/A'
                  : item[prop]
              }}</span>
            </td>
            <td v-if="templateEnable">
              <Button
                :styled="'outline'"
                :size="'sm'"
                data-bs-toggle="modal"
                data-bs-target="#MasterEnableModal"
                @click="$emit('action', 'template', target, item.id)">
                Plantillas
              </Button>
            </td>
            <td>
              <ActionIcon
                v-if="actions.includes('edit')"
                :tooltipTitle="'Editar'"
                :action="'edit'"
                :modal="modal"
                @click="$emit('action', 'update', target, item.id, item.name)" />
              <ActionIcon
                v-if="actions.includes('copy')"
                :tooltipTitle="'Clonar'"
                :action="'copy'"
                :modal="'#ConfirmClone'"
                @click="$emit('action', 'clone', target, item.id)" />
              <ActionIcon
                v-if="actions.includes('delete')"
                :tooltipTitle="'Eliminar'"
                :action="'delete'"
                :modal="'#ConfirmDelete'"
                @click="$emit('action', 'remove', target, item.id)" />
            </td>
          </tr>
        </Draggable>
      </table>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ActionIcon from '@/components/common/ActionIcon.vue';
import Button from '@/components/common/Button.vue';
import Toggle from '@/components/common/Toggle.vue';
import { menu } from '../../config';

export default {
  name: 'BoardItems',
  components: {
    ActionIcon,
    Draggable,
    Button,
    Toggle,
  },
  data() {
    return {
      menu,
      list: this.items,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      },
    };
  },
  props: {
    cols: Array,
    items: Array,
    modal: String,
    target: String,
    actions: Array,
    draggable: Boolean,
    orderList: Function,
    templateEnable: Boolean,
  },
  watch: {
    items(val) {
      this.list = val;
    },
  },
  methods: {
    smallText(text) {
      return `${text.slice(0, 70)}${text.length > 70 ? '...' : ''}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.board-items {
  height: calc(100% - 218px);
  background-color: $light;
  thead {
    th {
      padding: 1rem;
    }
  }
}
.handle {
  cursor: grab;
  &:hover {
    .nohandle {
      display: none;
    }
    .handle-icon {
      display: flex;
    }
  }
  .handle-icon {
    top: 0;
    left: 0;
    bottom: 0;
    display: none;
    align-items: center;
  }
}
.bg-transition {
  transition: background 2s, color 2s;
}
.to-delete {
  background: $tertiary;
  color: $light;
}
</style>
