<template>
  <div class="list-items d-flex flex-column justify-content-between">
    <div class="board-list overflow-auto flex-grow-1">
      <ul class="list-group">
        <Draggable
          v-model="list"
          handle=".handle"
          group="parents"
          :sort="parents.sort"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          @update="$emit('orderList', list)">
          <li
            class="list-group-item mb-3"
            :class="{ 'to-delete': parent.deleting }"
            v-for="(parent, i) in list"
            :key="i">
            <div class="list-item-wrapper w-100 position-relative">
              <!-- Collapse Item Button -->
              <BoardItem
                ref="accordion"
                :id="parent.id"
                :parent="true"
                :sortable="parents.sort"
                :tagsTitle="tagsTitle"
                :tags="parent.tags"
                :children="childsProp"
                :icon="parent.icon"
                :name="`${parent.name} ${
                  childsProp ? '(' + parent[childsProp].length + ')' : ''
                }`" />

              <!-- Parent Item Controls -->
              <div class="list-item-controls position-absolute flex-center">
                <Button
                  v-if="parent.name !== 'Productos sin clasificar' && childs"
                  class="me-4"
                  :size="'sm'"
                  data-bs-toggle="modal"
                  :data-bs-target="modal"
                  @click="
                    $emit(
                      'action',
                      parents.actions.edit ? 'parent' : 'update',
                      parents.actions.edit ? childs.target : parents.target,
                      parents.actions.edit ? null : parent.id,
                      parents.actions.edit ? parent.id : null,
                    )
                  ">
                  {{ childs.parentAction || `+ Agregar ${$wordUp(childs.target)}` }}
                </Button>

                <div v-if="parent.default">Predefinido</div>
                <div class="list-actions" v-else-if="!parents.hideActions">
                  <ActionIcon
                    v-if="parents.actions.edit"
                    :tooltipTitle="'Editar'"
                    :action="'edit'"
                    :modal="'#EditorModal'"
                    @click="$emit('action', 'update', parents.target, parent.id)" />
                  <ActionIcon
                    :tooltipTitle="'Eliminar'"
                    :action="'delete'"
                    :modal="'#ConfirmDelete'"
                    @click="$emit('action', 'remove', parents.target, parent.id)" />
                </div>
              </div>
            </div>
            <!-- Item Childs -->
            <div
              v-if="childsProp"
              :id="`collapse${parent.id}`"
              class="subgroup collapse show"
              :class="{ show: childs.show }">
              <ul class="list-group">
                <Draggable
                  group="childs"
                  handle=".handle"
                  :sort="childs.sort"
                  v-bind="dragOptions"
                  v-model="parent[childsProp]"
                  @start="drag = true"
                  @end="drag = false"
                  @update="$emit('orderList', parent[childsProp], parent.id)"
                  @add="changeParent(parent.id, childId)">
                  <li
                    class="list-group-item position-relative flex-center justify-content-between"
                    :class="{ 'to-delete': parent.deleting || child.deleting }"
                    v-for="(child, j) in parent[childsProp]"
                    :key="j"
                    @mousedown="childId = child.id">
                    <BoardItem
                      :sortable="childs.sort"
                      v-bind="child"
                      :tagsTitle="tagsTitle"
                      :tags="child.tags" />

                    <Button
                      v-if="templateEnable"
                      class="me-4"
                      :styled="'outline'"
                      :size="'sm'"
                      data-bs-toggle="modal"
                      data-bs-target="#MasterEnableModal"
                      @click="$emit('action', 'template', childs.target, child.id)">
                      Plantillas
                    </Button>

                    <!-- Child Actions -->
                    <div class="list-actions" v-if="!childs.hideActions">
                      <ActionIcon
                        v-if="childs.actions.edit"
                        :tooltipTitle="'Editar'"
                        :action="'edit'"
                        :modal="'#EditorModal'"
                        @click="$emit('action', 'update', childs.target, child.id, parent.id, j)" />
                      <ActionIcon
                        :tooltipTitle="'Eliminar'"
                        :action="'delete'"
                        :modal="'#ConfirmDelete'"
                        @click="$emit('action', 'remove', childs.target, child.id, parent.id)" />
                    </div>
                  </li>
                </Draggable>
              </ul>
            </div>
          </li>
        </Draggable>
      </ul>
    </div>
  </div>
</template>

<script>
import ActionIcon from '@/components/common/ActionIcon.vue';
import BoardItem from '@/components/boards/BoardItem.vue';
import Button from '@/components/common/Button.vue';
import Draggable from 'vuedraggable';

export default {
  name: 'BoardItemsChained',
  components: {
    ActionIcon,
    BoardItem,
    Draggable,
    Button,
  },
  mounted() {
    this.$emit('mounted');
  },
  data() {
    return {
      childId: null,
      list: this.items,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      },
    };
  },
  props: {
    modal: String,
    items: Array,
    childs: Object,
    parents: Object,
    childsProp: String,
    templateEnable: Boolean,
    tagsTitle: String,
  },
  watch: {
    items(val) {
      this.list = val;
    },
  },
  methods: {
    changeParent(parentId, childId) {
      this.$emit('changeParent', parentId, childId);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  height: calc(100% - 184px);
  background-color: $gray-bg;
  .list-group-item {
    // background: ;
    transition: background-color 0.5s;
    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
    &:has(.show) {
      background: rgba(0, 0, 0, 0.035);
    }
  }
  .subgroup {
    .list-group-item {
      padding-left: 8rem;
      padding-right: 8rem;
      .handle-icon {
        opacity: 1;
      }
    }
  }
}
.list-item-controls {
  top: 8px;
  right: 30px;
}
.icon-arrow-down {
  transform: rotate(-90deg);
}
.handle {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
.nohandle {
  display: inline-block;
  opacity: 1;
}
.handle-icon {
  opacity: 0;
}
.handle:hover {
  + .name .nohandle {
    opacity: 0;
  }
  .handle-icon {
    opacity: 1;
  }
}

.to-delete {
  background-color: rgba($tertiary, 0.5);
  color: $light;
}
</style>
