<template>
  <div
    :id="id"
    class="modal fade"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
    aria-labelledby="ConfirmModalLabel">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p class="fs-3" v-html="description"></p>
            <slot></slot>
          </div>
        </div>
        <div class="modal-footer justify-content-between pt-5">
          <Button
            :styled="btnMode ? 'danger' : 'secondary'"
            :data-bs-dismiss="'modal'"
            @click="$emit('click2')">
            {{ btnText2 || 'Cancelar' }}
          </Button>
          <Button
            :styled="btnMode ? 'secondary' : 'danger'"
            :data-bs-dismiss="'modal'"
            @click="$emit('click')">
            {{ btnText }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'ConfirmModal',
  components: {
    Button,
  },
  data() {
    return {};
  },
  props: {
    id: String,
    title: String,
    btnText: String,
    btnText2: String,
    btnMode: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-header {
    background-color: #e1e0ff;
  }
  &-footer button {
    flex: 1;
  }
}
</style>
