<template>
  <div class="board h-100">
    <div class="board-header d-flex align-items-center justify-content-between px-4 mb-2">
      <div class="text-light ps-5">
        <div class="board-title d-flex align-items-center mb-lg-3">
          <slot name="icon"></slot>
          <h1 class="fs-2 ms-3 mb-0">{{ title }}</h1>
        </div>
        <small class="board-caption d-block">{{ caption }}</small>
      </div>
      <div class="board-controls d-flex align-items-center pe-5">
        <Button
          v-if="showCollapseButton"
          class="mb-3 mt-2"
          :styled="'normal'"
          @click="$emit('expandCollapse')"
          >{{ `${accordionCollapsed ? 'Expandir' : 'Contraer'} todo` }}</Button
        >
        <!-- Search Input -->
        <form class="col-12 col-lg-auto mb-3 mb-lg-0 mx-lg-3">
          <Input
            v-if="search"
            aria-label="Search"
            :placeholder="'Buscar'"
            @input="(v) => $emit('search', v)" />
        </form>
        <!-- Filter -->
        <div v-if="filter" class="position-relative">
          <Button
            :styled="'normal'"
            style="border: 1px solid transparent"
            @click="showFilterMenu = !showFilterMenu">
            <i class="icon-filter"></i>Filtrar
          </Button>
          <!-- Filter float -->
          <div
            v-if="showFilterMenu"
            ref="filterMenu"
            class="filter-menu position-absolute p-4 rounded text-start">
            <span>Filtrar por:</span>
            <Input
              name="types"
              :type="'list'"
              v-model="filterValue"
              :placeholder="filterName"
              :options="filterOptions"
              @input="(v) => $emit('filter', v)" />
            <!-- Reset filter -->
            <Button
              class="w-100"
              :styled="'normal'"
              @click="
                (v) => {
                  showFilterMenu = false;
                  filterValue = '';
                  $emit('filter', null);
                }
              ">
              <small>Limpiar Filtro</small>
            </Button>
          </div>
        </div>
        <Button
          v-if="target"
          :styled="'normal'"
          data-bs-toggle="modal"
          :data-bs-target="modal || '#EditorModal'"
          @click="$emit('action', 'create', target)">
          + Agregar {{ target }}
        </Button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Input from '@/components/common/Input.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'Board',
  components: {
    Button,
    Input,
  },
  data() {
    return {
      filterValue: '',
      filterOption: this.filterName,
      showFilterMenu: false,
    };
  },
  props: {
    modal: String,
    title: String,
    target: String,
    search: Boolean,
    filter: Boolean,
    caption: String,
    filterName: String,
    filterOptions: Array,
    showCollapseButton: Boolean,
    accordionCollapsed: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.board {
  &-header {
    width: 100%;
    height: 180px;
    background: linear-gradient(280deg, #9492ff 0%, #5816d8 100%);
  }
  &-title i::before {
    font-size: 5rem;
    color: $light;
  }
  &-caption {
    max-width: 500px;
    white-space: pre-line;
  }
  &-controls {
    flex-wrap: wrap;
    justify-content: end;
  }
}
.filter-menu {
  max-width: 300px;
  right: 0;
  top: 120%;
  z-index: 1;
  background: $light;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>
