import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const families = {
  getAll: () => {
    const config = {
      method: 'GET',
      url: '/v1/families',
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },

  create: (family) => {
    const config = {
      method: 'POST',
      url: '/v1/families',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: family,
    };

    return menu.http(config).catch(handleError);
  },

  update: (id, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/families/${id}`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },

  orderPosition: (familyList) => {
    const config = {
      method: 'PUT',
      url: '/v1/families',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: familyList,
    };

    return menu.http(config).catch(handleError);
  },

  uploadIcon: (id, images) => {
    const formData = new FormData();
    images.forEach((element) => formData.append(element.name, element.img));

    const config = {
      method: 'POST',
      url: `/v1/families/${id}/attachment`,
      headers: authHeader(),
      data: formData,
    };

    return menu.http(config).catch(handleError);
  },

  remove: (id) => {
    const config = {
      method: 'DELETE',
      url: `/v1/families/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
};

const categories = {
  create: (category, familyId) => {
    const config = {
      method: 'POST',
      url: `/v1/families/${familyId}/categories`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: category,
    };

    return menu.http(config).catch(handleError);
  },

  update: (id, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/families/categories/${id}`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },

  orderPosition: (categoryList) => {
    const config = {
      method: 'PUT',
      url: '/v1/families/categories',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: categoryList,
    };

    return menu.http(config).catch(handleError);
  },

  remove: (id) => {
    const config = {
      method: 'DELETE',
      url: `/v1/families/categories/${id}`,
      headers: { ...authHeader() },
    };

    return menu.http(config).catch(handleError);
  },
};

export default {
  families,
  categories,
};
