<template>
  <div class="tab-wrapper position-relative bg-light">
    <BoardItemsChained
      v-if="items.length || search"
      ref="itemsChained"
      class="board-content h-100"
      :items="items"
      :tagsTitle="'Productos asignados:'"
      v-bind="listSettings"
      :modal="'#EditorModal'"
      @action="emitAction" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :title="title"
      :target="'Grupo de variantes'"
      :modal="'#EditorModal'"
      :description="`Aún no tienes ningún grupo de variantes registrado.`"
      @click="emitAction('create', target)">
      <span class="material-symbols-outlined me-2" style="font-size: 8rem">category</span>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>

    <button ref="imgInputs" class="d-none" @click="updateImageInputs()"></button>
    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="removeItem"></button>
    <button ref="reset" class="d-none" @click="resetInputs"></button>
    <button
      ref="masterModal"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#MasterEnableModal"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
import BoardItemsChained from '@/components/boards/BoardItemsChained.vue';

export default {
  name: 'TabTags',
  components: {
    Spinner,
    BoardPlaceholder,
    BoardItemsChained,
  },
  mounted() {
    this.getAll().then(() => {
      console.log(this.items);
    });

    // if (!this.products.items.length) {
    //   this.getAllProducts();
    // }
  },
  data() {
    return {
      title: 'Etiquetas',
      target: 'Etiqueta',
      itemToUpdate: null,
      itemToDelete: null,
      listSettings: {
        parents: { target: 'etiqueta', sort: false, actions: { edit: true } },
      },
    };
  },
  props: {
    search: String,
  },
  computed: {
    ...mapState({
      // products: (state) => state.products,
      tags: (state) => state.tags,
    }),
    items() {
      const tags = this.search
        ? this.tags.items.filter((t) => t.name.toLowerCase().includes(this.search.toLowerCase()))
        : this.tags.items.slice(0).sort((a, b) => a.name.localeCompare(b.name));

      // Matching products with tags
      // return tagList.map((v) => {
      //   const products = this.products.items.filter((p) => {
      //     return p.tags.find((vp) => vp.id === v.id);
      //   });

      //   return { ...v, products, tags: products.map((pp) => pp.name) };
      // });

      return tags.map((t) => ({ ...t, default: t.global }));
    },
    loading() {
      return this.tags.loading;
    },
    error() {
      return this.tags.error;
    },
  },
  methods: {
    ...mapActions('tags', ['getAll', 'create', 'update', 'remove', 'resetInputs', 'setItemValues']),
    ...mapActions('products', { getAllProducts: 'getAll' }),
    emitAction(action, target, id) {
      console.log(action, target, id);
      this.$emit('action', action, target, id);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues({ id });
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    createItem() {
      try {
        this.create().then(() => {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    updateItem() {
      this.update({ id: this.itemToUpdate }).then((err) => {
        if (!err) {
          this.$parent.$parent.$parent.$refs.editorModal.$refs.closeEditor.click();
        }
      });
    },
    removeItem() {
      this.remove({ id: this.itemToDelete }).then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
