import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/Login.vue';
import AdminPanel from '../views/AdminPanel.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'AdminPanel',
    component: AdminPanel,
    children: [
      // this will render the AdminPanel for these URLs
      {
        path: '',
        component: AdminPanel,
        alias: [
          '/menu/categories',
          '/menu/products',
          '/menu/variants',
          '/menu/tags',
          '/promotions',
          '/advertising',
          '/multisucursal/sucursales',
          '/multisucursal/templates',
          '/tutorials',
          '/billing',
          '/settings/general',
          '/settings/account',
          '/settings/customize',
          '/settings/marketing',
          '/disabled',
          '/logout',
        ],
      },
    ],
  },
  // {
  //   path: '/sign-up',
  //   name: 'SignUp',
  //   component: () => import('../views/SignUp.vue'),
  // },
  {
    path: '/success-email',
    name: 'EmailVerified',
    /* route level code-splitting
      this generates a separate chunk (about.[hash].js) for this route
    which is lazy-loaded when the route is visited. */
    component: () => import('../views/EmailVerified.vue'),
  },
  // otherwise redirect to root
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/sign-up', '/success-email'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('access_token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});

export default router;
