/** Styles */
import 'normalize.css';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min';
import './assets/icons/font/style.css';
import './assets/icons/socialIcons/style.css';
import './assets/fonts/style.css';
import './assets/fontawesome/css/all.min.css';
/** Vue */
import Vue from 'vue';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './utils';
import './registerServiceWorker';

import { http, log, wordUp, regex, mexTimezone } from './config';

moment.locale('es');

Vue.prototype.$priceFormat = utils.priceFormat;
Vue.prototype.$mexTimezone = mexTimezone;
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$wordUp = wordUp;
Vue.prototype.$regex = regex;
Vue.prototype.$http = http; // axios instance
Vue.prototype.$gKey = 'AIzaSyDNJtCbqnae0VW_slV5--FuabKXQBd34EQ';
Vue.prototype.$log = log;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
