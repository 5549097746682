<template>
  <div>
    <h3 class="mb-4">Tu suscripción</h3>
    <div class="bg-light d-flex p-4 border rounded mb-4" v-for="s in subscriptions" :key="s.code">
      <!-- Plan Name -->
      <div class="col-lg-4">
        <p>
          Plan {{ s.product }}
          <span v-if="s.demo" class="demo-badge py-1 px-3 ms-2 fs-6 rounded-pill text-primary"
            >Demo</span
          >
        </p>
        <p class="mb-0 fs-3">{{ s.plan === 'Freemium' ? 'Gratis' : s.plan }}</p>
      </div>
      <div class="billing-plan col-lg-5" :class="{ invisible: s.plan === 'Freemium' }">
        <p>Monto</p>
        <p class="mb-0 fs-3">{{ `${$priceFormat(s.plan_price, profile.info.currency)} ${profile.info.currency} / ${period(s.interval)}` }}</p>
      </div>
      <div class="col-lg-3 text-end">
      <div class="text-start" v-if="s.next_plan">
        <p>Termina</p>
        <p class="mb-0 fs-3">{{ $moment(s.expires_at).format('DD MMMM YYYY') }}</p>
      </div>
        <Button
          class="ms-auto h-100"
          v-else-if="isActive(s.status)"
          @click="$emit('changePlan', s.product)">
          {{ s.product === 'sucursales' ? 'Elegir Cantidad' : 'Cambiar Plan' }}
        </Button>
        <Button
          v-else-if="s.product === 'menú'"
          class="ms-auto h-100"
          data-bs-toggle="modal"
          :data-bs-target="'#resumeSubscription'"
          @click="$emit('update', s)">
          Reactivar
        </Button>
      </div>
    </div>
    <div
      class="bg-light d-flex p-4 border rounded mb-4"
      v-for="f in subscriptions[0].features"
      :key="f.code">
      <!-- Plan Name -->
      <div class="col-lg-4">
        <p>Complemento</p>
        <p class="mb-0 fs-3">{{ `${f.quantity} ${f.name}` }}</p>
      </div>
      <div class="billing-plan col-lg-5">
        <p>Monto</p>
        <p class="mb-0 fs-3">{{ `${$priceFormat(f.amount, profile.info.currency)} ${profile.info.currency} / ${period(subscriptions[0].interval)}` }}</p>
      </div>
      <div class="col-lg-3 text-end">
        <Button
          v-if="isActive(subscriptions[0].status)"
          class="ms-auto h-100"
          @click="$emit('changePlan', f.name.toLowerCase())"
          >Elegir cantidad
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Button from '@/components/common/Button.vue';

export default {
  name: 'SubscriptionsDetails',
  components: {
    Button,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,}),
  },
  props: {
    subscriptions: Array,
    alreadyUseDemo: Boolean,
  },
  methods: {
    isActive(status) {
      return status === 'active';
    },
    period(interval) {
      return interval === 'monthly' ? 'Mensual' : 'Anual'
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-badge {
  background: $primary-xx-light;
}
</style>
