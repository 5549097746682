import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const products = {
  getAll: () => {
    const config = {
      method: 'GET',
      url: '/v1/products',
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  getOne: (id) => {
    const config = {
      method: 'GET',
      url: `/v1/products/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  getByTemp: (templateId) => {
    const config = {
      method: 'GET',
      url: `/v1/templates/${templateId}/products`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  create: (product, categoryId) => {
    const config = {
      method: 'POST',
      url: `/v1/categories/${categoryId}/products`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: product,
    };

    return menu.http(config).catch(handleError);
  },
  update: (id, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/products/${id}`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  updateByTemp: (templateId, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/templates/${templateId}/products`,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data,
    };

    return menu.http(config).catch(handleError);
  },
  orderPosition: (productList) => {
    const config = {
      method: 'PUT',
      url: '/v1/products',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      data: productList,
    };

    return menu.http(config).catch(handleError);
  },
  remove: (id) => {
    const config = {
      method: 'DELETE',
      url: `/v1/products/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  addVariantGroup: (productId, variantGroup) => {
    const config = {
      method: 'POST',
      url: `/v1/products/${productId}/variants`,
      headers: authHeader(),
      data: variantGroup,
    };

    return menu.http(config).catch(handleError);
  },
  updateVariantGroup: (productId, data) => {
    const config = {
      method: 'PUT',
      url: `/v1/products/${productId}/variants`,
      headers: authHeader(),
      data,
    };

    return menu.http(config).catch(handleError);
  },
  removeVariantGroup: (id, productId) => {
    const config = {
      method: 'DELETE',
      url: `/v1/products/${productId}/variants/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  addTag: (productId, tag) => {
    const config = {
      method: 'POST',
      url: `/v1/products/${productId}/tags`,
      headers: authHeader(),
      data: tag,
    };

    return menu.http(config).catch(handleError);
  },
  removeTag: (id, productId) => {
    const config = {
      method: 'DELETE',
      url: `/v1/products/${productId}/tags/${id}`,
      headers: authHeader(),
    };

    return menu.http(config).catch(handleError);
  },
  uploadImage: (id, images) => {
    const formData = new FormData();
    images.forEach((element) => formData.append(element.name, element.img));

    const config = {
      method: 'POST',
      url: `/v1/products/${id}/attachment`,
      headers: authHeader(),
      data: formData,
    };

    return menu.http(config).catch(handleError);
  },
};

// const variants = {
//   create: (variant, productId) => {
//     const config = {
//       method: 'POST',
//       url: `/v1/products/${productId}/variants`,
//       headers: { ...authHeader(), 'Content-Type': 'application/json' },
//       data: variant,
//     };

//     return menu.http(config).catch(handleError);
//   },
//   update: (id, data) => {
//     const config = {
//       method: 'PUT',
//       url: `/v1/products/variants/${id}`,
//       headers: { ...authHeader(), 'Content-Type': 'application/json' },
//       data,
//     };

//     return menu.http(config).catch(handleError);
//   },
//   updateByTemp: (templateId, data) => {
//     const config = {
//       method: 'PUT',
//       url: `/v1/templates/${templateId}/products/variants`,
//       headers: { ...authHeader(), 'Content-Type': 'application/json' },
//       data,
//     };

//     return menu.http(config).catch(handleError);
//   },
//   remove: (id) => {
//     const config = {
//       method: 'DELETE',
//       url: `/v1/products/variants/${id}`,
//       headers: authHeader(),
//     };

//     return menu.http(config).catch(handleError);
//   },
// };

export default {
  products,
  // variants,
};
