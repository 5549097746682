import service from '../../services/categories.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre',
      value: '',
      required: true,
      placeholder: 'Bebidas',
    },
  ],
  imageInputs: [],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.families.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  create({ state, dispatch, commit }, parentId) {
    const target = parentId ? 'categories' : 'families';
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    let payload = {};
    let images = [];

    if (formError || imageError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    // Count items in local to set position
    const position = parentId
      ? state.items.find((i) => i.id === parentId).categories.length
      : state.items.length;

    payload.position = position;

    // Setting images to upload
    if (!parentId) {
      state.imageInputs.forEach(({ name, value }) => {
        if (value && typeof value !== 'string') images.push({ name, img: value });
      });
    }

    if (!parentId) commit('loading');

    return service[target]
      .create(payload, parentId)
      .then(({ data }) => {
        if (parentId) {
          // Add new category into parent family
          const parent = state.items.find((i) => i.id === parentId);
          parent.categories.push({ id: data.info.id, ...payload });
        } else if (images.length) {
          // Upload family icon
          return service.families.uploadIcon(data.info.id, images).then(() => {
            dispatch('getAll');
          });
        } else {
          dispatch('getAll');
        }
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, dispatch, commit }, { id, parentId }) {
    const target = parentId ? 'categories' : 'families';
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    let payload = {};
    let images = [];

    if (formError || imageError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    // Setting images to upload
    if (!parentId) {
      state.imageInputs.forEach(({ name, value }) => {
        if (value && typeof value !== 'string') images.push({ name, img: value });
      });
    }

    // If a image will update, we need getAll to update item icon url
    if (!parentId && images.length) commit('loading');

    return service[target]
      .update(id, payload)
      .then(() => {
        if (parentId) {
          state.items.find((p, i) => {
            if (p.id === parentId) {
              p.categories.find((itm, j) => {
                // Update local item data
                if (itm.id === id) {
                  state.items[i].categories.splice(j, 1, { ...itm, ...payload });
                  return true;
                }
              });
            }
          });
        } else {
          state.items.find((itm, i) => {
            // Update local item data
            if (itm.id === id) {
              state.items.splice(i, 1, { ...itm, ...payload });
              return true;
            }
          });

          // If a image will update, we need getAll to update item icon url
          if (images.length) {
            return service.families.uploadIcon(id, images).then(() => {
              dispatch('getAll');
            });
          }
        }
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },
  changeFamily({ commit }, { id, family_id }) {
    return service.categories.update(id, { family_id }).catch((err) => handleError(commit, err));
  },
  orderPosition({ commit }, { itemList, parentId }) {
    const target = parentId ? 'categories' : 'families';
    // Setting index like new position to items
    const itemsNewPosition = itemList.map(({ id }, i) => ({ id, position: i }));
    return service[target].orderPosition(itemsNewPosition);
  },
  remove({ commit }, { id, parentId }) {
    const target = parentId ? 'categories' : 'families';
    commit('deleteRequest', { id, parentId });

    // if parentId will delete category else family
    service[target]
      .remove(id)
      .then(() => commit('deleteSuccess', { id, parentId }))
      .catch((err) => {
        commit('deleteFailure', { id, parentId });
        handleError(commit, err);
      });
  },
  setInput({ state }) {
    if (state.imageInputs.length < 2) {
      state.inputs.push({
        name: 'description',
        label: 'Descripción (opcional)',
        type: 'textarea',
        value: '',
        required: false,
        placeholder: 'Descripción)',
      });
    }
  },
  setImageInput({ state }) {
    if (state.imageInputs.length < 1) {
      state.imageInputs.push({
        name: 'icon',
        label: 'Icono',
        value: null,
        required: false,
        size: 'sm',
        formats: '.jpg, .jpeg, .png, .gif',
        placeholder: 'Agregar icono',
      });
    }
  },
  removeInput({ state }) {
    if (state.inputs.length === 2) {
      state.inputs.pop();
    }
  },
  removeImageInput({ state }) {
    if (state.imageInputs.length === 1) {
      state.imageInputs.pop();
    }
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  setImageInputValue({ state, commit }, input) {
    let indx;

    // Setting value to input index, i might be a input name
    if (typeof input.i === 'string') {
      state.imageInputs.find((o, i) => {
        if (o.name === input.i) {
          indx = i;
          return true;
        }
      });
    } else {
      indx = input.i;
    }

    commit('setImageInputValue', { ...input, i: indx });
  },
  // Will set item data to inputs and show on editor
  setItemValues({ state, commit }, { id, parentId }) {
    log('Setting item data to inputs');
    if (parentId) {
      const parent = state.items.find((i) => i.id === parentId);
      const item = parent.categories.find((i) => i.id === id);
      commit('setItemValues', item);
    } else {
      const item = state.items.find((i) => i.id === id);
      commit('setItemValues', item);
      commit('setItemImages', item);
    }
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
    state.imageInputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  // Get all
  getAllSuccess(state, families) {
    state.items = families;
    state.loading = false;
  },
  // Remove item
  deleteRequest(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Set signal that will be delete
    items.splice(index, 1, { ...itm, deleting: true });
  },
  deleteSuccess(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove from local items
    items.splice(index, 1);
  },
  deleteFailure(state, { id, parentId }) {
    const items = parentId ? state.items.find((i) => i.id === parentId).categories : state.items;
    let index;

    const itm = items.find((itm, i) => {
      if (itm.id === id) {
        index = i;
        return true;
      }
    });

    // Remove delete signal
    delete itm.deleting;
    items.splice(index, 1, itm);
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setImageInputValue(state, { value, valid, i }) {
    if (value !== undefined) state.imageInputs[i].value = value;
    if (valid !== undefined) state.imageInputs[i].valid = valid;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      return { ...i, value: item[i.name] };
    });
  },
  setItemImages(state, item) {
    state.imageInputs = state.imageInputs.map((i) => {
      if (item[i.name]) {
        const value = item[i.name].replace('Icon', 'public');
        return { ...i, value };
      }
      return { ...i, value: '' };
    });
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
