import service from '../../services/account.service';
// import { log } from '../../config';

const accessToken = localStorage.getItem('access_token');

export default {
  namespaced: true,
  state: accessToken
    ? { status: { loggedIn: true } }
    : { status: {}, signUp: { loading: false }, login: { loading: false }, error: null },

  actions: {
    // Credentials login and get access token
    login({ commit }, credentials) {
      commit('loginRequest');

      return service
        .login(credentials)
        .then(({ data }) => {
          /** store user details and jwt token in local storage
         to keep user logged in between page refreshes */
          if (data.access_token) localStorage.setItem('access_token', data.access_token);
          if (data.refresh_token) localStorage.setItem('refresh_token', data.refresh_token);
          commit('loginSuccess');
        })
        .catch((err) => {
          if (err.response) {
            commit('loginFailure', err.response.data);
          } else {
            commit('loginFailure', err);
          }
        });
    },
    signUp({ commit }, data) {
      commit('signUpRequest');
      return service.signUp(data).then((response) => {
        commit('signUpSuccess');
        return response.data;
      });
    },
    logout({ commit }) {
      localStorage.clear();
      commit('logout');
    },
    // eslint-disable-next-line
    changePassword({ commit }, { password, new_password }) {
      return service.changePassword(password, new_password);
    },
    // eslint-disable-next-line
    resendEmail({ commit }, email) {
      return service.resendEmail(email);
    },
  },

  mutations: {
    loginRequest(state) {
      state.login.loading = true;
    },
    loginSuccess(state) {
      state.status = { loggedIn: true };
      state.login.loading = false;
      state.error = null;
    },
    loginFailure(state, error) {
      state.error = error;
      state.login.loading = false;
    },
    signUpRequest(state) {
      state.signUp.loading = true;
    },
    signUpSuccess(state) {
      state.signUp.loading = false;
    },
    logout(state) {
      state.status = {};
      state.signUp = { loading: false };
      state.login = { loading: false };
      state.error = null;
    },
  },
};
