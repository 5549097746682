import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';

import authHeader from '../helpers/authHeader';

/**
 * Return profile data
 * @param {string} id
 * @returns
 */
const getPlans = (currency) => {
  const config = {
    method: 'get',
    url: `/v1/plans?currency=${currency}`,
  };

  return menu
    .http(config)
    // .then(({ data }) => data)
    .then(({ data }) => {
      console.log('getPlans', data);

      return data;
    })
    .catch(handleError);
};

// const getSucursalesPlans = () => {
//   const config = {
//     method: 'get',
//     url: '/v1/plans/sucursales',
//   };

//   return menu
//     .http(config)
//     .then(({ data }) => data)
//     .catch(handleError);
// };

/**
 * Return profile data
 * @param {string} id
 * @returns
 */
const getCardToken = () => {
  const config = {
    method: 'get',
    url: '/v1/subscriptions/card-token',
    headers: authHeader(),
  };

  return menu
    .http(config)
    // .then(({ data }) => data)
    .then(({ data }) => {
      console.log('getCardToken', data);

      return data;
    })
    .catch(handleError);
};

export default {
  getPlans,
  // getSucursalesPlans,
  getCardToken,
};
