<template>
  <div
    id="MasterEnableModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
    aria-labelledby="EditorModalLabel">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      :class="{ 'modal-dialog-lg': mode === 'update' }">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
            Actualizar {{ $wordUp(target) }} en plantillas
          </h5>
          <button
            ref="closeBtn"
            type="button"
            class="btn-close"
            aria-label="Close"
            data-bs-dismiss="modal"
            @click="reset"></button>
        </div>
        <div class="modal-body">
          <ul v-if="mode === 'replicate'" class="list-group">
            <li class="list-group-item py-3">
              <div class="d-flex justify-content-between py-3">
                <span>Seleccionar todas</span>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selectAll"
                    @click="checkAll" />
                </div>
              </div>
            </li>
            <li v-for="(t, i) in items" :key="`${t.id}${i}`" class="list-group-item py-3">
              <div class="d-flex justify-content-between py-3">
                <span>{{ t.template }}</span>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="t.replicate" />
                </div>
              </div>
            </li>
          </ul>
          <ul v-else class="list-group">
            <li class="list-group-item py-3">
              <div class="d-flex justify-content-between py-3">
                <span>Activar/desactivar todo</span>
                <Toggle
                  v-show="['update', 'enable'].includes(mode)"
                  name="enableAll"
                  :checked="enableAll"
                  @click="switchAll" />
              </div>
            </li>
            <li v-for="(t, i) in items" :key="`${t.id}${i}`" class="list-group-item py-3">
              <div v-if="mode === 'enable'" class="d-flex justify-content-between">
                <span class="me-3">{{ t.template }}</span>
                <Toggle
                  :name="t.template + t.id"
                  :checked="t.enabled"
                  @click="enableDisableOnTemplate(t.template_id)" />
              </div>
              <div
                v-else-if="mode === 'update'"
                class="d-flex align-items-center justify-content-between py-3">
                <Toggle
                  class="me-5"
                  :name="t.template + t.id"
                  :checked="t.enabled"
                  @click="enableDisableOnTemplate(t.template_id)" />
                <span class="me-5">{{ t.template }}</span>

                <div v-for="(n, j) in t.inputs" :key="'inp' + j">
                  <Input
                    v-if="n.type"
                    v-bind="n"
                    :style="`width: ${n.width}`"
                    :validated="validated"
                    @valid="(v) => (n.valid = v.valid)"
                    @input="(v) => ((n.value = v), (n.updated = true))" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="mode !== 'enable'" class="modal-footer justify-content-between pt-5">
          <!-- <Button :styled="'danger'" data-bs-dismiss="modal" @click="reset">Cancelar</Button> -->
          <Button @click="saveItem">{{
            mode === 'replicate' ? 'Replicar Cambios' : `Actualizar Plantillas`
          }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Input from '@/components/common/Input.vue';
import Toggle from '@/components/common/Toggle.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'MasterEnableModal',
  components: {
    Button,
    Toggle,
    Input,
  },
  mounted() {
    if (!this.templates.length) this.getAll();
  },
  data() {
    return {
      enabled: true,
      selectAll: false,
      enableAll: true,
      sources: {
        producto: 'products',
        variante: 'variants',
        promoción: 'promotions',
        anuncio: 'advertising',
      },
      validated: false,
      sourceByTemplates: [],
      templatesWithSources: [],
      templateInputs: {
        producto: [
          {
            name: 'price',
            type: 'number',
            width: '100px',
            required: true,
            value: '',
          },
        ],
        variante: [
          {
            name: 'price',
            width: '100px',
            type: 'number',
            value: '',
          },
        ],
        anuncio: [
          {
            name: 'description',
            width: '300px',
            type: 'textarea',
            value: '',
          },
        ],
        promoción: [
          {
            name: 'description',
            width: '300px',
            type: 'textarea',
            value: '',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates.items,
      products: (state) => state.products.items,
      variants: (state) => state.variants.items,
      promotions: (state) => state.promotions.items,
      advertising: (state) => state.advertising.items,
    }),
    items() {
      if (Array.isArray(this.id)) {
        return this.templatesWithSources.map((ts) => {
          const item = ts.items.find((itm) => itm.id === this.id[0]) || { enabled: true };

          if (this.target) {
            item.inputs = this.templateInputs[this.target].map((inp) => ({
              ...inp,
              value: item[inp.name],
            }));
          }

          return { ...item, template: ts.name, template_id: ts.id, replicate: false };
        });
      }

      if (this.id) {
        return this.templatesWithSources.map((ts) => {
          const item = ts.items.find((itm) => itm.id === this.id) || { enabled: true };

          if (this.target && item) {
            item.inputs = this.templateInputs[this.target].map((inp) => ({
              ...inp,
              value: item[inp.name],
            }));
          }

          return { ...item, template: ts.name, template_id: ts.id, replicate: false };
        });
      }

      return [];
    },
  },
  props: {
    id: [Number, Array],
    mode: String,
    target: String,
  },
  watch: {
    items(v) {
      this.enableAll = !v.some((d) => !d.enabled);
    },
    async target() {
      const source = this.sources[this.target];

      if (source) {
        this.templatesWithSources = await Promise.all(
          this.templates.map(async (t) => {
            const items = await this[`get${this.$wordUp(source)}ByTemp`](t.id).then((data) => data);

            return { ...t, items };
          }),
        );
      }
    },
    async id() {
      const source = this.sources[this.target];

      if (source && this.mode === 'enable') {
        this.templatesWithSources = await Promise.all(
          this.templates.map(async (t) => {
            const items = await this[`get${this.$wordUp(source)}ByTemp`](t.id).then((data) => data);

            return { ...t, items };
          }),
        );
      }
    },
  },
  methods: {
    ...mapActions('templates', ['getAll']),
    ...mapActions('products', {
      getProductsByTemp: 'getByTemp',
      updateProductsByTemp: 'updateByTemp',
    }),
    ...mapActions('promotions', {
      getPromotionsByTemp: 'getByTemp',
      updatePromotionsByTemp: 'updateByTemp',
    }),
    ...mapActions('advertising', {
      getAdvertisingByTemp: 'getByTemp',
      updateAdvertisingByTemp: 'updateByTemp',
    }),
    ...mapActions('variants', {
      getVariantsByTemp: 'getByTemp',
      updateVariantsByTemp: 'updateByTemp',
    }),
    enableDisableOnTemplate(templateId) {
      const source = this.sources[this.target];

      if (Array.isArray(this.id)) {
        this.id.forEach((id) => {
          const tempIdx = this.templatesWithSources.findIndex((t) => t.id === templateId);
          const itmIdx = this.templatesWithSources[tempIdx].items.findIndex((t) => t.id === id);

          const item = this.items.find((t) => t.id === id && t.template_id === templateId) || {
            enabled: true,
          };

          if (this.templatesWithSources[tempIdx].items[itmIdx])
            this.templatesWithSources[tempIdx].items[itmIdx].enabled = !item.enabled;
        });

        Promise.all(
          this.id.map((id) => {
            return this[`update${this.$wordUp(source)}ByTemp`]({
              templateId,
              [source]: [{ id, enabled: !this.enableAll }],
            });
          }),
        );
      } else {
        const tempIdx = this.templatesWithSources.findIndex((t) => t.id === templateId);
        const itmIdx = this.templatesWithSources[tempIdx].items.findIndex((t) => t.id === this.id);

        const item = this.items.find((t) => t.id === this.id && t.template_id === templateId) || {
          enabled: true,
        };

        if (this.templatesWithSources[tempIdx].items[itmIdx])
          this.templatesWithSources[tempIdx].items[itmIdx].enabled = !item.enabled;

        this[`update${this.$wordUp(source)}ByTemp`]({
          templateId,
          [source]: [{ id: this.id, enabled: !item.enabled }],
        });
      }
    },
    saveItem() {
      const source = this.sources[this.target];

      if (this.mode !== 'replicate') {
        let error = false;

        const itemsToUpdate = this.items.map((itm) => {
          const inputsUpdated = itm.inputs.filter((itu) => itu.updated);

          if (!error) error = inputsUpdated.some((uuy) => !uuy.valid);

          inputsUpdated.forEach((uui) => {
            // eslint-disable-next-line
            itm[uui.name] = uui.type === 'number' ? parseInt(uui.value) : uui.value;
          });

          return { ...itm, updated: !!inputsUpdated.length };
        });

        this.validated = true;

        if (!error) {
          Promise.all(
            itemsToUpdate.map((iu) => {
              if (iu.updated) {
                return this[`update${this.$wordUp(source)}ByTemp`]({
                  templateId: iu.template_id,
                  [source]: [iu],
                });
              }

              return [];
            }),
          ).then(async () => {
            this.templatesWithSources = await Promise.all(
              this.templates.map(async (t) => {
                const items = await this[`get${this.$wordUp(source)}ByTemp`](t.id).then(
                  (data) => data,
                );

                return { ...t, items };
              }),
            );
            this.reset();
            this.$refs.closeBtn.click();
          });
        }
      } else {
        const props = this.templateInputs[this.target].map((itm) => itm.name);

        let mainItem;

        if (source === 'variants') {
          mainItem = this[source].find((itm) => itm.id === this.id);
        } else {
          mainItem = this[source].find((itm) => itm.id === this.id);
        }

        Promise.all(
          this.items.map((itm) => {
            if (itm.replicate) {
              if (source === 'variants') {
                mainItem.variants.map((v) => {
                  const payItm = { id: v.id };

                  props.forEach((p) => {
                    payItm[p] = v[p] || null;
                  });

                  return this[`update${this.$wordUp(source)}ByTemp`]({
                    templateId: itm.template_id,
                    [source]: [payItm],
                  });
                });
              } else {
                const payItm = { id: itm.id };

                props.forEach((p) => {
                  payItm[p] = mainItem[p];
                });

                return this[`update${this.$wordUp(source)}ByTemp`]({
                  templateId: itm.template_id,
                  [source]: [payItm],
                });
              }
            }

            return {};
          }),
        ).then(async () => {
          this.templatesWithSources = await Promise.all(
            this.templates.map(async (t) => {
              const items = await this[`get${this.$wordUp(source)}ByTemp`](t.id).then(
                (data) => data,
              );

              return { ...t, items };
            }),
          );

          this.reset();
          this.$refs.closeBtn.click();
        });
      }
    },
    checkAll() {
      this.items.forEach((v) => {
        // eslint-disable-next-line
        v.replicate = !this.selectAll;
      });
    },
    async switchAll() {
      const source = this.sources[this.target];

      await Promise.all(
        this.items.map((v) => {
          // eslint-disable-next-line
          v.enabled = !this.enableAll;

          if (Array.isArray(this.id)) {
            return Promise.all(
              this.id.map(async (id) => {
                await this[`update${this.$wordUp(source)}ByTemp`]({
                  templateId: v.template_id,
                  [source]: [{ id, enabled: !this.enableAll }],
                }).then(() => console.log(v.template));
              }),
            );
          }
          return this[`update${this.$wordUp(source)}ByTemp`]({
            templateId: v.template_id,
            [source]: [{ id: this.id, enabled: !this.enableAll }],
          });
        }),
      );

      this.enableAll = !this.enableAll;

      this.templatesWithSources = await Promise.all(
        this.templates.map(async (t) => {
          const items = await this[`get${this.$wordUp(source)}ByTemp`](t.id).then((data) => data);

          return { ...t, items };
        }),
      );
    },
    reset() {
      this.validated = false;
      this.selectAll = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    max-width: 400px;
  }
  &-dialog-lg {
    max-width: 600px;
  }
  &-header {
    background-color: #e1e0ff;
  }
  &-body {
    min-height: 150px;
  }
  &-footer button {
    flex: 1;
  }
}
.variants-list {
  transition: height 1s;
  height: 400px;
}
.hidden-list {
  height: 0;
  padding: 0 !important;
}
.limit-control {
  background: #fff;
  border-radius: 5px;
  border: solid 2px $primary;
  font-weight: bold;
  &:active {
    background: $primary;
    color: #fff;
  }
}
.dollar {
  color: #6c757d;
  bottom: 12px;
  left: 16px;
}
</style>
