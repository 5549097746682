<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100">
      <Board
        v-if="items.length"
        :title="title"
        :caption="caption"
        :target="$wordUp(target)"
        @action="emitAction">
        <template v-slot:icon>
          <i class="icon-star"></i>
        </template>
        <BoardItems
          v-if="!loading"
          class="board-content"
          :modal="'#EditorModal'"
          :target="target"
          :items="items"
          :draggable="true"
          :orderList="orderList"
          :templateEnable="existTemplates"
          v-bind="boardSettings"
          @action="emitAction" />
        <div v-else class="position-relative" style="height: calc(100% - 218px)">
          <Spinner class="text-primary" :size="'lg'" :center="true" />
        </div>
      </Board>
      <BoardPlaceholder
        v-else-if="!loading"
        class="position-center"
        :title="title"
        :target="$wordUp(target)"
        :modal="'#EditorModal'"
        :description="`Aún no tienes ninguna ${target} registrada.`"
        @click="emitAction('create', target)">
        <i class="icon-star"></i>
      </BoardPlaceholder>
    </div>
    <Notifications :single="'true'" :hide-after="5000"></Notifications>
    <button ref="create" class="d-none" @click="createItem"></button>
    <button ref="update" class="d-none" @click="updateItem"></button>
    <button ref="remove" class="d-none" @click="remove(itemToDelete)"></button>
    <button ref="reset" class="d-none" @click="resetInputs"></button>
    <button
      ref="masterModal"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#MasterEnableModal"></button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Board from '@/components/boards/Board.vue';
import Spinner from '@/components/common/Spinner.vue';
import Notifications from '@voerro/vue-notifications';
import BoardItems from '@/components/boards/BoardItems.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';

export default {
  name: 'BoardPromotions',
  components: {
    Board,
    Spinner,
    BoardItems,
    Notifications,
    BoardPlaceholder,
  },
  mounted() {
    this.getAll();
  },
  data() {
    return {
      title: 'Promociones',
      target: 'promoción',
      caption: '- Ordena la posición de las promociones arrastrando los elementos.',
      itemToUpdate: null,
      itemToDelete: null,
      boardSettings: {
        cols: [
          {
            header: 'Nombre',
            width: '200px',
            prop: 'name',
          },
          {
            header: 'Vigencia',
            width: '120px',
            prop: 'expiration',
          },
          {
            header: 'Descripción',
            width: 'auto',
            prop: 'description',
          },
        ],
        actions: ['edit', 'delete'],
      },
    };
  },
  props: {
    existTemplates: Boolean,
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates,
      promotions: (state) => state.promotions,
    }),
    items() {
      const parseItems = this.promotions.items.map((p) => ({
        ...p,
        expiration: p.expiration ? this.$moment(p.expiration).format('DD-MM-YYYY') : 'N/A',
      }));
      return parseItems;
    },
    loading() {
      return this.promotions.loading;
    },
    error() {
      return this.promotions.error;
    },
  },
  methods: {
    ...mapActions('promotions', [
      'getAll',
      'create',
      'update',
      'remove',
      'resetInputs',
      'setItemValues',
      'orderPosition',
    ]),
    emitAction(action, target, id) {
      this.$emit('action', action, target, id);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues(id);
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    createItem() {
      this.create().then((id) => {
        try {
          if (this.templates.items.length) {
            this.$emit('action', 'create', 'promoción', id);
            this.$refs.masterModal.click();
          }
          this.$parent.$refs.editorModal.$refs.closeEditor.click();
          // this.resetInputs();
        } catch (error) {
          console.log(error.message);
        }
      });
    },
    updateItem() {
      this.update(this.itemToUpdate).then((err) => {
        if (!err) {
          const updateInTemplate = this.promotions.inputs.some(
            (inp) => inp.updated && inp.editInTemplate,
          );

          if (this.templates.items.length && updateInTemplate) {
            this.$emit('action', 'update', 'promoción', this.itemToUpdate);
            this.$refs.masterModal.click();
          }
          this.$parent.$refs.editorModal.$refs.closeEditor.click();
          // this.resetInputs();
        }
      });
    },
    orderList(itemList) {
      this.orderPosition(itemList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
