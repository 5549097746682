<template>
  <ul class="nav">
    <li class="nav-item" :class="{active: t.active}" v-for="(t, i) in tabs" :key="i">
      <a class="nav-link flex-center py-3 px-4" aria-current="page" @click="$emit('click', t.tab)">
        <span class="material-symbols-outlined me-2">{{ t.icon }}</span> {{ t.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Tabs',
  data() {
    return {};
  },
  props: {
    tabs: Array
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &-link {
    background-color: $light;
    color: $dark-x-gray-bg;
    border-bottom: 3px solid $dark-x-gray-bg;
    cursor: pointer;
  }
  &-link:hover, .active &-link {
    background-color: $primary-xx-light;
    color: $primary;
    border-bottom: 3px solid $primary;
  }
}
</style>
