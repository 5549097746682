<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="login-container position-center col-lg-4 col-md-6 col-sm-8">
      <!-- Login greeting -->
      <div class="text-center">
        <img class="mf-logo mb-5" src="@/assets/images/logo.png" alt="Mindfood logo" />
        <p class="fs-2 fw-bolder text-primary">¡Bienvenido!</p>
        <p class="fs-4">Inicia sesión con tu cuenta</p>
      </div>

      <!-- Login form -->
      <div class="login-form mb-5 pb-5">
        <form @submit.prevent="handleSubmit">
          <Input
            :name="'email'"
            :type="'email'"
            :label="'Correo'"
            :required="true"
            :placeholder="'ejemplo@correo.mx'"
            v-model="username" />

          <p class="fs-6 text-end mb-0">
            ¿No tienes cuenta aún?
            <!-- <router-link to="/sign-up" class="text-primary text-decoration-none">
              Regístrate
            </router-link> -->
            <a :href="menu.signUp">Regístrate</a>
          </p>

          <Input
            :name="'password'"
            :type="'password'"
            :label="'Contraseña'"
            :required="true"
            :placeholder="'************'"
            v-model="password" />

          <!-- Submit button -->
          <Button
            class="w-100 my-3"
            :type="'submit'"
            :animation="'loading'"
            :submitted="submitted"
            :disabled="disabledSubmit">
            Iniciar Sesión
          </Button>
          <div v-if="error" class="alert alert-danger" role="alert">
            <p v-if="errorMessage" class="m-0">{{ errorMessage }}</p>
            <p v-if="errorCode === 'MISSING_VERIFY_EMAIL'" class="mt-3 mb-0">
              Si no has recibido el correo de verificación, haz
              <a href="#" @click="resendEmail(username)">click aquí</a>
            </p>
          </div>
        </form>
      </div>

      <div class="text-center pt-5">
        <img
          class="mf-logo"
          src="@/assets/images/mindfood-solutions.png"
          alt="Mindfood solutions" />
      </div>
    </div>

    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P4Z6V3X"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Button from '@/components/common/Button.vue';
import Input from '@/components/common/Input.vue';
import { menu } from '../config';

export default {
  name: 'Login',
  components: {
    Button,
    Input,
  },
  mounted() {
    if (this.account.status.loggedIn) this.$router.push('/menu/categories');
  },
  data() {
    return {
      menu,
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapState({ account: (state) => state.account }),
    disabledSubmit() {
      return !this.username || !this.password;
    },
    submitted() {
      return this.account.login.loading;
    },
    error() {
      return this.account.error;
    },
    errorCode() {
      if (this.error && this.error.error) {
        console.log(this.error.error);
        return this.error.error.code;
      }

      return null;
    },
    errorMessage() {
      if (this.errorCode === 'RESOURCE_NOT_FOUND') return 'La cuenta no existe.';
      if (this.errorCode === 'CREDENTIALS_NOT_MATCH')
        return 'El correo o la contraseña no coinciden, intenta de nuevo.';
      if (this.errorCode === 'MISSING_VERIFY_EMAIL')
        return 'La cuenta aun no ha sido verificada, para ello ve al correo que hemos enviado durante el registro y haz click sobre el enlace.';

      return 'Algo a salido mal, por favor intenta más tarde.';
    },
  },
  methods: {
    ...mapActions('account', ['login', 'resendEmail']),
    handleSubmit() {
      const { username, password } = this;

      if (username && password) {
        this.login({ username, password }).then(() => {
          if (!this.error) this.$router.push('/menu/categories');
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  &-bg {
    width: 100%;
    height: 100%;
    background: url('../assets/images/bg-logo.webp') no-repeat center/90%;
    mix-blend-mode: multiply;
    opacity: 0.05;
  }
  &-container {
    max-width: 400px;
  }
}
.mf-logo {
  width: 150px;
}
</style>
