<template>
  <div
    id="EditorModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
    aria-labelledby="EditorModalLabel">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      :class="{ 'max-width': target === 'Producto' }">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title flex-grow-1 text-primary fw-bolder fs-4" id="EditorModalLabel">
            {{ `${mode} ${target}` }}
          </h5>
          <button
            type="button"
            ref="closeEditor"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset"></button>
        </div>
        <div v-if="errorMsg" class="modal-body flex-center">
          <p>{{ errorMsg }}</p>
        </div>
        <div v-else class="modal-body pb-0">
          <Spinner v-if="loading" class="text-primary" :size="'lg'" :center="true" />
          <form v-show="!loading" @submit.prevent="" :class="{ row: target === 'Producto' }">
            <div :class="{ 'col-7': target === 'Producto' }">
              <Input
                v-for="(input, i) in inputs"
                :key="i"
                v-bind="input"
                :validated="validated"
                @valid="(v) => $emit('valid', v)"
                @input="(v) => $emit('input', v)" />
              <!-- Product variant input -->
              <label v-if="productVariants" class="form-label w-100" for="">
                <div class="d-flex" v-if="variantList.length">
                  <div class="col-7">Grupos de variantes</div>
                  <!-- <div class="col-3 text-center" style="font-size: 1.4rem">Limite máximo</div>
                  <div class="col-2 text-center" style="font-size: 1.4rem">Obligatorio</div> -->
                </div>

                <div
                  class="row gx-3 align-items-center my-3"
                  v-for="(variant, i) in variantList"
                  :key="i">
                  <div class="col-8">
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="
                        min-height: 43.19px;
                        padding: 1rem 1.5rem;
                        background: #e1e0ff;
                        border-radius: 5px;
                      ">
                      <span>{{ variant.name }}</span>

                      <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        @click="addRemoveGroup('remove', variant.id, productId)"></button>
                    </div>
                  </div>
                  <div class="col-4">
                    <Button
                      class="w-100"
                      :styled="'normal'"
                      data-bs-toggle="modal"
                      data-bs-target="#VariantsEditorModal"
                      @click="updateVariantGroup(variant.id)"
                      >Ver Variantes</Button
                    >
                  </div>
                  <!-- Hidden till need to use in future -->
                  <!-- <div class="col-3 position-relative d-flex">
                    <button
                      class="limit-control"
                      @click="
                        () => {
                          if (
                            (variant.limit > 0 && !variant.obligatory) ||
                            (variant.limit > 1 && variant.obligatory)
                          ) {
                            variant.limit -= 1;

                            updateGroup(variant);
                          }
                        }
                      ">
                      -
                    </button>
                    <input
                      class="form-control mx-2 text-center"
                      type="number"
                      v-model="variant.limit"
                      @input="
                        (v) => {
                          variant.limit = v.target.value ? parseInt(v.target.value) : 0;
                          updateGroup(variant);
                        }
                      " />
                    <button
                      class="limit-control"
                      @click="
                        () => {
                          variant.limit += 1;
                          updateGroup(variant);
                        }
                      ">
                      +
                    </button>
                  </div>
                  <div class="col-2 text-center">
                    <Toggle
                      :name="`${variant.id}`"
                      :checked="variant.obligatory"
                      @click="
                        () => {
                          variant.obligatory = !variant.obligatory;
                          updateGroup(variant);
                        }
                      " />
                  </div> -->
                </div>
                <div class="position-relative">
                  <div class="d-flex">
                    <Button
                      class="me-2 px-4"
                      :styled="'light'"
                      @click="showVariantGroups = !showVariantGroups">
                      Asignar Grupos de Variantes
                    </Button>
                    <Button
                      class="px-4"
                      :styled="'normal'"
                      data-bs-toggle="modal"
                      data-bs-target="#VariantsEditorModal"
                      @click="addNewVariantGroup">
                      + Agregar Nuevo Grupo
                    </Button>
                  </div>
                  <div
                    class="variants-list bg-light overflow-auto mb-3"
                    :class="{ 'hidden-list': !showVariantGroups }">
                    <ul class="list-group p-4 shadow bg-body rounded">
                      <p>Selecciona para asignar</p>
                      <li
                        class="list-group-item p-3 mb-2 rounded"
                        :class="{ 'bg-primary': v.added, 'text-light': v.added }"
                        style="cursor: pointer; background: #e1e0ff"
                        v-for="(v, i) in variantGroupsSort"
                        :key="i"
                        @click="addRemoveGroup(v.added ? 'remove' : 'add', v.id, productId)">
                        <span></span>{{ v.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </label>

              <label v-if="variantGroupId && target === 'Grupo'" class="form-label w-100" for="">
                <div class="d-flex" v-if="prodVars.length">
                  <div class="col-7 mb-3">Productos asignados</div>
                </div>

                <div class="d-flex flex-wrap">
                  <div
                    class="row gx-3 align-items-center mb-3 me-3"
                    v-for="(p, i) in prodVars"
                    :key="i">
                    <div
                      class="d-flex justify-content-between align-items-center"
                      style="
                        min-height: 43.19px;
                        padding: 1rem 1.5rem;
                        background: #e1e0ff;
                        border-radius: 5px;
                      ">
                      <span class="me-2">{{ p.name }}</span>

                      <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        @click="addRemoveGroup('remove', variantGroupId, p.id)"></button>
                    </div>
                  </div>
                </div>

                <div class="position-relative">
                  <Button
                    class="w-100"
                    :styled="'light'"
                    @click="showSignedProducts = !showSignedProducts">
                    Asignar Productos
                  </Button>
                  <div
                    class="variants-list bg-light overflow-auto mb-3"
                    :class="{ 'hidden-list': !showSignedProducts }">
                    <ul class="list-group p-4 shadow bg-body rounded">
                      <p>Selecciona para asignar</p>
                      <li
                        class="list-group-item p-3 mb-2 rounded"
                        :class="{ 'bg-primary': p.added, 'text-light': p.added }"
                        style="cursor: pointer; background: #e1e0ff"
                        v-for="(p, i) in productsSort"
                        :key="i"
                        @click="addRemoveGroup(p.added ? 'remove' : 'add', variantGroupId, p.id)">
                        <span></span>{{ p.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </label>
            </div>

            <div :class="{ 'col-5': target === 'Producto' }">
              <!-- Image Inputs -->
              <ImageInput
                class="ms-4"
                ref="imageInput"
                v-for="input in imageInputs"
                :key="input.name"
                :validated="validated"
                v-bind="input"
                @valid="(v) => $emit('validImage', v)"
                @input="(v) => $emit('imageInput', v)" />
              <div class="ms-4">
                <label class="d-block" v-for="(t, i) in toggleInputs" :key="i" :for="t.name">
                  <span class="d-flex align-items-center">
                    <span class="text-primary me-2" v-html="t.icon"></span>
                    {{ t.label }}</span
                  >
                  <p class="fs-6">{{ t.placeholder }}</p>
                  <Toggle
                    class="my-3"
                    :name="t.name"
                    :checked="t.value"
                    v-model="t.value"
                    @click="() => $emit('toggle', { i })" />
                  <small v-if="t.error" class="text-danger">{{ t.message }}</small>
                </label>
              </div>

              <div class="position-relative" v-if="target === 'Producto'">
                <Button class="w-100 px-4" :styled="'light'" @click="showTags = !showTags">
                  Asignar Etiquetas
                </Button>
                <div
                  class="variants-list bg-light overflow-auto mb-3"
                  :class="{ 'hidden-list': !showTags }">
                  <ul class="list-group p-4 shadow bg-body rounded">
                    <p>Selecciona para asignar</p>
                    <li
                      class="list-group-item p-3 mb-2 rounded"
                      :class="{ 'bg-primary': v.added, 'text-light': v.added }"
                      style="cursor: pointer; background: #e1e0ff"
                      v-for="(v, i) in tagsSort"
                      :key="i"
                      @click="addRemoveTag(v.added ? 'remove' : 'add', v.id, productId)">
                      <span class="fs-3 me-4" :class="v.icon"></span>{{ v.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div :class="{ 'col-5': target === 'Etiqueta' }">
              <label for="" v-for="(c, i) in iconInputs" :key="i" class="position-relative">
                <span>{{ c.label }}</span>

                <div class="p-4 fs-1" style="cursor: pointer" @click="showPicker = !showPicker">
                  <i v-if="c.value" :class="c.value"></i>
                  <i v-else class="fa-solid fa-expand"></i>
                </div>

                <IconPiker
                  v-if="showPicker"
                  :seachbox="'Buscar icono'"
                  @selectIcon="
                    (v) => {
                      selectIcon(v, i);
                    }
                  " />
              </label>
            </div>
          </form>
        </div>
        <div v-show="!errorMsg" class="modal-footer justify-content-between pt-5">
          <Button :styled="'danger'" data-bs-dismiss="modal" @click="reset">Cancelar</Button>
          <Button @click="saveItem">{{ `${mode} ${target}` }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Input from '@/components/common/Input.vue';
import Toggle from '@/components/common/Toggle.vue';
import IconPiker from '@/components/IconPicker.vue';
import Button from '@/components/common/Button.vue';
import Spinner from '@/components/common/Spinner.vue';
import ImageInput from '@/components/common/ImageInput.vue';

export default {
  name: 'EditorModal',
  components: {
    ImageInput,
    IconPiker,
    Spinner,
    Button,
    Toggle,
    Input,
  },
  mounted() {
    if (this.variants) this.getAll();
    if (this.prodTags) this.getAllTags();
  },
  data() {
    return {
      showPicker: false,
      variantList: this.variants,
      tagList: this.prodTags,
      productList: [],
      showTags: false,
      showVariantGroups: false,
      showSignedProducts: false,
    };
  },
  computed: {
    ...mapState({
      variantGroups: (state) => state.variants,
      products: (state) => state.products,
      tags: (state) => state.tags,
    }),
    prodVars() {
      const signedList = this.products.items.filter((p) => {
        return !!p.variant_groups.find((v) => v.id === this.variantGroupId);
      });

      if (signedList) return signedList;

      return [];
    },
    groupList() {
      return this.variantGroups.items;
    },
    productsSort() {
      if (Array.isArray(this.products.items)) {
        const list = this.products.items.map((g) => {
          const prod = !!g.variant_groups.find((v) => v.id === this.variantGroupId);

          // const group = this.productList.find((gg) => g.id === gg.id);

          return { ...g, added: !!prod };
        });

        return list.sort((a, b) => a.name.localeCompare(b.name));
      }

      return [];
    },
    variantGroupsSort() {
      if (Array.isArray(this.variantGroups.items)) {
        const list = this.variantGroups.items.map((g) => {
          const group = this.variantList.find((gg) => g.id === gg.id);

          return { ...g, added: !!group };
        });

        return list.sort((a, b) => a.name.localeCompare(b.name));
      }

      return [];
    },
    tagsSort() {
      if (Array.isArray(this.tags.items)) {
        const list = this.tags.items.map((g) => {
          const tag = this.tagList.find((gg) => g.id === gg.id);

          return { ...g, added: !!tag };
        });

        return list.sort((a, b) => a.name.localeCompare(b.name));
      }

      return [];
    },
    variantsForNewProduct: {
      get() {
        return this.products.variants;
      },
      set(variants) {
        return this.$store.dispatch(`${'products'}/setVariantList`, variants);
      },
    },
    tagsForNewProduct: {
      get() {
        return this.products.tags;
      },
      set(tags) {
        return this.$store.dispatch(`${'products'}/setTagList`, tags);
      },
    },
  },
  props: {
    id: String,
    mode: String,
    inputs: Array,
    target: String,
    prodTags: Array,
    variants: Array,
    loading: Boolean,
    errorMsg: String,
    productId: Number,
    validated: Boolean,
    iconInputs: Array,
    imageInputs: Array,
    toggleInputs: Array,
    variantGroupId: Number,
    productVariants: Boolean,
    newVariantGroup: Object,
  },
  watch: {
    variants(v) {
      this.variantList = v;
    },
    prodTags(v) {
      this.tagList = v;
    },
    variantList(v) {
      this.$emit('variants', v);
    },
    tagList(v) {
      this.$emit('tags', v);
    },
    groupList(v) {
      const groupIds = this.variantList.map((g) => g.id);

      if (this.mode === 'Agregar') {
        this.variantList = groupIds.map((id) => {
          return v.find((vv) => vv.id === id);
        });
      }
    },
    newVariantGroup(v) {
      if (v && v.id) {
        this.variantList.push(v);
      }
    },
  },
  methods: {
    ...mapActions('products', [
      'addVariantGroup',
      'updateVariantGroup',
      'removeVariantGroup',
      'addTag',
      'removeTag',
    ]),
    ...mapActions('variants', ['getAll', 'setGroupInputs']),
    ...mapActions('tags', { getAllTags: 'getAll' }),
    saveItem() {
      if (this.mode === 'Agregar' && this.target === 'Producto') {
        this.variantsForNewProduct = this.variantList;
        this.tagsForNewProduct = this.tagList;
      }

      this.$emit('save');
    },
    selectIcon(v, i) {
      this.iconInputs[i].value = `fa-${v.className} fa-${v.cssValue.toLowerCase()}`;
    },
    reset() {
      if (Array.isArray(this.$refs.imageInput)) {
        this.$refs.imageInput.forEach((input, i) => {
          this.$refs.imageInput[i].$refs.input.value = null;
          this.$refs.imageInput[i].$refs.imageHolder.src = '';
        });
      }
      this.showTags = false;
      this.showVariantGroups = false;
      this.showSignedProducts = false;
      this.$emit('reset');
    },
    addNewVariantGroup() {
      this.$emit('addNewVariantGroup', this.productId);
      this.setGroupInputs();
    },
    updateVariantGroup(groupId) {
      this.$emit('updateVariantGroup', groupId);
      this.setGroupInputs();
    },
    addRemoveGroup(action, groupId, productId) {
      if (this.mode === 'Actualizar') {
        if (action === 'add') {
          this.addVariantGroup({ productId, group_id: groupId });
        } else if (action === 'remove') {
          this.removeVariantGroup({ productId, group_id: groupId });
        }
      } else if (this.mode === 'Agregar') {
        if (action === 'add') {
          const group = this.variantGroupsSort.find((v) => v.id === groupId);
          this.variantList.push(group);
        } else if (action === 'remove') {
          const gIdx = this.variantList.findIndex((v) => v.id === groupId);
          this.variantList.splice(gIdx, 1);
        }
      }
    },
    addRemoveTag(action, tagId, productId) {
      if (this.mode === 'Actualizar') {
        if (action === 'add') {
          this.addTag({ productId, tag_id: tagId });
        } else if (action === 'remove') {
          this.removeTag({ productId, tag_id: tagId });
        }
      } else if (this.mode === 'Agregar') {
        if (action === 'add') {
          const tag = this.tagsSort.find((v) => v.id === tagId);
          this.tagList.push(tag);
        } else if (action === 'remove') {
          const gIdx = this.tagList.findIndex((v) => v.id === tagId);
          this.tagList.splice(gIdx, 1);
        }
      }
    },
    updateGroup({ id, limit, obligatory }) {
      this.updateVariantGroup({
        productId: this.productId,
        group: { group_id: id, limit, obligatory },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-header {
    background-color: #e1e0ff;
  }
  &-body {
    min-height: 150px;
  }
  &-footer button {
    flex: 1;
  }
}
.max-width {
  max-width: 900px;
}
.variants-list {
  transition: height 1s;
  height: 400px;
}
.hidden-list {
  height: 0;
  padding: 0 !important;
}
.limit-control {
  background: #fff;
  border-radius: 5px;
  border: solid 2px $primary;
  font-weight: bold;
  &:active {
    background: $primary;
    color: #fff;
  }
}
.dollar {
  color: #6c757d;
  bottom: 12px;
  left: 16px;
}
</style>
