<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100">
      <BoardPlaceholder
        class="position-center"
        :cta="true"
        :title="'Obtén acceso'"
        :description="`El plan actual de tu suscripción no cuenta con acceso a esta sección`">
        <template v-slot:button>
          <Button class="m-auto" @click="$emit('goTo')">Mejorar Suscripción</Button>
        </template>
        <img src="@/assets/icons/lock.png" alt="" />
      </BoardPlaceholder>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
import Button from '@/components/common/Button.vue';

export default {
  name: 'BoardSucursales',
  components: {
    BoardPlaceholder,
    Button,
  },
  data() {
    return {
      title: 'Sucursales',
      target: 'sucursal',
      itemLink: null,
      itemToUpdate: null,
      itemToDelete: null,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.info,
      sucursales: (state) => state.sucursales,
      templates: (state) => state.templates.items,
    }),
    scopes() {
      return this.profile.plan.scopes.map((s) => s.name);
    },
    limit() {
      const scope = this.profile.plan.scopes.find((s) => s.name === 'Sucursales');
      return scope.limit;
    },
    items() {
      return this.sucursales.items.map((item) => ({
        ...item,
        template_name: item.template.name || 'N/A',
        template_id: item.template.id || '',
      }));
    },
    loading() {
      return this.sucursales.loading;
    },
    error() {
      return this.sucursales.error;
    },
  },
  watch: {
    error(v) {
      if (v.error.code === 'ENABLED_LIMIT_REACHED') {
        this.notifyLimit();
      }
    },
    templates(v) {
      this.setInputOptions({ name: 'template_id', options: v });
    },
  },
  methods: {
    ...mapActions('sucursales', [
      'getAll',
      'create',
      'update',
      'enable',
      'remove',
      'resetInputs',
      'updateRoute',
      'setItemValues',
      'setInputOptions',
      // 'setTemplateInput',
      'removeTemplateInput',
    ]),
    ...mapActions('templates', { getAllTemplates: 'getAll' }),
    saveLink(id, route) {
      const data = { route_key: route };
      this.updateRoute({ data, id });
    },
    getLink(item) {
      this.itemLink = item;
    },
    notifyLimit() {
      const notify = {
        text: `
        <p><b>Limite Alcanzado</b></p>
        <p>El numero de sucursales que puedes tener activas es ${this.limit}</p>
        <p>Debes desactivar una sucursal antes de activar una diferente.</p>
        <p>Si necesitas activar más sucursales, agrega más sucursales a tu plan.</p>
        `,
        theme: 'error',
      };

      window.notify(notify);
    },
    emitAction(action, target, id) {
      this.$emit('action', action, target, id);

      if (action === 'update') {
        this.itemToUpdate = id;
        this.setItemValues(id);
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    enableItem(id, prop) {
      const item = this.items.find((i) => i.id === id);

      item[prop] = !item[prop];

      this.enable({ id, boolean: item[prop] });
    },
    createItem() {
      this.create().then((err) => {
        if (!err) {
          this.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
    updateItem() {
      this.update({ id: this.itemToUpdate, templates: this.templates }).then((err) => {
        if (!err) {
          this.$parent.$refs.editorModal.$refs.closeEditor.click();
          this.resetInputs();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
