import { menu, licenses } from '../config'; // axios instance
import { handleError } from '../helpers/handles';

import authHeader from '../helpers/authHeader';

/**
 * Return profile data
 * @param {string} id
 * @returns
 */
const getOne = () => {
  const config = {
    method: 'get',
    url: '/v1/profiles/me',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

/**
 * Update profile data
 * @param {object} data
 * @returns
 */
const update = (data) => {
  const config = {
    method: 'PUT',
    url: '/v1/profiles/me',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

const getPaymentMethods = () => {
  const config = {
    method: 'GET',
    url: '/v1/profiles/me/payment-methods',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return menu.http(config).catch(handleError);
};

const addPaymentMethod = (data) => {
  const config = {
    method: 'POST',
    url: '/v1/profiles/me/payment-methods',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

const removePaymentMethod = (cardId) => {
  const config = {
    method: 'DELETE',
    url: `/v1/profiles/me/payment-methods/${cardId}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return menu.http(config).catch(handleError);
};

const getEmptyCardToken = () => {
  const config = {
    method: 'GET',
    url: '/v1/payment-sources/card-token',
  };

  return licenses.http(config).catch(handleError);
};

/**
 * Upload logo
 * @param {file} logo
 * @returns
 */
const uploadLogo = (logo) => {
  const formData = new FormData();
  formData.append('logo', logo);

  const config = {
    method: 'POST',
    url: '/v1/profiles/attachment',
    headers: { ...authHeader() },
    data: formData,
  };

  return menu.http(config).catch(handleError);
};

export default {
  getOne,
  update,
  uploadLogo,
  addPaymentMethod,
  getPaymentMethods,
  getEmptyCardToken,
  removePaymentMethod,
};
