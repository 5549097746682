<template>
  <div class="board-placeholder text-center">
    <div>
      <div class="board-icon text-primary lh-1"><slot></slot></div>
      <div class="board-placeholder-text pb-2">
        <p class="fs-2 fw-bolder mb-3">{{ title }}</p>
        <p class="fs-4 mb-4">{{ description }}</p>
      </div>
      <slot v-if="cta" name="button"></slot>
      <Button
        v-else
        class="m-auto"
        data-bs-toggle="modal"
        :data-bs-target="modal"
        @click="$emit('click')">
        {{ `+ Agregar ${target}` }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';

export default {
  name: 'BoardPlaceholder',
  components: {
    Button,
  },
  data() {
    return {};
  },
  props: {
    cta: Boolean,
    title: String,
    modal: String,
    target: String,
    description: String,
  },
};
</script>

<style lang="scss" scoped>
.board-placeholder {
  .board-icon {
    font-size: 8rem;
  }
}
</style>
