<template>
  <div class="tab-wrapper position-relative w-100 bg-light">
    <BoardItems
      v-if="items.length"
      class="board-content"
      :modal="'#TemplateEditorModal'"
      :target="target"
      :items="items"
      v-bind="boardSettings"
      @action="emitAction" />
    <BoardPlaceholder
      v-else-if="!loading"
      class="position-center"
      :title="'Plantillas'"
      :description="`Aún no tienes ninguna ${target} registrada.`"
      :target="$wordUp(target)"
      :modal="'#TemplateEditorModal'">
      <i class="icon-copy"></i>
    </BoardPlaceholder>
    <div v-else class="position-relative h-100">
      <Spinner class="text-primary" :size="'lg'" :center="true" />
    </div>
    <button ref="remove" class="d-none" @click="removeItem(itemToDelete)"></button>

    <!-- Template Editor -->
    <TemplateEditor
      ref="templateEditor"
      :mode="editorMode"
      :validated="validated"
      :target="$wordUp(target)"
      :itemToUpdate="itemToUpdate"
      :productWidthVar="productWidthVar" />
    <!-- @editVariants="editVariants" /> -->

    <!-- <TemplateVariantsEditor
      v-show="productToEdit"
      :mode="editorMode"
      :product="productToEdit"
      :itemToUpdate="itemToUpdate"
      @updateVariants="updateVariants" /> -->

    <ConfirmModal
      :id="'ConfirmClone'"
      :btnMode="'reverse'"
      :btnText="'Clonar Plantilla'"
      :description="'Confirma para clonar plantilla'"
      @click="cloneTemplate" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Spinner from '@/components/common/Spinner.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import BoardItems from '@/components/boards/BoardItems.vue';
import TemplateEditor from '@/components/TemplateEditorModal.vue';
import BoardPlaceholder from '@/components/boards/BoardPlaceholder.vue';
// import TemplateVariantsEditor from '@/components/TemplateVariantsModal.vue';

export default {
  name: 'BoardTemplates',
  components: {
    Spinner,
    BoardItems,
    ConfirmModal,
    TemplateEditor,
    BoardPlaceholder,
    // TemplateVariantsEditor,
  },
  mounted() {
    if (this.scopes.includes('Templates')) {
      this.getAll();
    } else {
      this.$emit('goTo', 'disabled');
    }
  },
  data() {
    return {
      caption:
        'Utiliza las plantillas para asignar productos, promociones, precios y publicidad distintos en las templates, asi cada que crees una nueva sucursal puedes asignarle una plantilla ya guardada y ahorrar mucho tiempo.',
      target: 'plantilla',
      editorMode: 'Agregar',
      itemToUpdate: null,
      itemToClone: null,
      itemToDelete: null,
      productToEdit: {},
      productWidthVar: null,
      boardSettings: {
        cols: [
          {
            header: 'Nombre',
            width: 'auto',
            prop: 'name',
          },
          {
            header: 'Ultimo cambio',
            width: '200px',
            prop: 'updated',
          },
        ],
        actions: ['edit', 'copy', 'delete'],
      },
    };
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates,
      profile: (state) => state.profile.info,
      sucursales: (state) => state.sucursales,
    }),
    validated: {
      get() {
        return this.templates.validated;
      },
      set(input) {
        return this.$store.dispatch(`templates/setValidated`, input);
      },
    },
    scopes() {
      return this.profile.plan.scopes.map((s) => s.name);
    },
    items() {
      return this.templates.items;
    },
    loading() {
      return this.templates.loading;
    },
    error() {
      return this.templates.error;
    },
  },
  methods: {
    ...mapActions('templates', [
      'getAll',
      'create',
      'update',
      'clone',
      'remove',
      'resetInputs',
      'setItemValues',
      'setTemplateInput',
    ]),
    ...mapActions('sucursales', { getAllSucursales: 'getAll', updateSucursal: 'simpleUpdate' }),
    notify() {
      const notify = {
        text: `
        <p><b>Error en los productos</b></p>
        <p>El precio de los productos es obligatorio</p>
        `,
        theme: 'error',
      };
      window.notify(notify);
    },
    cloneTemplate() {
      this.clone(this.itemToClone);
    },
    editVariants(product) {
      this.productToEdit = product;
    },
    updateVariants(product) {
      const variants = product.variants.map((v) => ({ ...v, price: v.price || null }));
      this.productWidthVar = { ...product, variants };
    },
    emitAction(action, target, id) {
      this.$emit('action', action, target, id);

      if (action === 'create') {
        this.editorMode = 'Agregar';
      } else if (action === 'update') {
        this.editorMode = 'Actualizar';
        this.itemToUpdate = id;
        this.setItemValues(id);
      } else if (action === 'clone') {
        this.itemToClone = id;
      } else if (action === 'remove') {
        this.itemToDelete = id;
      }
    },
    setAddMode() {
      this.editorMode = 'Agregar';
    },
    removeItem(id) {
      this.remove(id).then(() => {
        this.getAllSucursales().then(() => {
          const sucursalesToUpdate = this.sucursales.items.filter((s) => s.template.id === id);
          console.log('sucursalesToUpdate', sucursalesToUpdate);
          sucursalesToUpdate.forEach((s) => {
            console.log(s.name);
            this.updateSucursal({ id: s.id, payload: { template_id: null } });
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
  height: calc(100% - 250px);
}
</style>
