import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';

import authHeader from '../helpers/authHeader';

/**
 * Return access token
 * @param {object} credentials
 * @param {string} credentials.username
 * @param {string} credentials.password
 * @returns token
 */
const login = (credentials) => {
  const config = {
    method: 'post',
    url: '/auth/login',
    headers: {
      'Content-Type': 'application/json',
    },
    data: credentials,
  };

  return menu.http(config).catch(handleError);
};

/**
 * Create new account
 * @param {object} data
 * @param {string} data.username
 * @param {string} data.password
 * @param {string} data.first_name
 * @param {string} data.last_name
 * @param {string} data.phone
 * @param {string} data.company
 * @param {string} data.business_kind
 */
const signUp = (data) => {
  const config = {
    method: 'post',
    url: '/auth/signup',
    headers: { 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

/**
 * Update account data
 * @param {object} data {first_name, last_name, phone, company, business_kind}
 * @returns
 */
const update = (id, data) => {
  const config = {
    method: 'put',
    url: `/auth/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  };

  return menu
    .http(config)
    .then((response) => response.data)
    .catch(handleError);
};

/**
 * Change account password
 * @param {string} password
 * @param {string} new_password
 * @returns
 */
// eslint-disable-next-line
const changePassword = (password, new_password) => {
  const config = {
    method: 'put',
    url: '/auth/change-password',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: { password, new_password },
  };

  return menu.http(config).catch(handleError);
};

const resendEmail = (username) => {
  const config = {
    method: 'post',
    url: '/auth/resend-email',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { username },
  };

  return menu.http(config).catch(handleError);
};

export default {
  login,
  signUp,
  update,
  resendEmail,
  changePassword,
};
