<template>
  <div class="board-wrapper position-relative w-100 m-4 bg-light">
    <div class="board-content h-100" style="background-color: #f9f9f9">
      <Board :title="title" :caption="caption" @action="emitAction">
        <template v-slot:icon>
          <i class="icon-settings"></i>
        </template>
        <Tabs class="my-3" :tabs="tabs" @click="switchTab" />

        <TabGeneral
          v-if="tab === 'general'"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabAccount
          v-if="tab === 'account'"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabCustomize
          v-if="tab === 'customize'"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
        <TabMarketing
          v-if="tab === 'marketing'"
          @action="(a, b, c, d) => $emit('action', a, b, c, d)" />
      </Board>
    </div>
  </div>
</template>

<script>
import TabGeneral from '@/components/boards/boardTabs/TabGeneral.vue';
import TabAccount from '@/components/boards/boardTabs/TabAccount.vue';
import TabCustomize from '@/components/boards/boardTabs/TabCustomize.vue';
import TabMarketing from '@/components/boards/boardTabs/TabMarketing.vue';
import Board from '@/components/boards/Board.vue';
import Tabs from '@/components/common/Tabs.vue';

export default {
  name: 'BoardSettings',
  components: {
    TabGeneral,
    TabAccount,
    TabCustomize,
    TabMarketing,
    Board,
    Tabs,
  },
  mounted() {
    this.caption = this.tabs[0].caption;

    this.switchTab(this.tab);
  },
  data() {
    return {
      title: 'Configuración',
      caption: '',
      filterFamily: null,
      itemToUpdate: null,
      itemToDelete: null,
      tabs: [
        {
          title: 'General',
          icon: 'edit_square',
          tab: 'general',
          caption: '',
          active: false,
        },
        {
          title: 'Cuenta',
          icon: 'person',
          tab: 'account',
          caption: '',
          active: false,
        },
        {
          title: 'Identidad de marca',
          icon: 'palette',
          tab: 'customize',
          caption: '',
          active: false,
        },
        {
          title: 'Integraciones de marketing',
          icon: 'campaign',
          tab: 'marketing',
          caption: '',
          active: false,
        },
      ],
    };
  },
  props: {
    tab: String,
  },
  methods: {
    switchTab(v) {
      this.$emit('click', `/settings/${v}`);

      this.tabs = this.tabs.map((t) => {
        if (t.tab === v) {
          this.caption = t.caption;
          // this.target = t.target;
          return { ...t, active: true };
        }

        return { ...t, active: false };
      });
    },
    emitAction(action, target) {
      this.$emit('action', action, target);
    },
  },
};
</script>

<style lang="scss" scoped></style>
