import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const getAll = () => {
  const config = {
    method: 'GET',
    url: `/v1/sucursales`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const create = (sucursal) => {
  const config = {
    method: 'POST',
    url: '/v1/sucursales',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: sucursal,
  };

  return menu.http(config).catch(handleError);
};

const update = (id, sucursal) => {
  const config = {
    method: 'PUT',
    url: `/v1/sucursales/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: sucursal,
  };

  return menu.http(config).catch(handleError);
};

const enable = (id, boolean) => {
  const config = {
    method: 'PUT',
    url: `/v1/sucursales/enable/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: { enabled: boolean },
  };

  return menu.http(config).catch(handleError);
};

const remove = (id) => {
  const config = {
    method: 'DELETE',
    url: `/v1/sucursales/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

export default {
  getAll,
  create,
  update,
  enable,
  remove,
};
