import { menu } from '../config'; // axios instance
import { handleError } from '../helpers/handles';
import authHeader from '../helpers/authHeader';

const getAll = () => {
  const config = {
    method: 'GET',
    url: '/v1/promotions',
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const getOne = (id) => {
  const config = {
    method: 'GET',
    url: `/v1/promotions/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const getByTemp = (templateId) => {
  const config = {
    method: 'GET',
    url: `/v1/templates/${templateId}/promotions`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

const create = (sucursal) => {
  const config = {
    method: 'POST',
    url: '/v1/promotions',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: sucursal,
  };

  return menu.http(config).catch(handleError);
};

const update = (id, data) => {
  const config = {
    method: 'PUT',
    url: `/v1/promotions/${id}`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data,
  };

  return menu.http(config).catch(handleError);
};

const updateByTemp = (templateId, promotions) => {
  const config = {
    method: 'PUT',
    url: `/v1/templates/${templateId}/promotions`,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: promotions,
  };

  return menu.http(config).catch(handleError);
};

const orderPosition = (promotionList) => {
  const config = {
    method: 'PUT',
    url: '/v1/promotions',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    data: promotionList,
  };

  return menu.http(config).catch(handleError);
};

const uploadImages = (id, images) => {
  const formData = new FormData();
  images.forEach((element) => formData.append(element.name, element.img));

  const config = {
    method: 'POST',
    url: `/v1/promotions/${id}/attachment`,
    headers: { ...authHeader() },
    data: formData,
  };

  return menu.http(config).catch(handleError);
};

const remove = (id) => {
  const config = {
    method: 'DELETE',
    url: `/v1/promotions/${id}`,
    headers: authHeader(),
  };

  return menu.http(config).catch(handleError);
};

export default {
  getAll,
  getOne,
  create,
  update,
  remove,
  uploadImages,
  getByTemp,
  updateByTemp,
  orderPosition,
};
