import service from '../../services/plans.service';

export default {
  namespaced: true,
  state: { menuPlans: [], loading: false },

  actions: {
    getMenuPlans({ commit }, currency) {
      commit('loading');

      return service.getPlans(currency).then((data) => {
        commit('menuPlansSuccess', data);
      });
    },
    // getSucursalesPlans({ commit }) {
    //   commit('loading');

    //   return service.getSucursalesPlans().then((data) => {
    //     commit('sucursalesPlansSuccess', data);
    //   });
    // },
    getCardToken() {
      return service.getCardToken().then((data) => data);
    },
  },

  mutations: {
    loading(state) {
      state.loading = true;
    },
    menuPlansSuccess(state, plans) {
      state.loading = false;
      state.menuPlans = plans;
    },
    // sucursalesPlansSuccess(state, plans) {
    //   const monthlyPlans = plans.filter((p) => p.interval === 'Mensual');
    //   const yearlyPlans = plans.filter((p) => p.interval === 'Anual');
    //   const planList = monthlyPlans.map((p) => {
    //     const pp = yearlyPlans.find((y) => y.name === p.name);
    //     return {
    //       id: p.id,
    //       name: p.name,
    //       title: p.name,
    //       price: p.amount,
    //       intervals: [
    //         { code: p.code, interval: p.interval, amount: p.amount },
    //         { code: pp.code, interval: pp.interval, amount: pp.amount },
    //       ],
    //     };
    //   });

    //   state.loading = false;
    //   state.sucursalesPlans = planList;
    // },
    getPlansFailure(state, error) {
      state.loading = false;
      state.error = error;
    },
  },
};
