import axios from 'axios';

export const devEnv = process.env.NODE_ENV !== 'production';

export const menu = {
  url: process.env.VUE_APP_MENU_URL,
  apiUrl: process.env.VUE_APP_MENU_API_URL,
  http: axios.create({ baseURL: process.env.VUE_APP_MENU_API_URL }),
  signUp: `${process.env.VUE_APP_MENU_LANDING_URL}/#pricing`,
};

export const licenses = {
  apiUrl: process.env.VUE_APP_LICENSES_API_URL,
  http: axios.create({ baseURL: process.env.VUE_APP_LICENSES_API_URL }),
  conektaPubKey: process.env.VUE_APP_CONEKTA_PUB_KEY,
};

export const http = axios;

export const log = (...args) => {
  if (devEnv) {
    console.log(...args);
  }
};

export const wordUp = (word) => {
  if (word) return word[0].toUpperCase() + word.slice(1);

  return '';
};

export const mexTimezone = [
  'America/Bahia_Banderas',
  'America/Cancun',
  'America/Chihuahua',
  'America/Ciudad_Juarez',
  'America/Ensenada',
  'America/Hermosillo',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Merida',
  'America/Mexico_City',
  'America/Monterrey',
  'America/Tijuana',
  'America/Ojinaga',
  'America/Santa_Isabel',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'Mexico/General',
];

export const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
