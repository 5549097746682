<template>
  <div class="mb-3">
    <label v-show="showInput" :for="name" class="form-label w-100 mb-0">
      <span class="ps-3">{{ label }}</span>
      <div
        :for="name"
        class="file-upload mt-1 text-center"
        :class="{ 'py-5': !image && !value, 'file-upload-bg': !image && !value }">
        <span v-if="!image && !value" class="flex-center text-light">
          <span class="icon-picture me-2 pe-4"></span>{{ placeholder }}
        </span>
        <div v-else class="input-indicator"><i class="icon-edit"></i></div>
        <input
          :id="name"
          ref="input"
          class="form-control upload"
          type="file"
          :name="name"
          :accept="formats"
          :placeholder="placeholder"
          @change="handleImage" />
        <img
          ref="imageHolder"
          v-show="!error && (image || value)"
          :class="holderSize"
          :src="value"
          alt="" />
      </div>
    </label>
    <button
      class="btn btn-danger text-light btn-sm w-100"
      v-if="!required && value"
      @click="$emit('input', { value: null, i: $vnode.key, name })">
      Remover imagen
    </button>
    <div v-if="validated && error" class="fs-6 text-danger">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: 'ImageInput',
  mounted() {
    this.validate();
  },
  data() {
    return {
      image: null,
      error: false,
      errorMessage: 'Imagen requerida',
      holderSize: {
        'w-100': this.size === 'full',
        'w-75': this.size === 'lg',
        'w-50': this.size === 'md',
        'w-25': this.size === 'sm',
      },
    };
  },
  props: {
    name: String,
    size: { type: String, default: 'full' },
    label: String,
    formats: String,
    required: Boolean,
    validated: Boolean,
    showInput: { type: Boolean, default: true },
    placeholder: String,
    value: [File, String],
  },
  watch: {
    image() {
      this.validate();
    },
    value(v) {
      if (!v) this.image = null;
      this.validate();
    },
    validated(v) {
      if (!v) this.image = null;
      this.validate();
    },
  },
  methods: {
    handleImage(e) {
      [this.image] = e.target.files;
      this.$log('Image', this.image);

      if (this.image) this.showImg(this.image);

      if (this.$vnode.key) {
        this.$emit('input', { value: this.image, i: this.$vnode.key });
      } else {
        this.$emit('input', this.image);
      }
    },
    validate() {
      const imgExt = this.image?.name.split('.').pop() || '';

      // Allowed file size in mb
      const allowedSize = 10;
      const imgSize = this.image?.size / (1024 * 1024);

      const tooLarge = this.image && imgSize > allowedSize;
      const isEmpty = this.required && !this.image && !this.value;
      const wrongFormat = !this.formats.includes(imgExt.toLowerCase());

      this.$log('img isEmpty', isEmpty, 'this.image', this.image, 'this.value', this.value);

      this.error = isEmpty || wrongFormat || tooLarge;

      if (isEmpty) this.errorMessage = 'Imagen requerida';
      if (this.image && wrongFormat)
        this.errorMessage = `Imagen no valida, solo permite ${this.formats}`;
      if (this.image && tooLarge) this.errorMessage = `Imagen muy grande, máximo 10MB`;

      this.$emit('valid', { valid: !this.error, i: this.$vnode.key, name: this.name });
    },
    showImg(img) {
      if (typeof img === 'string') {
        this.$refs.imageHolder.src = img;
      } else if (img) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.$refs.imageHolder.src = e.target.result;
        };

        reader.readAsDataURL(img);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-upload {
  min-height: 90px;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  &-bg {
    background: $primary-light;
  }
  & > span {
    background: transparent;
    width: 100%;
    font-size: 2rem;
    border-radius: $border-radius;
    line-height: 1;
    span {
      font-size: 3rem;
    }
  }
  .input-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 1rem;
    font-size: 2.5rem;
    background: $primary-light;
    border-radius: 15px 0 0 0;
    i::before {
      color: $light;
    }
  }
  .upload {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
</style>
