<template>
  <div class="sidebar d-flex flex-column h-100">
    <!-- Profile info -->
    <div class="sidebar-info text-center px-lg-4 pt-lg-5 pb-lg-3">
      <img
        class="mf-logo mb-lg-4"
        src="@/assets/images/mindfood-solutions.png"
        alt="Mindfood solutions" />
      <div v-if="profile.loading" class="profile-loading position-relative">
        <Spinner :class="'text-primary'" :size="'lg'" :center="true" />
      </div>
      <div v-else class="profile">
        <div class="profile-picture position-relative rounded-circle overflow-hidden mb-lg-4">
          <img v-if="logo" class="profile-pic" :src="logo" :alt="company" />
          <img v-else class="profile-pic" src="@/assets/images/profile.png" alt="" />
        </div>
        <h3 class="fs-4 fw-bolder">{{ company }}</h3>
      </div>
    </div>

    <div class="sidebar-sections flex-grow-1">
      <SidebarActions
        class="d-flex flex-column justify-content-between h-100"
        :active="section"
        @click="(v) => $emit('click', v)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Spinner from '@/components/common/Spinner.vue';
import SidebarActions from '@/components/common/SidebarActions.vue';

export default {
  name: 'Sidebar',
  components: {
    SidebarActions,
    Spinner,
  },
  mounted() {
    this.getProfile().then(() => {
      this.$log('Profile', this.profile.info);
    });
  },
  data() {
    return {};
  },
  props: {
    section: String,
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    logo() {
      return this.profile.info.logo;
    },
    company() {
      return this.profile.info.company;
    },
  },
  methods: {
    ...mapActions('profile', ['getProfile']),
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  &-info {
    min-height: 225px;
  }
  width: 250px;
  background: $light;
}
.mf-logo {
  width: 150px;
}
.profile {
  &-loading {
    height: 130px;
  }
  &-picture {
    margin: auto;
    width: fit-content;
  }
  &-pic {
    width: 90px;
    height: 90px;
  }
}
</style>
