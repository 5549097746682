import moment from 'moment';
import service from '../../services/promotions.service';
import { log } from '../../config';

const state = {
  items: [],
  error: null,
  loading: false,
  validated: false,
  inputs: [
    {
      name: 'name',
      label: 'Nombre de la promoción',
      value: '',
      required: true,
      placeholder: 'Martes de tragos 2x1',
    },
    {
      name: 'description',
      label: 'Descripción',
      value: '',
      type: 'textarea',
      required: true,
      placeholder: 'Descripción de la promoción, lo que incluye, restricciones, información extra.',
      editInTemplate: true,
    },
    {
      name: 'expiration',
      label: 'Vigencia',
      value: null,
      type: 'date',
      placeholder: 'Seleccionar fechas de vigencia',
      get validation() {
        return this.value && moment(this.value).isBefore(moment().endOf('day'));
      },
      message: `La vigencia debe ser mayor a ${moment().format('DD-MM-YYYY')}`,
    },
  ],
  imageInputs: [
    {
      name: 'poster',
      field: 'poster',
      label: 'Poster (móvil)',
      required: true,
      value: '',
      size: 'md',
      formats: '.jpg, .jpeg, .png, .gif',
      placeholder: '1080x1920',
    },
    // {
    //   name: 'banner',
    //   field: 'banner',
    //   label: 'Banner (Desktop)',
    //   required: true,
    //   value: '',
    //   formats: '.jpg, .jpeg, .png, .gif',
    //   placeholder: '1920x1080',
    // },
  ],
};

/* eslint-disable */

const actions = {
  getAll({ commit }) {
    commit('loading');

    return service.getAll().then(({ data }) => {
      commit('getAllSuccess', data);
    });
  },
  getOne({ commit }, id) {
    return service.getOne(id).then(({ data }) => {
      commit('getOneSuccess');
      return data;
    });
  },
  getByTemp({ commit }, templateId) {
    commit('loading');

    return service.getByTemp(templateId).then(({ data }) => {
      commit('requestSuccess');
      return data;
    });
  },
  create({ state, dispatch, commit }) {
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    const customError = state.inputs.find((i) => i.validation);
    let payload = {};
    let images = [];

    if (formError || imageError || customError) {
      state.validated = true;
      throw new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value) payload[i.name] = i.value;
    });

    payload.position = state.items.length;

    // Setting images to upload
    state.imageInputs.forEach(({ name, value }) => {
      if (value && typeof value !== 'string') images.push({ name, img: value });
    });

    log('New promotion', payload);
    commit('loading');

    return service
      .create(payload)
      .then(async ({ data }) => {
        if (images.length) {
          // Upload product image
          await service.uploadImages(data.info.id, images).then(async () => {
            const newPromotion = await dispatch('getOne', data.info.id);
            log('getOne', newPromotion);
            state.items.push(newPromotion);
            // commit('createSuccess', newPromotion);
            state.validated = false;
          });
        } else {
          const newPromotion = await dispatch('getOne', data.info.id);
          log('getOne', newPromotion);
          state.items.push(newPromotion);
          state.validated = false;
        }

        return data.info.id;
      })
      .catch((err) => handleError(commit, err));
  },
  update({ state, dispatch, commit }, id) {
    const formError = state.inputs.find((i) => !i.valid);
    const imageError = state.imageInputs.find((i) => !i.valid);
    const customError = state.inputs.find((i) => i.validation);
    let payload = {};
    let images = [];

    if (formError || imageError || customError) {
      state.validated = true;
      return new Error('FORM_ERROR');
    }

    // Setting input values into payload
    state.inputs.forEach((i) => {
      if (i.value && i.value !== 'Invalid date' && i.value && i.value !== 'Fecha inválida') {
        payload[i.name] = i.value;
      }
    });

    // Setting images to upload
    state.imageInputs.forEach(({ name, value }) => {
      if (value && typeof value !== 'string') images.push({ name, img: value });
    });

    // If a image will update, we need getAll to update item icon url
    commit('loading');

    return service
      .update(id, payload)
      .then(async () => {
        let promotion;

        // If a image will update, we need getAll to update item image url
        if (images.length) {
          await service.uploadImages(id, images).then(async () => {
            promotion = await dispatch('getOne', id);
          });
        }

        // Update local item data
        await state.items.find((itm, i) => {
          if (itm.id === id) {
            state.items.splice(i, 1, { ...itm, ...(promotion || payload) });
            return true;
          }
        });

        commit('requestSuccess');
        state.validated = false;
      })
      .catch((err) => handleError(commit, err));
  },

  updateByTemp({ commit }, { templateId, promotions }) {
    const items = promotions.map(({ id, description, enabled }) => ({
      promotion_id: id,
      description,
      enabled,
    }));

    commit('loading');

    return service.updateByTemp(templateId, items).then(() => commit('requestSuccess'));
  },
  orderPosition({ commit }, itemList) {
    // Setting index like new position to items
    const itemsNewPosition = itemList.map(({ id }, i) => ({ id, position: i }));
    return service.orderPosition(itemsNewPosition);
  },
  remove({ commit }, id) {
    commit('deleteRequest', id);

    service
      .remove(id)
      .then(() => commit('deleteSuccess', id))
      .catch((err) => commit('deleteFailure', { id, error: err }));
  },
  setValidated({ state }, value) {
    state.validated = value;
  },
  setInputValue({ commit }, input) {
    commit('setInputValue', input);
  },
  setImageInputValue({ state, commit }, input) {
    let indx;

    // Setting value to input index, i might be a input name
    if (typeof input.i === 'string') {
      state.imageInputs.find((o, i) => {
        if (o.name === input.i) {
          indx = i;
          return true;
        }
      });
    } else {
      indx = input.i;
    }

    commit('setImageInputValue', { ...input, i: indx });
  },
  // Will set item data to inputs and show on editor
  setItemValues({ state, commit }, id) {
    log('Setting item data to inputs');
    const item = state.items.find((i) => i.id === id);
    commit('setItemValues', item);
    commit('setItemImages', item);
  },
  resetInputs({ state }) {
    log('Resetting inputs');
    state.inputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
    state.imageInputs.forEach((inp) => {
      inp.value = null;
      inp.updated = false;
    });
  },
};

const mutations = {
  loading(state) {
    state.loading = true;
  },
  requestSuccess(state) {
    state.loading = false;
  },
  requestFailure(state, error) {
    state.loading = false;
    state.error = error;
  },
  getAllSuccess(state, promotions) {
    state.items = promotions;
    state.loading = false;
  },
  getOneSuccess(state) {
    state.loading = false;
  },
  createSuccess(state, promotion) {
    state.items.push(promotion);
    log('state.items', state.items);
  },
  // Remove item
  deleteRequest(state, id) {
    // add 'deleting:true' property to sucursal being deleted
    state.items = state.items.map((sucursal) =>
      sucursal.id === id ? { ...sucursal, deleting: true } : sucursal,
    );
  },
  deleteSuccess(state, id) {
    // remove deleted sucursal from state
    state.items = state.items.filter((sucursal) => sucursal.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to sucursal
    state.items = state.items.map((sucursal) => {
      if (sucursal.id === id) {
        // make copy of sucursal without 'deleting:true' property
        const { deleting, ...itemCopy } = sucursal;
        // return copy of sucursal with 'deleteError:[error]' property
        return { ...itemCopy, deleteError: error };
      }

      return sucursal;
    });
  },
  setInputValue(state, { value, valid, updated, i }) {
    if (value && state.inputs[i].name === 'expiration')
      state.inputs[i].validation = value && moment(value).isBefore(moment().endOf('day'));

    if (value !== undefined) state.inputs[i].value = value;
    if (valid !== undefined) state.inputs[i].valid = valid;
    if (updated !== undefined) state.inputs[i].updated = updated;
  },
  setImageInputValue(state, { value, valid, i }) {
    if (value !== undefined) state.imageInputs[i].value = value;
    if (valid !== undefined) state.imageInputs[i].valid = valid;
  },
  setItemValues(state, item) {
    state.inputs = state.inputs.map((i) => {
      if (i.type === 'date') {
        return { ...i, value: moment(item[i.name]).format('YYYY-MM-DD') };
      } else {
        return { ...i, value: item[i.name] };
      }
    });
  },
  setItemImages(state, item) {
    state.imageInputs = state.imageInputs.map((i) => {
      if (item[i.name]) {
        return { ...i, value: item[i.name] };
      }
      return { ...i, value: '' };
    });
  },
};

function handleError(commit, err) {
  if (err.response) {
    commit('requestFailure', err.response.data);
  } else {
    commit('requestFailure', err);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
